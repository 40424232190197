import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Parser from 'html-react-parser'
import { useSelector } from 'react-redux'
import Modal from '../Modal'
import { useMediaOnly } from 'src/helpers'
import { SignatureModal, FormBlock } from 'src/components'

import CheckboxWithLabel from 'src/components/CheckboxWithLabel'
import { PrivacyPolicy, TermsText, AuthTerms } from './components'
import { useTermsAndConditionsStyles } from './styles'
import { IModalElement, TermsAndConditionProps } from './types'
import { AUTH_TERMS, NOTES, PRIVACY_POLICY, SIGNATURE, TERMS_CONDITIONS } from 'src/common'
import { gymDetailsSelector, planStateSelector } from 'src/selectors'

const PLANET_FITNESS_CORPORATE_ID = '11500'

function TermsAndConditions({
  guestPass,
  memberName,
  handleAgreementCheck,
  handleSignatureSubmit,
  showSignature,
}: TermsAndConditionProps) {
  const [checked, setChecked] = useState(false)
  const [signature, setSignature] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [activeModalType, setActiveModalType] = useState(TERMS_CONDITIONS)
  const reduxPlanInfo = useSelector(planStateSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const isPlanetFitness = reduxGymInfo.corporateId === PLANET_FITNESS_CORPORATE_ID
  const [fullName, setFullName] = useState('')
  const isMobile = useMediaOnly('xs')
  const classes = useTermsAndConditionsStyles()

  useEffect(() => {
    memberName && setFullName(memberName)
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked)
    handleAgreementCheck(e.target.checked)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleLinkClick = (target: string) => {
    setActiveModalType(target)
    setModalOpen(true)
  }

  const activeModalContent: () => IModalElement = () => {
    let modalContent = {
      [PRIVACY_POLICY]: {
        title: 'Privacy Policy',
        component: <PrivacyPolicy />,
      },
      [TERMS_CONDITIONS]: {
        title: 'Terms and Conditions',
        component: reduxGymInfo.prospectTerms && atob(reduxGymInfo.prospectTerms),
      },
    }
    if (!guestPass) {
      modalContent = Object.assign(modalContent, {
        [AUTH_TERMS]: {
          title: 'Authorization Terms',
          component: <AuthTerms />,
        },
        [NOTES]: {
          title: 'Notes',
          component: atob(reduxPlanInfo.agreementNote),
        },
        [TERMS_CONDITIONS]: {
          title: 'Terms and Conditions',
          component: atob(reduxPlanInfo.agreementTerms),
        },
      })
    }
    return modalContent
  }

  return (
    <Grid item>
      {modalOpen && activeModalType === SIGNATURE && (
        <SignatureModal
          guestName={fullName}
          handleClose={closeModal}
          setNewSignature={(signature: string) => {
            setSignature(signature)
            handleSignatureSubmit(signature)
          }}
        />
      )}
      {activeModalType !== SIGNATURE && (
        <Modal
          cancelText={activeModalContent()[activeModalType].cancelText || ''}
          confirmText={activeModalContent()[activeModalType].confirmText || 'Close'}
          handleCancel={activeModalContent()[activeModalType].handleCancel || closeModal}
          handleConfirm={closeModal}
          open={modalOpen}
          title={activeModalContent()[activeModalType].title}
        >
          <Container>
            {activeModalType === PRIVACY_POLICY || activeModalType === AUTH_TERMS
              ? activeModalContent()[activeModalType].component
              : Parser(activeModalContent()[activeModalType].component)}
          </Container>
        </Modal>
      )}

      <FormBlock>
        <Grid item>
          <Grid direction="column" spacing={7} container>
            <Grid className="field-grouping" item>
              <Grid direction="row" style={{ textAlign: 'justify'}}  container>
                <FormGroup row>
                  <CheckboxWithLabel
                    checkbox={{
                      checked: checked,
                      onChange: handleChange,
                      name: 'terms',
                    }}
                    controlLabel={{
                      classes: { label: classes.label },
                      label: 'Yes, I agree to all terms and conditions',
                    }}
                    required
                  />
                </FormGroup>
                <FormGroup className="tc-body" row>
                  <TermsText guestPass={guestPass} handleLinkClick={handleLinkClick} />
                  {isPlanetFitness && (
                    <Typography variant="body1">
                      {`We collect certain information about our members and potential members. To learn more about what
                        data we collect, how we use that data, and what privacy options you may have, please see our
                        Privacy Policy. For California residents age 13 to 16: Planet Fitness will not sell your personal
                        information to third parties as prohibited by law. If you would like to opt in to the sale of your
                        personal information, please have your parent or guardian send a request to `}
                      <Link href="mailto:privacy@planetfitness.com">privacy@planetfitness.com</Link>
                      {
                        '. I have received this data collection notice and have been provided an opportunity to review the '
                      }
                      <Link href="https://www.planetfitness.com/privacy-policy" rel="noopener" target="_blank">
                        Privacy Policy
                      </Link>
                      .
                    </Typography>
                  )}
                </FormGroup>
                {showSignature && (
                  <Box alignItems="center" display="flex" flex={1} flexDirection={{ xs: 'column', sm: 'row' }} pt={3}>
                    <Button
                      className={classes.signButtonStyle}
                      color="secondary"
                      name={SIGNATURE}
                      style={{
                        width: isMobile ? '100%' : 239,
                      }}
                      variant="contained"
                      onClick={() => handleLinkClick(SIGNATURE)}
                    >
                      {signature ? 'Edit Signature' : 'Sign Agreement'}
                    </Button>
                    {!!signature && (
                      <Box className={classes.signatureBox} display="flex" ml={[0, 1.5]} mt={[2, 0]}>
                        <img alt="User signature" className={classes.signatureImg} src={signature} />
                      </Box>
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormBlock>
    </Grid>
  )
}

export default TermsAndConditions
