import React, { ChangeEvent, useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { scrollToElem, transformCardNumber } from 'src/helpers'
import './CCFormInput.scss'
import { getPaymentCardBrand, iconMapping } from './utils'
import InputAdornment from '@material-ui/core/InputAdornment'

function CCFormInput({
  propName,
  fieldName,
  value,
  maxLength,
  onChange,
  error = false,
  required = false,
  nextIteration = 0,
}: CCFormInputType) {
  const errorRef = React.useRef<any>(null)
  const [cardBrand, setCardBrand] = useState(getPaymentCardBrand(value))

  useEffect(() => {
    setCardBrand(getPaymentCardBrand(value))
  }, [value])

  useEffect(() => {
    if (error) {
      scrollToElem(errorRef.current)
    }
  }, [nextIteration, error])

  const CreditCardIcon = cardBrand && iconMapping[cardBrand]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = transformCardNumber(event.target.value)

    if (typeof value !== 'undefined') {
      onChange({ target: { value } })
    }
  }

  return (
    <FormControl className={'form-group ' + propName}>
      <InputLabel htmlFor={propName}>
        {fieldName}{' '}
        {required && (
          <Typography color="error" component="span">
            *
          </Typography>
        )}
      </InputLabel>
      <Input
        color="secondary"
        error={error}
        inputProps={{ maxLength: maxLength }}
        name={propName}
        startAdornment={
          CreditCardIcon && (
            <InputAdornment position="start">
              <CreditCardIcon />
            </InputAdornment>
          )
        }
        type="text"
        value={value}
        onChange={handleChange}
      />
      {error && (
        <FormHelperText ref={errorRef} error>
          Valid {fieldName} is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

interface CCFormInputType {
  propName: string
  fieldName: string
  value: string
  maxLength?: number
  onChange: (event: any, label?: string) => void
  error?: boolean
  required?: boolean
  nextIteration?: number
}

export default CCFormInput
