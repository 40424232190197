import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { FormInput, HeadingBox, FormBlock, FormBlockHeading, TermsAndConditions } from 'src/components'
import { RootState } from 'src/configureStore'
import {
  isValidEmail,
  isValidPhone,
  isValidPostalCode,
  transformPhoneNumber,
  useMediaDown,
  scrollToElem,
  getQueryUrlState,
  transformName,
  useQuery,
} from 'src/helpers'
import { GuestValues } from 'src/interfaces'
import { guestProfileFieldsSlice, loadingSlice, stepperSlice } from 'src/reducers'
import { gymService } from 'src/services'
import { getCountryLabel, getCountryStates, getCountryStateOptions, getPromotionOptions } from 'src/helpers'
import CheckboxWithLabel from 'src/components/CheckboxWithLabel'
import { GuestField, PAGES, TransformValue } from 'src/common'
import { gymDetailsSelector, stepperSelector } from 'src/selectors'

const useStyles = makeStyles((theme) => ({
  spacedBox: {
    paddingBottom: theme.spacing(4),
  },
}))

function GuestPage(): JSX.Element {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [nextIteration, setNextIteration] = useState(0)
  const errorRef = React.useRef<any>(null)

  const reduxGymInfo = useSelector(gymDetailsSelector)

  const initialGuestValues = useSelector((state: RootState) => state.guestProfileFieldsSlice)
  const [guestValues, setGuestValues] = useState(initialGuestValues)
  const [guestPassSubmitError, setGuestPassSubmitError] = useState('')
  const [agreement, setAgreement] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const gymName = reduxGymInfo.name
  const reduxStepper = useSelector(stepperSelector)
  const isMobile = useMediaDown('xs')
  const { stateLabel, zipCodeLabel } = getCountryLabel(guestValues.countryCode)
  const countriesStates = useSelector((state: RootState) => state.countriesAndStatesSlice.countriesStates)
  const countriesStatesOptions = getCountryStateOptions(countriesStates)
  const stateOptions = getCountryStates(guestValues.countryCode, countriesStatesOptions)
  const countryOptions = countriesStatesOptions?.localCountryOptions
  const campaigns = useSelector((state: RootState) => state.campaignsSlice.campaigns)
  const termsSelector = useSelector((state: RootState) => state.gymSlice.gym.prospectTerms)
  const promotionOptions = getPromotionOptions(campaigns)
  const classes = useStyles()
  const params = useQuery();

  useEffect(() => {
    setGuestValues(initialGuestValues)
  }, [initialGuestValues])

  useEffect(() => {
    if (reduxStepper) {
      handleGuestNext()
    }
  }, [reduxStepper])

  useEffect(() => {
    scrollToElem(errorRef.current)
  }, [nextIteration])

  const handleChange = (prop: keyof GuestValues, transformValue?: TransformValue) => (event: any) => {
    let newValue = event.target.value
    switch (prop) {
      case 'receivePromotionOffers':
        newValue = event.target.checked
        break
      case 'campaign':
        newValue = promotionOptions.find(({ value }) => value === newValue)
        break
    }

    if (transformValue) {
      newValue = transformValue(newValue) ?? guestValues[prop]
    }

    setGuestValues({ ...guestValues, [prop]: newValue })
  }

  const handleGuestNext = async () => {
    setIsSubmitted(true)
    setNextIteration(nextIteration + 1)
    dispatch(loadingSlice.actions.startLoading('handleGuestNext'))

    //Validate Values
    if (
      guestValues.firstName.trim() &&
      guestValues.lastName.trim() &&
      guestValues.streetAddress &&
      guestValues.city &&
      guestValues.state &&
      guestValues.zip &&
      isValidPostalCode(guestValues.zip, guestValues.countryCode) &&
      guestValues.email &&
      isValidEmail(guestValues.email) &&
      guestValues.homePhone &&
      isValidPhone(guestValues.homePhone) &&
      guestValues.campaign?.value &&
      guestValues.signatureImage &&
      guestValues.countryCode &&
      agreement
    ) {
      dispatch(guestProfileFieldsSlice.actions.setFieldsState(guestValues))
      const payload = {
        clubNumber: reduxGymInfo.number,
        ...(params.get('referrerId') ? { referredById: params.get('referrerId')} : {}),
        ...guestValues,
        campaign: guestValues.campaign.value,
        messagingConsent: guestValues.receivePromotionOffers
      }

      try {
        const response = await gymService.submitTrial(payload)
        if (response.data.status === 'success') {
          const newState = getQueryUrlState({ path: PAGES.guestReview })
          history.push(newState)
        }

        if (response.data.status === 'error' && response.data.message) {
          setGuestPassSubmitError(response.data.message)
        }
      } catch (error) {
        setGuestPassSubmitError('Unknown submission error, please verify data and try again')
      }
    }
    dispatch(stepperSlice.actions.resetNext())
    dispatch(loadingSlice.actions.endLoading('handleGuestNext'))
  }

  const handleSignatureSubmit = (signatureImage: string) => {
    setGuestValues({ ...guestValues, signatureImage })
  }

  const handleAgreementCheck = (checked: boolean) => {
    setAgreement(checked)
  }

  const formField = ({
    propName,
    isValid = true,
    isRequired = true,
    type = 'text',
    options = [],
    ...opts
  }: GuestField) => {
    const value = guestValues[propName]
    return (
      <FormInput
        error={isSubmitted && ((value && !isValid) || !value)}
        fieldName={opts.fieldName}
        maxLength={opts.maxLength}
        nextIteration={nextIteration}
        options={options}
        propName={propName}
        required={isRequired}
        type={type}
        value={value}
        onChange={handleChange(propName, opts.transformValue)}
      />
    )
  }

  return (
    <Container maxWidth="xl">
      <Box className={classes.spacedBox}>
        <HeadingBox title="Guest Registration">
          {`At ${gymName}, we're here to help you meet your fitness goals at any moment `}
          on the journey to a healthier and stronger you.
        </HeadingBox>
        <FormBlock>
          <Grid item>
            <Grid direction="column" spacing={6} container>
              <Grid item>
                <Grid direction="row" spacing={2} container>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'firstName',
                      fieldName: 'First name',
                      maxLength: 15,
                      transformValue: transformName,
                    })}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'lastName',
                      fieldName: 'Last name',
                      maxLength: 19,
                      transformValue: transformName,
                    })}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'email',
                      fieldName: 'Email',
                      maxLength: 50,
                      isValid: isValidEmail(guestValues.email),
                    })}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'homePhone',
                      fieldName: 'Mobile phone',
                      maxLength: 15,
                      isValid: isValidPhone(guestValues.homePhone),
                      type: 'phone',
                      transformValue: transformPhoneNumber,
                    })}
                  </Grid>
                  <Grid direction="row" spacing={2} container>
                    <Box pt={2}>
                      <CheckboxWithLabel
                        checkbox={{
                          style: { marginTop: '-8px', marginLeft: '16px' },
                          checked: guestValues.receivePromotionOffers,
                          inputProps: { 'aria-label': 'Receive Promotions Checkbox' },
                          onChange: handleChange('receivePromotionOffers'),
                          value: 'receivePromotionOffers',
                        }}
                        controlLabel={{
                          style: { alignItems: 'flex-start', textAlign: 'justify' },
                          label: `By checking the applicable box below, you agree that ${gymName}, 
                    may deliver or cause to be delivered to you at the telephone number provided by you above telephone
                     calls, telemarketing calls, SMS messages (including text messages), voicemail messages and similar
                      communications using automated systems and technology (including automatic telephone dialing
                       systems), and/or artificial or prerecorded voice messages. Such communications would be about
                        your account, as well as offers from ${gymName}. You further acknowledge that you are
                         not required to provide this consent, directly or indirectly, as a condition of purchasing any
                          goods or services and that all contact information provided by you above is accurate. 
                          If you opt-in now, you will be able to opt-out later. Message and data rates may apply. Message 
                          frequency varies. For SMS: text HELP for help and text STOP to cancel messages.`
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormBlock>
        <FormBlock>
          <Grid item>
            <Grid direction="column" spacing={6} container>
              <Grid item>
                <FormBlockHeading title="Personal Information" />
                <Grid direction="row" spacing={2} container>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'streetAddress',
                      fieldName: 'Address',
                      maxLength: 44,
                    })}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField({
                      propName: 'countryCode',
                      fieldName: 'Country',
                      type: 'select',
                      options: countryOptions,
                    })}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField({ propName: 'city', fieldName: 'City', maxLength: 23 })}
                  </Grid>
                  <Grid sm={3} xs={12} item>
                    {formField({
                      propName: 'state',
                      fieldName: stateLabel,
                      type: 'select',
                      options: stateOptions,
                    })}
                  </Grid>
                  <Grid sm={3} xs={12} item>
                    {formField({
                      propName: 'zip',
                      fieldName: zipCodeLabel,
                      maxLength: 10,
                      isValid: isValidPostalCode(guestValues.zip, guestValues.countryCode),
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormBlock>
        <FormBlock>
          <Box padding={3} width={isMobile ? 'calc(100% - 48px)' : 'calc(50% - 32px)'}>
            <FormInput
              error={isSubmitted && !guestValues.campaign?.value}
              fieldName="How did you hear about us?"
              nextIteration={nextIteration}
              options={promotionOptions}
              propName="campaign"
              type="select"
              value={guestValues.campaign?.value || ''}
              required
              onChange={handleChange('campaign')}
            />
          </Box>
        </FormBlock>
        {termsSelector !== undefined && (
          <>
            <HeadingBox title="Terms & Conditions" />
            <TermsAndConditions
              handleAgreementCheck={handleAgreementCheck}
              handleSignatureSubmit={handleSignatureSubmit}
              guestPass
              showSignature
            />
            {isSubmitted && !agreement && (
              <FormHelperText ref={errorRef} error>
                Accepting the Terms and Conditions is required
              </FormHelperText>
            )}
            {isSubmitted && !guestValues.signatureImage && (
              <FormHelperText ref={errorRef} error>
                Signature is required
              </FormHelperText>
            )}
          </>
        )}
        <Grid container>
          <Grid xs={4} item>
            <Typography color="error">* Required fields</Typography>
          </Grid>
          <Grid xs={4} item />
          {guestPassSubmitError !== '' && (
            <Grid xs={4} item>
              <Typography style={{ color: 'rgba(194, 0, 0, 0.87)' }} variant="body1">
                ERROR: {guestPassSubmitError}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  )
}

export default GuestPage
