import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormBlock, FormBlockHeading } from 'src/components'
import { EditButton } from './index'
import { formatPhoneNumber } from 'src/helpers'
import { useSelector } from 'react-redux'
import { profileStateSelector } from 'src/selectors'

export const SecondaryMemberBlock = ({ onEdit, memberIndex }: { onEdit: () => void; memberIndex: number }) => {
  const member = useSelector(profileStateSelector).secondaryMembers[memberIndex]
  const { firstName, lastName, email, cellNumber, birthDate } = member

  return (
    <FormBlock>
      <Grid item>
        <FormBlockHeading title="Additional member" />
        <EditButton onClick={onEdit} />
        <Grid direction="column" spacing={1} container>
          <Grid item>{`${firstName} ${lastName}`}</Grid>
          <Grid item>{birthDate}</Grid>
          <Grid item>{email}</Grid>
          <Grid item>{formatPhoneNumber(cellNumber)}</Grid>
        </Grid>
      </Grid>
    </FormBlock>
  )
}
