import React from 'react'
import Box, { BoxProps } from '@material-ui/core/Box'

interface ILoadingBox extends BoxProps {
  isLoading: boolean
}

const LoadingBox = ({ isLoading, ...props }: ILoadingBox) => {
  return (
    <Box {...props} className={`loading-box ${isLoading ? 'loading' : ''}`}>
      {props.children}
    </Box>
  )
}

export default LoadingBox
