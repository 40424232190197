import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import { Location } from '../../interfaces'
import { useHistory, useLocation } from 'react-router-dom'

export type ClubLocationsSelectProps = {
  defaultClubNumber: string
  clubLocations: Location[]
}

export const ClubLocationsSelect = ({ defaultClubNumber, clubLocations }: ClubLocationsSelectProps) => {
  const history = useHistory()
  const location = useLocation()

  const changeClubHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newClubNumber = event.target.value as string

    const params = new URLSearchParams(location.search)
    params.set('club', newClubNumber)

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    })

    window.location.reload()
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="outlined" fullWidth>
        <Typography style={{ fontWeight: 500, color: '#fff' }} variant="caption">
          Selected Club
        </Typography>
        <Select
          defaultValue={defaultClubNumber}
          style={{ backgroundColor: '#fff', width: '294px' }}
          onChange={changeClubHandler}
        >
          {clubLocations.map(({ tag, address1, clubName }) => (
            <MenuItem key={tag} value={tag}>
              {clubName} - {address1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
