import React, { ChangeEvent } from 'react'
import { FormInput } from 'src/components'
import { transformName, transformPhoneNumber } from 'src/helpers'
import { validateField } from '../validation'
import Grid, { GridSize } from '@material-ui/core/Grid'
import { FieldOptionState, ProfileFields, SelectOption } from 'src/interfaces'
import { FormInputType } from 'src/components/FormInput'
import { TransformValue } from 'src/common'

const transformSchema: Record<string, TransformValue> = {
  phone: transformPhoneNumber,
  name: transformName,
}

const NAME_FIELDS = ['firstName', 'lastName', 'emergencyContactFirstName', 'emergencyContactLastName']
const PHONE_FIELDS = ['workNumber', 'homeNumber', 'cellNumber']

export const ProfileField = (props: {
  fieldName: string
  maxLength?: number
  nextIteration: number
  onChange: (event: any) => void
  options?: SelectOption[]
  propName: keyof ProfileFields
  sm?: GridSize
  state: FieldOptionState
  transformValue?: TransformValue
  validate?: () => boolean
  value: string
}) => {
  const { options, propName, maxLength = 15, value, state, onChange, nextIteration, sm = 6, ...opts } = props

  if (state === FieldOptionState.hide) return null

  let type = 'text'

  switch (true) {
    case !!options:
      type = 'select'
      break
    case NAME_FIELDS.includes(propName):
      type = 'name'
      break
    case PHONE_FIELDS.includes(propName):
      type = 'phone'
      break
  }

  const isRequired = state === FieldOptionState.required
  const isValid = opts.validate ? opts.validate() : validateField(propName, value, isRequired)
  const isSubmitted = nextIteration > 0
  const transform = opts.transformValue || transformSchema[type]

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value

    if (transform) {
      newValue = transform(event.target.value) ?? value
    }

    onChange({ target: { value: newValue } })
  }

  return (
    <Grid sm={sm} xs={12} item>
      <FormInput
        error={isSubmitted && ((value && !isValid) || (!value.trim() && isRequired))}
        fieldName={opts.fieldName}
        maxLength={maxLength}
        nextIteration={nextIteration}
        options={options}
        propName={propName}
        required={isRequired}
        type={(type === 'name' ? 'text' : type) as FormInputType['type']}
        value={value}
        onChange={handleChange}
      />
    </Grid>
  )
}
