import React, { useEffect, useState } from 'react'
import Parser from 'html-react-parser'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import Link from '@material-ui/core/Link'
import { GymPlan } from 'src/interfaces'
import { getCardColorSchema, useMediaDown } from 'src/helpers'
import usePlanStyles from './styles'

type PlanComponentProps = {
  plan: GymPlan
  selected?: boolean
  clearSelectedAction?: () => void
  idPrefix?: string
}

function PlanComponent({ plan, selected, clearSelectedAction, idPrefix = '' }: PlanComponentProps): JSX.Element {
  const theme = useTheme()
  const [colors, setColors] = useState(getCardColorSchema(theme, selected))
  const classes = usePlanStyles()
  const isMobile = useMediaDown('xs')
  const ribbonText = plan.ribbonLabel

  const showRibbon = !selected && !(isMobile && !ribbonText)

  useEffect(() => {
    setColors(getCardColorSchema(theme, selected))
  }, [selected])

  return (
    <Grid md={4} sm={6} xs={12} item>
      <Radio checked={selected} className={classes.hidden} id={idPrefix + plan.planId} value={plan.planId} />
      {selected && clearSelectedAction && (
        <Box pb={1.5}>
          <Link className={classes.clearPlanLink} onClick={clearSelectedAction}>
            <Typography className={classes.clearPlanText} variant="body1">
              Clear plan selection
            </Typography>
            <Close className={classes.clearPlanBtn} />
          </Link>
        </Box>
      )}
      <label className={`${classes.contentBlock} ${selected ? 'selected' : ''}`} htmlFor={idPrefix + plan.planId}>
        {showRibbon && (
          <Typography className={classes.ribbon} style={{ opacity: ribbonText ? 1 : 0 }} variant="body1">
            {ribbonText}
          </Typography>
        )}
        <Card
          className={classes.card}
          style={{
            borderColor: ribbonText && !selected ? theme.palette.primary.main : colors.activeColor,
            borderWidth: selected ? '5px' : ribbonText ? '4px' : '1px',
            backgroundColor: `${colors.activeColor}27`,
            height: showRibbon ? 'calc(100% - 35px)' : '',
          }}
        >
          <CardHeader
            className={classes.cardHeader}
            style={{ backgroundColor: colors.activeHeaderColor, color: colors.activeTextColor }}
            title={plan.planName}
            titleTypographyProps={{
              align: 'center',
              variant: 'subtitle1',
            }}
          />
          <CardContent>{Parser(atob(plan.agreementDescription))}</CardContent>
        </Card>
      </label>
    </Grid>
  )
}

export default PlanComponent
