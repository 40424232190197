import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { gymService } from 'src/services'
import * as states from '../states'

export const fetchCampaigns = createAsyncThunk('gym/fetchCampaigns', async (clubId: string) => {
  const { data } = await gymService.getCampaigns(clubId)

  return data
})

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState: states.initialCampaignsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCampaigns.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.campaigns = action.payload
      return state
    })
    builder.addCase(fetchCampaigns.pending, (state) => {
      state.loading = true
      return state
    })
    builder.addCase(fetchCampaigns.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error.message
      return state
    })
  },
})

export default campaignsSlice.reducer
