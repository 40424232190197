import { useState } from 'react'
import { useSelector } from 'react-redux'
import { mainProfileFieldsSelector, planStateSelector } from 'src/selectors'
import { SecondaryMember, ProfileFields, UserDefinedField } from 'src/interfaces'
import { DATES_FORMAT_PATTERNS, TransformValue } from 'src/common'
import moment from 'moment'
import { validateField, validation } from './validation'

export const useProfilePage = () => {
  const reduxPlanInfo = useSelector(planStateSelector)
  const mainProfileFields = useSelector(mainProfileFieldsSelector)
  const [profileFields, setProfileFields] = useState(mainProfileFields)
  const [secondaryMembers, setSecondaryMembers] = useState<SecondaryMember[]>([])
  const [UDFs, setUDFs] = useState<UserDefinedField[]>([])
  const isValid = validation(profileFields)

  const handleChange = (prop: keyof ProfileFields, transformValue?: TransformValue) => (event: any, label?: any) => {
    const changes: { value: string; label?: string } = { value: event?.target?.value || '' }

    if (typeof event.target.checked !== 'undefined') {
      // handle checkbox case
      changes.value = event.target.checked
    } else if (label) {
      changes.label = label
    }

    if (transformValue) {
      changes.value = transformValue(changes.value) ?? profileFields[prop]?.value
    }

    setProfileFields({
      ...profileFields,
      [prop]: {
        ...profileFields[prop],
        ...changes,
      },
    })
  }

  const isFieldsValid = () => {
    let valid = Object.keys(profileFields).every(isValid)

    if (valid && reduxPlanInfo.additionalMembersAllowed) {
      valid = secondaryMembers.every((member) =>
        Object.keys(member).every((fieldName) =>
          validateField(fieldName, member[fieldName as keyof SecondaryMember], true),
        ),
      )
    }

    //validate custom fields
    for (const field of UDFs) {
      if (
        (field.required && !field.value?.trim()) ||
        (field.type === 'date' && field.value && !moment(field.value, DATES_FORMAT_PATTERNS.date.value, true).isValid())
      ) {
        valid = false
        break
      }
    }

    return valid
  }

  return {
    profileFields,
    setProfileFields,
    handleChange,
    isFieldsValid,
    secondaryMembers,
    setSecondaryMembers,
    UDFs,
    setUDFs,
    isValid,
  }
}
