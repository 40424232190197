import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import store from './configureStore'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import { startIframeInfoExchange } from './helpers'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/signup">
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

/**
 * if the app is used in iframe - we need a possibility to emit some events outside of application.
 * By sending events at different times, we can, for example, synchronize the height of the application
 * or scroll through the application at a certain moment and so on.
 */
startIframeInfoExchange()
