import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import chequeSrc from '../assets/cheque.png'
import InfoIcon from '@material-ui/icons/Info'
import { useStyles } from '../styles'
import Box from '@material-ui/core/Box'
import { useMediaUp } from 'src/helpers'

const HintTooltip = ({ hintText }: { hintText: string }): JSX.Element => {
  const classes = useStyles()
  const isDesktop = useMediaUp('md')
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box alignItems="center" display="flex">
      <Typography className={classes.hint} component="span" variant="body2">
        {hintText}
      </Typography>
      <Tooltip
        PopperProps={{ keepMounted: true }}
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        enterTouchDelay={500}
        leaveTouchDelay={500}
        open={isOpen}
        title={<img src={chequeSrc} />}
        disableHoverListener
        onClick={() => {
          if (isDesktop) return
          setIsOpen(!isOpen)
        }}
        onMouseEnter={() => {
          if (!isDesktop) return
          setIsOpen(true)
        }}
        onMouseLeave={() => {
          setIsOpen(false)
        }}
      >
        <InfoIcon classes={{ root: classes.hintIcon }} fontSize="small" />
      </Tooltip>
    </Box>
  )
}

export default HintTooltip
