import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Footer, Header, LoadingBackdrop, LoadingBox } from 'src/components'
import {
  fetchGymById,
  gymSlice,
  loadingSlice,
  fetchCountriesAndStates,
  fetchCampaigns,
  profileInformationSlice,
} from 'src/reducers'
import { GuestPage, GuestReviewPage, PaymentPage, PlanPage, ProfilePage, ReviewPage } from 'src/routes'
import { APP_MODIFIER_PARAMS, defaultMuiThemeConfig, PAGES } from 'src/common'
import { appIsUsedInIframe, getBrandingColors, getGymSalesUserData, useQuery } from 'src/helpers'
import { RootState } from 'src/configureStore'
import { GymDetails } from 'src/interfaces'
import { setTrackers } from './trackers'
import pkg from '../package.json'
import './App.scss'
import { fetchLocations } from './reducers/locations/locations'

function App() {
  const dispatch = useDispatch()
  const params = useQuery()
  const location = useLocation()
  const [theme, setTheme] = useState(createTheme(defaultMuiThemeConfig))
  const [colorSchemaReady, setColorSchemaReady] = useState(false)
  const newOnlineJoin = useSelector((state: RootState) => state.gymSlice.gym.newOnlineJoin)
  const minAppHeight = appIsUsedInIframe() ? '' : '100vh'
  const [guestMode] = useState(location.pathname === PAGES.guest)

  const setGymColorTheme = (gymInfo: GymDetails) => {
    const { primaryColor, secondaryColor } = getBrandingColors(gymInfo)
    const brandedThemeConfig = {
      ...defaultMuiThemeConfig,
      palette: {
        primary: {
          main: primaryColor,
        },
        secondary: {
          main: secondaryColor,
        },
        action: {
          disabledBackground: primaryColor,
          disabled: 'white',
        },
      },
    }
    setTheme(createTheme(brandedThemeConfig))
  }

  useEffect(() => {
    const gymId = params.get('club')
    const gsUserId = params.get(APP_MODIFIER_PARAMS.gsUserId)
    const gsToken = params.get(APP_MODIFIER_PARAMS.gsUserToken)
    const promoCode = params.get(APP_MODIFIER_PARAMS.promoCode)

    if (!gymId) return

    dispatch(loadingSlice.actions.startLoading('gymDataFetch'))
    dispatch(fetchGymById({ gymId, promoCode })).then((resp: any) => {
      const gymSettings = resp.payload?.[0][0]

      setGymColorTheme(gymSettings)
      dispatch(gymSlice.actions.setClubId(gymId))
      setColorSchemaReady(true)
      setTrackers(gymSettings)

      dispatch(loadingSlice.actions.endLoading('gymDataFetch'))
    })
    dispatch(fetchCountriesAndStates(gymId))
    dispatch(fetchCampaigns(gymId))
    dispatch(fetchLocations(gymId))

    // get and setup prefilled user fields from the GymSales service
    if (gsUserId && gsToken) {
      dispatch(loadingSlice.actions.startLoading('userDataFetch'))
      getGymSalesUserData(gsUserId, gsToken).then((fields) => {
        dispatch(profileInformationSlice.actions.setProfileInfo(fields))
        dispatch(loadingSlice.actions.endLoading('userDataFetch'))
      })
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="span"
        dangerouslySetInnerHTML={{ __html: `<!-- ${pkg.version} -->` }}
        style={{ display: 'none' }}
      />
      <LoadingBox
        display="flex"
        flexDirection="column"
        isLoading={!colorSchemaReady}
        justifyContent="space-between"
        minHeight={minAppHeight}
      >
        {newOnlineJoin === 'false' ? (
          <Box alignItems="center" display="flex" height={minAppHeight} justifyContent="center">
            <Typography component="h6">This page is not available. Please contact the club.</Typography>
          </Box>
        ) : (
          <>
            <Header guestMode={guestMode} />
            <Box className="container-fluid" flex={1} pb={4} pt={[4, 4, 5]}>
              <Route component={PlanPage} path={PAGES.plan} />
              <Route component={ProfilePage} path={PAGES.profile} />
              <Route component={PaymentPage} path={PAGES.payment} />
              <Route component={ReviewPage} path={PAGES.review} />
              <Route component={GuestPage} path={PAGES.guest} />
              <Route component={GuestReviewPage} path={PAGES.guestReview} />
            </Box>
            <Footer guestMode={guestMode} />
          </>
        )}
      </LoadingBox>
      <LoadingBackdrop />
    </ThemeProvider>
  )
}

export default App
