import * as validCard from 'card-validator'
import * as validBank from 'us-bank-account-validator'
import { ValidatedPassword } from 'src/interfaces'
import { validCardBrands } from 'src/common'

// https://stackoverflow.com/a/29767609
export function isValidPhone(phone: string): boolean {
  const regex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return regex.test(phone)
}

// https://stackoverflow.com/a/46181
export function isValidEmail(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email.toLowerCase())
}

// https://stackoverflow.com/a/160880
export function isValidPostalCode(postalCode: string, countryCode = ''): boolean {
  let regex
  switch (countryCode) {
    case 'US':
      regex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/
      break
    case 'CA':
      regex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/
      break
    default:
      regex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/
  }
  return regex.test(postalCode)
}

export function isFutureDate(date: string): boolean {
  const now = new Date()
  const target = new Date(date)
  return target >= now
}

export function isPastDate(date: string): boolean {
  const now = new Date()
  const target = new Date(date)
  return target <= now
}

export function isValidCard(card: string): boolean {
  const cardObject = validCard.number(card)
  return !!cardObject?.isValid
}

export function isValidCVV(cvv: string, card: string): boolean {
  const cardObject = validCard.number(card)
  if (cardObject?.card?.type === 'american-express') {
    return validCard.cvv(cvv, 4).isValid
  } else {
    return validCard.cvv(cvv).isValid
  }
}

export function getCardType(card: string): string {
  const cardObject = validCard.number(card)
  const cardType = cardObject?.card?.type
  return (cardType && validCardBrands[cardType]) || ''
}

export function isValidAccountNumber(account: string): boolean {
  const accountObject = validBank.accountNumber(account)
  return !!accountObject?.isValid
}

export function isValidRoutingNumber(routing: string): boolean {
  const routingObject = validBank.routingNumber(routing)
  return !!routingObject?.isValid
}

export function isValidTransitNumber(transitNumber: string): boolean {
  return /^\d{5}$/.test(transitNumber)
}

export function isValidBankNumber(bankNumber: string): boolean {
  return /^\d{3}$/.test(bankNumber)
}

export function isValidPassword(password: string, verifiedPassword: string): ValidatedPassword {
  const ValidatedResponse: ValidatedPassword = {
    password,
    verifiedPassword,
    errors: [],
    valid: true,
  }

  //Validate Passwords Match
  if (ValidatedResponse.password !== ValidatedResponse.verifiedPassword) {
    ValidatedResponse.errors.push('Password and verified password do not match!')
    ValidatedResponse.valid = false
  }

  //Validate Password Length
  if (ValidatedResponse.password.length < 8) {
    ValidatedResponse.errors.push('Password must be at least 8 characters!')
    ValidatedResponse.valid = false
  }
  //Validate has UPPER and lower case
  if (!ValidatedResponse.password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
    ValidatedResponse.errors.push('Password must contain UPPER and lower case letters')
    ValidatedResponse.valid = false
  }

  //Validate has number and special character
  if (!ValidatedResponse.password.match(/([!@#$%^&*?_~])/) && !ValidatedResponse.password.match(/([0-9])/)) {
    ValidatedResponse.errors.push('Password must contain at least one number and one special character')
    ValidatedResponse.valid = false
  }

  return ValidatedResponse
}
