import { initTiktok } from './tiktok'
import { initFb } from './fb'
import { initGA } from './gtag'

export const setTrackers: (config: Record<string, string>) => void = (config) => {
  if (config.tikTokTrackingId) {
    initTiktok(config.tikTokTrackingId)
  }

  if (config.facebookTrackingId) {
    initFb(config.facebookTrackingId)
  }

  if (config.ga4TrackingId) {
    initGA(config.ga4TrackingId)
  }
}
