import makeStyles from '@material-ui/core/styles/makeStyles'

const styles = makeStyles((theme) => {
  return {
    hidden: {
      display: 'none',
    },
    ribbon: {
      display: 'block',
      height: 40,
      lineHeight: '40px',
      color: '#fff',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderRadius: '5px 5px 0 0',
      marginBottom: -5,
      position: 'relative',
      background: theme.palette.primary.main,
    },
    card: {
      border: '5px solid',
      boxSizing: 'border-box',
      width: '100%',
      cursor: 'pointer',
      height: '100%',
      transition: 'border 250ms ease-in',

      '& span': {
        fontWeight: 'bold',
      },
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
    cardHeader: {
      transition: 'background-color 250ms ease-in',
    },
    contentBlock: {
      height: '100%',
      display: 'block',
      minHeight: 250,

      '&.selected': {
        height: 'auto',
        minHeight: 0,
      },
    },
    clearPlanBtn: {
      transform: 'translateY(7px)',
    },
    clearPlanLink: {
      cursor: 'pointer',
      textAlign: 'right',
      textDecoration: 'none',
      display: 'block',
    },
    clearPlanText: {
      display: 'inline-block',
    },
  }
})

export default styles
