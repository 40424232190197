import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import Typography from '@material-ui/core/Typography'
import { CheckboxWithLabel } from 'src/components'

export const ElectronicAgreementAcknowledgement = ({
  checked,
  setChecked,
}: {
  checked: boolean
  setChecked: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element => {
  const toggleChecked = () => setChecked((state) => !state)

  return (
    <>
      <FormGroup row>
        <CheckboxWithLabel
          checkbox={{
            checked,
            onChange: toggleChecked,
            name: 'electronicAgreement',
          }}
          controlLabel={{ label: 'I acknowledge that: ' }}
          required
        />
      </FormGroup>
      <FormGroup className="tc-body" style={{ gap: 8, textAlign: 'justify'  }} row>
        <Typography variant="body1">
          (1) I have the right to receive a paper copy of my Membership Agreement (“Agreement”), but am opting out of
          this option and affirmatively consent to receive an electronic copy of my Agreement at no charge or cost to
          me;
        </Typography>
        <Typography variant="body1">
          (2) I have the right to revoke this consent at any time in person at my home club or with written notice sent
          by mail to my home club;
        </Typography>
        <Typography variant="body1">
          (3) this consent is valid for the duration of my membership and applies to all membership-related documents;
        </Typography>
        <Typography variant="body1">
          (4) I may print a PDF copy or request a paper copy of my Agreement at any time by inquiring at the front desk
          of my home club; and
        </Typography>
        <Typography variant="body1">
          (5) I have the ability to access email through a web browser or application, and open a PDF using a desktop
          application, mobile application, or web browser so that I can access my Agreement electronically.
        </Typography>
      </FormGroup>
    </>
  )
}
