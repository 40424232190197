import Typography from '@material-ui/core/Typography'
import React from 'react'

const AuthTerms = () => {
  return (
    <Typography>
      By executing this Agreement, you authorize Club and Club’s agents, including its third party payment processing
      companies (&quot;Club’s Agents&quot;), to store the account or card information provided by You on or in relation
      to this Agreement and/or Your Club Membership Agreement (&quot;Club Agreement&quot;), as well as any other account
      or card information provided by You through any means to Club or Club’s Agents (including information provided in
      person, online or over the phone) for purposes of making any payment in relation to this Agreement and/or Your
      Club Agreement (hereinafter, &quot;Payment Information&quot;). Club and/or Club’s Agents will use the stored
      Payment Information to process payment of all dues, fees, taxes, purchases and incidental charges that are due or
      will become due, including all items on the Payment Schedule, fees identified in Your Club Agreement,
      membership-related obligations, retail transactions, personal training purchases, group exercise purchases,
      childcare fees, or other purchases. Club and/or Club’s Agents may also use the stored Payment Information to
      process payments owed in relation to all subsequent agreements entered between You and Club. The fixed dates or
      intervals on which transactions will be processed and the transaction amounts (including all associated fees,
      taxes and charges) and/or a description of how they will be calculated, are more specifically set forth in the
      Payment Schedule and other terms of Your Club Agreement. If Your Club Agreement will automatically renew at the
      end of the Term defined therein, the stored Payment Information will be used to process payments owed in relation
      to the renewal term. This consent to store Payment Information will not expire unless it is expressly revoked. The
      general cancellation and refund policies provided in Your Club Agreement will apply to this consent. If any
      changes are made to the terms of this consent, an e-mail notifying you of such changes will be sent to the e-mail
      address provided by You on the face of Your Club Agreement or, if an e-mail is not provided, notice will be sent
      to the mailing address provided on Your Club Agreement.
      <br />
    </Typography>
  )
}

export default AuthTerms
