import React, { ChangeEvent, useEffect, useState } from 'react'
import moment from 'moment'
import Grid, { GridSize } from '@material-ui/core/Grid'
import { DateInput } from 'src/components'
import { FieldOptionState } from 'src/interfaces'
import uniqueId from 'lodash/uniqueId'
import uniq from 'lodash/uniq'
import without from 'lodash/without'

export const BirthdateField = ({
  value,
  state,
  onChange,
  minorAge,
  setMinors,
  nextIteration,
  md = 6,
}: {
  md?: GridSize
  minorAge: string | undefined
  nextIteration: number
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  setMinors: React.Dispatch<React.SetStateAction<string[]>>
  state: FieldOptionState
  value: string
}) => {
  const propName = 'birthDate'
  const [fieldId] = useState(uniqueId(propName))
  const [isPikerOpened, setIsPickerOpened] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const isRequired = state === FieldOptionState.required
  const isHidden = state === FieldOptionState.hide

  useEffect(() => {
    if (!minorAge || isPikerOpened || isHidden) {
      return
    }

    const memberAge = Math.floor(moment().diff(value, 'years', true))
    const isMinor = !!value && memberAge >= 0 && memberAge < Number(minorAge)

    setErrorMessage(isMinor ? 'Minors are not allowed' : undefined)
    setMinors((minors) => (isMinor ? uniq([...minors, fieldId]) : without(minors, fieldId)))
  }, [value, isPikerOpened])

  useEffect(() => () => setMinors((minors) => without(minors, fieldId)), [])

  return isHidden ? null : (
    <Grid md={md} xs={12} item>
      <DateInput
        error={nextIteration > 0 && ((!value && isRequired) || !!errorMessage)}
        errorMessage={errorMessage}
        fieldName="Date of Birth"
        nextIteration={nextIteration}
        propName={propName}
        required={isRequired}
        type="date"
        value={value}
        disableFuture
        onChange={onChange}
        onClose={() => setIsPickerOpened(false)}
        onOpen={() => setIsPickerOpened(true)}
      />
    </Grid>
  )
}
