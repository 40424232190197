import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { Schedule } from 'src/interfaces'
import Addon from './Addon'
import Modal from './Modal'
import { useMediaUp } from 'src/helpers'
import { clubNumberSelector } from 'src/selectors'
import { selectAddons } from 'src/reducers'

export const AddonsModal = ({ onClose, planId }: { onClose: () => void; planId: string }) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaUp('md')
  const addons = useSelector((state: RootState) =>
    state.planInformationSlice.schedules.filter((s: Schedule) => s.addon),
  )
  const { scheduleFrequency } = useSelector((state: RootState) => state.planInformationSlice)
  const [addonsSelected, setAddonsSelected] = useState<Schedule[]>(
    addons.filter((s: any) => s.defaultChecked || s.userSelected),
  )
  const nonDefaultAddons = addons.filter((item) => !item.defaultChecked)
  const clubNumber = useSelector(clubNumberSelector)

  const handleAddonChange = (selected: boolean, addon: any) => {
    setAddonsSelected(
      selected
        ? [...addonsSelected, addon]
        : addonsSelected.filter((addonItem) => addonItem.profitCenter !== addon.profitCenter),
    )
  }

  const submitAddonSelections = () => {
    const config = {
      clubNumber,
      planId,
      addons: addonsSelected,
    }

    dispatch(selectAddons(config))
    onClose()
  }

  return (
    <Modal
      cancelText="Skip"
      confirmText="Submit"
      handleCancel={onClose}
      handleConfirm={submitAddonSelections}
      subHeader="Choose any of our additional services."
      title="Add-Ons"
      open
    >
      <Grid
        alignItems="center"
        direction="row"
        justifyContent="center"
        spacing={3}
        style={{
          height: isDesktop ? '400px' : '',
          overflow: 'auto',
        }}
        container
      >
        {nonDefaultAddons.map((el: Schedule) => (
          <Grid key={el.profitCenter} lg={4} md={4} sm={6} xs={12} item>
            <Addon addon={el} scheduleFrequency={scheduleFrequency} onChange={handleAddonChange} />
          </Grid>
        ))}
      </Grid>
    </Modal>
  )
}
