import { getCardType } from '../helpers'
import {
  EmergencyContact,
  FieldOptionState,
  GymDetails,
  GymPlanSelection,
  MemberAgreement,
  MemberInfo,
  PaymentField,
  PaymentInfoOption,
  PaymentValues,
  ProfileValues,
  UserDefinedField,
} from '../interfaces'

const capitalizeWords = (str: string): string =>
  str.replaceAll(/[A-z0-9']+/g, (word) => word.replace(/^(?:\d+')*'*[A-z]/, (w) => w.toUpperCase()))

// allow latin letters, digits, space and dash symbols and capitalize each word (digit at the start is not allowed)
export const transformName = (value: string): string | undefined => {
  if (value && !/^[A-Za-z\s-'][A-Za-z\d\s-']*$/.test(value)) return

  return capitalizeWords(value)
}

// allow digits only and remove spaces
export const transformCardNumber = (value: string): string | undefined => {
  const number = value.replaceAll(' ', '')

  if (/^\d*$/.test(number)) {
    return number
  }
}

// allow letter and capitalize it
export const transformMiddlename = (value: string): string | undefined => {
  if (!/^[A-Za-z]*$/.test(value)) return

  return capitalizeWords(value)
}

// Remove special characters from phone number (555) 111-1234 to 5551111234
export function transformPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/\(|\)|\s|-/g, '').substring(1)
}

export function formatPhoneNumber(phoneNumber: string): string {
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
}

export function disguiseCardNumber(cardNumber: string): string {
  return cardNumber.replace(/\d(?=\d{4})/g, '*').replace(/(.{4})/g, '$1 ')
}

export function transformAgreement(
  gym: GymDetails,
  plan: GymPlanSelection,
  profile: ProfileValues,
  udfs: UserDefinedField[],
  payment: PaymentValues,
  params: URLSearchParams,
): MemberAgreement {
  const todayExpDate = new Date(payment.todayExpDate)
  const recurringExpDate = new Date(payment.recurringExpDate)

  const recurringPaymentName: PaymentField[] = [
    {
      id: 'recurringFirstName',
      label: 'First name',
      maxLength: 15,
      value: payment.useForRecurring ? payment.todayFirstName : payment.recurringFirstName,
    },
    {
      id: 'recurringLastName',
      label: 'Last name',
      maxLength: 19,
      value: payment.useForRecurring ? payment.todayLastName : payment.recurringLastName,
    },
  ]
  const recurringPaymentCC: PaymentField[] = [
    {
      id: 'recurringCardTypeSelect',
      label: 'Card type',
      text: getCardType(payment.useForRecurring ? payment.todayCCNumber : payment.recurringCCNumber),
      value: getCardType(payment.useForRecurring ? payment.todayCCNumber : payment.recurringCCNumber),
    },
    {
      id: 'recurringCardNumberInput',
      label: 'Card number',
      maxLength: 19,
      value: payment.useForRecurring ? payment.todayCCNumber : payment.recurringCCNumber,
    },
    {
      id: 'recurringCCMonth',
      label: 'Expiration month',
      text:
        payment.useForRecurring && payment.todayExpDate
          ? todayExpDate.toLocaleString('default', { month: 'long' })
          : payment.recurringExpDate
            ? recurringExpDate.toLocaleString('default', { month: 'long' })
            : 'Month',
      value:
        payment.useForRecurring && payment.todayExpDate
          ? payment.todayExpDate.split('-')[1]
          : payment.recurringExpDate
            ? payment.recurringExpDate.split('-')[1]
            : '',
    },
    {
      id: 'recurringCCYear',
      label: 'Expiration year',
      text:
        payment.useForRecurring && payment.todayExpDate
          ? payment.todayExpDate.split('-')[0]
          : payment.recurringExpDate
            ? payment.recurringExpDate.split('-')[0]
            : 'Year',
      value:
        payment.useForRecurring && payment.todayExpDate
          ? payment.todayExpDate.split('-')[0]
          : payment.recurringExpDate
            ? payment.recurringExpDate.split('-')[0]
            : '',
    },
  ]
  const recurringPaymentBank: PaymentField[] = [
    {
      id: 'accountType',
      label: 'Account type',
      value: payment.recurringAccountType,
    },
    {
      id: 'recurringRoutingNumberInput',
      label: 'Routing number',
      maxLength: 9,
      value: payment.recurringRoutingNumber,
    },
    {
      id: 'recurringAccountNumberInput',
      label: 'Account number',
      maxLength: 17,
      value: payment.recurringAccountNumber,
    },
    {
      id: 'recurringAccountNumberVerificationInput',
      label: 'Verify account number',
      maxLength: 17,
      value: payment.recurringAccountNumberRepeat,
    },
  ]
  const dueTodayPayment: PaymentField[] = [
    {
      id: 'dueTodayFirstName',
      label: 'First name',
      maxLength: 15,
      value: payment.todayFirstName,
    },
    {
      id: 'dueTodayLastName',
      label: 'Last name',
      maxLength: 19,
      value: payment.todayLastName,
    },
    {
      id: 'dueTodayCardTypeSelect',
      label: 'Card type',
      text: getCardType(payment.todayCCNumber),
      value: getCardType(payment.todayCCNumber),
    },
    {
      id: 'dueTodayCardNumberInput',
      label: 'Card number',
      maxLength: 19,
      value: payment.todayCCNumber,
    },
    {
      id: 'dueTodayCCMonth',
      label: 'Month expiration',
      text: payment.todayExpDate ? todayExpDate.toLocaleString('default', { month: 'long' }) : 'Month',
      value: payment.todayExpDate ? payment.todayExpDate.split('-')[1] : '',
    },
    {
      id: 'dueTodayCCYear',
      label: 'Year expiration',
      text: payment.todayExpDate ? payment.todayExpDate.split('-')[0] : 'Year',
      value: payment.todayExpDate ? payment.todayExpDate.split('-')[0] : '',
    },
    {
      id: 'securityCode',
      label: 'Security code',
      maxLength: 4,
      value: payment.todayCVV,
    },
    {
      id: 'billingPostalCode',
      label: 'Billing postal code',
      maxLength: 10,
      value: payment.todayZip,
    },
    {
      id: 'sameCCSecurityCode',
      label: 'Security code',
      maxLength: 4,
      value: payment.todayCVV,
    },
    {
      id: 'sameCCBillingPostalCode',
      label: 'Billing postal code',
      maxLength: 10,
      value: payment.todayZip,
    },
  ]
  const memberInfo: MemberInfo[] = [
    {
      memberInfoId: 1,
      id: 'firstName',
      label: 'First name',
      abcCode: 'firstName',
      columns: 5,
      maxLength: 15,
      value: profile.firstName.value,
      hiddenShownRequired: FieldOptionState.system,
    },
    {
      memberInfoId: 2,
      id: 'lastName',
      label: 'Last name',
      abcCode: 'lastName',
      columns: 5,
      maxLength: 19,
      value: profile.lastName.value,
      hiddenShownRequired: FieldOptionState.system,
    },
    {
      memberInfoId: 3,
      id: 'middleInitial',
      label: 'MI',
      abcCode: 'middleInitial',
      columns: 2,
      maxLength: 1,
      value: profile.middleInitial.value,
      hiddenShownRequired: profile.middleInitial.state,
    },
    {
      memberInfoId: 4,
      id: 'email',
      label: 'Email',
      abcCode: 'email',
      columns: 5,
      maxLength: 50,
      value: profile.email.value,
      hiddenShownRequired: FieldOptionState.system,
    },
    {
      memberInfoId: 5,
      id: 'verifyEmail',
      label: 'Verify email',
      abcCode: 'verifyEmail',
      columns: 5,
      maxLength: 50,
      value: profile.email.value,
      hiddenShownRequired: FieldOptionState.system,
    },
    {
      memberInfoId: 6,
      id: 'mailingAddress',
      label: 'Mailing address',
      abcCode: 'street',
      columns: 5,
      maxLength: 44,
      value: profile.street.value,
      hiddenShownRequired: profile.street.state,
    },
    {
      memberInfoId: 7,
      id: 'country',
      label: 'Country',
      abcCode: 'country',
      selectList: [],
      columns: 4,
      selected: profile.country.value || 'US',
      value: profile.country.value || 'US',
      text: profile.country.value || 'United States',
      hiddenShownRequired: profile.country.state,
    },
    {
      memberInfoId: 8,
      id: 'city',
      label: 'City',
      abcCode: 'city',
      columns: 5,
      maxLength: 23,
      value: profile.city.value,
      hiddenShownRequired: profile.city.state,
    },
    {
      memberInfoId: 9,
      id: 'state',
      label: 'State/Province',
      abcCode: 'state',
      columns: 4,
      selectList: [],
      selected: profile.state.value,
      value: profile.state.value,
      text: profile.state.value,
      hiddenShownRequired: profile.state.state,
    },
    {
      memberInfoId: 10,
      id: 'postalCode',
      label: 'Postal code',
      abcCode: 'zip',
      columns: 3,
      maxLength: 10,
      value: profile.zip.value,
      hiddenShownRequired: profile.zip.state,
    },
    {
      memberInfoId: 11,
      id: 'dateOfBirth',
      label: 'Date of birth',
      abcCode: 'birthDate',
      columns: 3,
      value: profile.birthDate.value,
      hiddenShownRequired: profile.birthDate.state,
    },
    {
      memberInfoId: 12,
      id: 'gender',
      label: 'Gender',
      abcCode: 'gender',
      selectList: [
        {
          value: 'MALE',
          text: 'Male',
        },
        {
          value: 'FEMALE',
          text: 'Female',
        },
      ],
      columns: 3,
      value: profile.gender.value,
      text: profile.gender.value,
      hiddenShownRequired: profile.gender.state,
    },
    {
      memberInfoId: 13,
      id: 'homePhone',
      label: 'Home phone',
      abcCode: 'homeNumber',
      columns: 3,
      maxLength: 15,
      value: profile.homeNumber.value,
      hiddenShownRequired: profile.homeNumber.state,
    },
    {
      memberInfoId: 14,
      id: 'mobilePhone',
      label: 'Mobile phone',
      abcCode: 'cellNumber',
      columns: 3,
      maxLength: 15,
      value: profile.cellNumber.value,
      hiddenShownRequired: profile.cellNumber.state,
    },
    {
      memberInfoId: 15,
      id: 'workPhone',
      label: 'Work phone',
      abcCode: 'workNumber',
      columns: 3,
      maxLength: 15,
      value: profile.workNumber.value,
      hiddenShownRequired: profile.workNumber.state,
    },
    {
      memberInfoId: 16,
      id: 'extension',
      label: 'Extension',
      abcCode: 'workExt',
      columns: 2,
      maxLength: 4,
      value: profile.workExt.value,
      hiddenShownRequired: profile.workExt.state,
    },
    {
      memberInfoId: 17,
      id: 'driversLicense',
      label: 'Drivers license',
      abcCode: 'dl',
      columns: 5,
      maxLength: 20,
      value: profile.dl.value,
      hiddenShownRequired: profile.dl.state,
    },
    {
      memberInfoId: 18,
      id: 'employer',
      label: 'Employer',
      abcCode: 'employer',
      columns: 5,
      maxLength: 50,
      value: profile.employer.value,
      hiddenShownRequired: profile.employer.state,
    },
    {
      memberInfoId: 19,
      id: 'salesPerson',
      label: 'Sales person',
      abcCode: 'salesPersonId',
      columns: 5,
      selectList: [],
      selected: profile.salesPersonId.value,
      value: profile.salesPersonId.value,
      text: profile.salesPersonId.value,
      hiddenShownRequired: profile.salesPersonId.state,
    },
    {
      memberInfoId: 20,
      id: 'healthwaysId',
      label: 'Wellness program ID',
      abcCode: 'healthwaysId',
      columns: 5,
      maxLength: 50,
      value: profile.healthwaysId.value,
      hiddenShownRequired: profile.healthwaysId.state,
    },
    {
      memberInfoId: 21,
      id: 'campaign',
      label: 'How did you hear about us',
      abcCode: 'promotion',
      columns: 5,
      selectList: [],
      selected: profile.promotion.value,
      value: profile.promotion.value,
      text: profile.promotion.value,
      hiddenShownRequired: profile.promotion.state,
    },
    {
      memberInfoId: 22,
      id: 'mailingAddress2',
      label: 'Mailing address 2',
      abcCode: 'address2',
      columns: 5,
      maxLength: 40,
      text: '',
      value: '',
      hiddenShownRequired: FieldOptionState.hide,
    },
  ]
  const emergencyContact: EmergencyContact[] = [
    {
      id: 'emergencyFirstName',
      label: 'First name',
      abcCode: 'emergencyContactFirstName',
      columns: 6,
      maxLength: 15,
      hiddenShownRequired: profile.emergencyContactFirstName.state,
      value: profile.emergencyContactFirstName.value,
    },
    {
      id: 'emergencyLastName',
      label: 'Last name',
      abcCode: 'emergencyContactLastName',
      columns: 6,
      maxLength: 19,
      hiddenShownRequired: profile.emergencyContactLastName.state,
      value: profile.emergencyContactLastName.value,
    },
    {
      id: 'emergencyPhone',
      label: 'Phone',
      abcCode: 'emergencyContactNumber',
      columns: 3,
      maxLength: 15,
      hiddenShownRequired: profile.emergencyContactNumber.state,
      value: profile.emergencyContactNumber.value,
    },
    {
      id: 'emergencyExtension',
      label: 'Extension',
      abcCode: 'emergencyContactExtension',
      columns: 2,
      maxLength: 4,
      hiddenShownRequired: profile.emergencyContactExtension.state,
      value: profile.emergencyContactExtension.value,
    },
  ]
  const dueTodayPaymentInfoOptions: PaymentInfoOption = {
    paymentOption: payment.payLater,
    dueTodaySameAsRecurring: payment.payLater === 'payAtClub' ? true : payment.useForRecurring,
    clubIPad: params.get('clubIPad') ? 'true' : 'false',
  }

  return {
    activePresale: plan.activePresale,
    agreementSignature: payment.signature,
    club: gym,
    clubNumber: gym.number,
    dueTodayPaymentInfoData: dueTodayPayment,
    dueTodayPaymentInfoOptions: dueTodayPaymentInfoOptions,
    emergencyContact: emergencyContact,
    macAddress: params.get('clubIPad') ? 'TABLET_CLUB' : 'HOME',
    memberInfo: memberInfo,
    messagingConsent: profile.marketingSMS.value,
    planId: plan.planId,
    planValidation: plan.planValidation,
    recurringPaymentCreditCardInfo: recurringPaymentCC,
    recurringPaymentEFTInfo: recurringPaymentBank,
    recurringPaymentNameInfo: recurringPaymentName,
    schedules: plan.schedules,
    secondaryMembers: profile.secondaryMembers,
    totalDownPayment: plan.downPaymentTotalAmount,
    udfs: udfs,
  }
}
