export interface DownPayment {
  name: string
  subTotal: string
  tax: string
  total: string
}

export interface Schedule {
  profitCenter: string
  scheduleDueDate: string // date
  scheduleAmount: string // money
  schedulePreTaxAmount: string // money
  numberOfPayments: string // possibly enum?
  recurring: boolean
  addon: boolean
  defaultChecked: boolean
  description: string
}

export interface ClubFee {
  feeDueDate: string // date
  feeName: string
  feeAmount: string // money
  feeApply: boolean
  feeRecurring: boolean
}

export interface UserDefinedField {
  field: string
  table: number
  index: number
  type: string
  required: boolean
  includeSelectOne: boolean // check with luke
  values: string
  label?: string
  id?: string
  value?: string
}

export enum FieldOptionState {
  show = 'show',
  hide = 'hide',
  required = 'required',
  system = 'System',
}

export interface MemberSelectOptions {
  value: string
  text: string
  id?: string
  addressLineCount?: string
}

export interface MemberInfo {
  memberInfoId: number
  id: string // value key, ie "firstName", "lastName"
  label: string
  abcCode: string // seems to be the same as "id"
  hiddenShownRequired: FieldOptionState // idk
  columns: number
  value: string
  maxLength?: number
  text?: string
  selected?: string
  selectList?: MemberSelectOptions[]
}

export interface StateProvinceDetails {
  addressLineCount: string
  stateProvinceFullName: string
}

export interface StateCode {
  [key: string]: StateProvinceDetails
}

export interface CountryDetails {
  countryFullName: string
  statesProvinces: Array<StateCode>
}

export interface Country {
  [key: string]: CountryDetails
}

export interface SelectOption {
  order?: string
  value: string
  label: string
}

export interface Location {
  address1: string
  clubName: string
  tag: string
}
