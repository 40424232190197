import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core/styles'

export const FeeLine = ({
  name,
  amount,
  color,
  textClassName,
}: {
  name: string
  amount: string
  color: string
  textClassName?: string
}) => {
  const theme = useTheme()

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Typography className={textClassName} style={{ color }} variant="body1">
        {name}
      </Typography>
      <Typography
        className={textClassName}
        style={{ textAlign: 'right', alignSelf: 'flex-end', marginLeft: theme.spacing(2), color }}
        variant="body1"
      >
        {amount}
      </Typography>
    </Box>
  )
}
