import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface IHeadingBoxProps {
  title: string
  children?: React.ReactNode
}

const HeadingBox = (props: IHeadingBoxProps) => {
  return (
    <Box pb={1}>
      <Box pb={1}>
        <Typography style={{ fontWeight: 500 }} variant="h4">
          {props.title}
        </Typography>
      </Box>
      {props.children && (
        <Box pb={1}>
          <Typography variant="body1">{props.children}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default HeadingBox
