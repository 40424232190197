import makeStyles from '@material-ui/core/styles/makeStyles'

export const useTermsAndConditionsStyles = makeStyles({
  label: {
    fontWeight: 500,
  },
  modalOpener: {
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'underline',

    '&:hover': {
      textDecoration: 'none',
    },
  },
  signButtonStyle: {
    height: 63,
    display: 'flex',
    fontSize: 18,
    fontWeight: 'bold',
  },
  signatureBox: {
    width: 268,
    height: 68,
    background: '#fff',
    border: '1px solid #e9e9e9',
  },
  signatureImg: {
    height: '100%',
  },
})
