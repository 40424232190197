import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const FeeLine = ({ name, amount, color }: { name: string; amount: string; color: string }): JSX.Element => (
  <>
    <Grid xs={8} item>
      <Typography style={{ color }} variant="body1">
        {name}
      </Typography>
    </Grid>
    <Grid xs={4} item>
      <Typography style={{ textAlign: 'right', color }} variant="body1">
        {amount}
      </Typography>
    </Grid>
  </>
)

export default FeeLine
