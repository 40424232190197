import { ClubFee, DownPayment, FieldOptionState, Schedule, UserDefinedField } from './entities'
import { TStringBoolean } from 'src/common'

interface CardNumberAcceptedLengths {
  AMEX: string[]
  VISA: string[]
  DISCOVER: string[]
  MASTERCARD: string[]
}

export enum PlanDisplayLocation {
  ipad = 'PAD',
  web = 'WEB',
  all = 'ALL',
}

export interface GymPlan {
  additionalMembersAllowed: TStringBoolean
  agreementDescription: string
  corporatePlanOnly: string
  limitedAvailability: TStringBoolean
  membershipTypeId: string
  planEndDate: string
  planId: string
  planName: string
  planStartDate: string
  promoCode: string
  promoName: string
  ribbonLabel?: string
  webPlanDisplayLocation: PlanDisplayLocation
}

export interface GymDetails {
  abcMobileApp: TStringBoolean
  address1: string
  address2: string
  afterTransferUrl: string
  allowAddonImageSignature: string
  allowAddons: boolean
  allowCashDown: string
  allowImageSignature: string
  allowTransfer: boolean
  allowedClubAccountPastDuePaymentMethods: string
  backgroundColor: string
  billingCountry: string
  bookingWindow: string
  brandColor: string
  calendarHighTime: string
  calendarLowTime: string
  cardNumberAcceptedLengths: CardNumberAcceptedLengths
  city: string
  clubAccountPurchaseHistoryCode: string
  corporateId: string
  country: string
  displayRenewalTermsVerbiage: boolean
  dtEmail: string
  email: string
  externalTransferUrl: string
  facebook: string
  filterPlansByMembershipType: boolean
  generalNoticeTerms: string
  hasWebServiceTerminal: boolean
  homeClub: boolean
  id: string
  limitedAccess: boolean
  logoUrl: string
  micoDraftMethod: string
  minorAge: string
  minorAllowSignUp: boolean
  minorDisclaimer: string
  name: string
  newOnlineJoin: TStringBoolean
  number: string
  onlineAgreements: boolean
  onlineAllowShop: boolean
  onlineAppointments: boolean
  onlineCancelAutoRenew: boolean
  onlineCancelAutoRenewTimePeriod: number
  onlineClasses: boolean
  onlineDraftMethod: string
  onlineEmployeePortal: boolean
  onlineProspects: boolean
  only550: boolean
  payMode: string
  paymentMethods: string[]
  phone: string
  phoneExt: string
  preSale: boolean
  preferredBillingMethod: string
  primaryColor: string
  promptMicoRegistration: boolean
  prospectTerms: string
  requireCCNameDisclaimer: string
  secondaryColor: string
  showElectronicAgreementAcknowledgement: boolean
  showFees: string
  showLevels: boolean
  state: string
  supportedCountryAddress: string[]
  theme: string
  trackOpportunity: boolean
  transferMessage: string
  transferUrl: string
  twitter: string
  useExternalTransferUrl: boolean
  usesClubAccountPastDue: boolean
  websiteUrl: string
  zip: string
}

export interface FieldOptionValue {
  state: FieldOptionState
  value: any
  label?: string
}

export interface SecondaryMember {
  firstName: string
  lastName: string
  email: string
  cellNumber: string
  birthDate: string
}

export interface ProfileFields {
  firstName: FieldOptionValue
  middleInitial: FieldOptionValue
  lastName: FieldOptionValue
  email: FieldOptionValue
  cellNumber: FieldOptionValue
  homeNumber: FieldOptionValue
  street: FieldOptionValue
  country: FieldOptionValue
  city: FieldOptionValue
  state: FieldOptionValue
  zip: FieldOptionValue
  birthDate: FieldOptionValue
  gender: FieldOptionValue
  dl: FieldOptionValue
  emergencyContactFirstName: FieldOptionValue
  emergencyContactLastName: FieldOptionValue
  emergencyContactNumber: FieldOptionValue
  emergencyContactExtension: FieldOptionValue
  salesPersonId: FieldOptionValue
  promotion: FieldOptionValue
  employer: FieldOptionValue
  workNumber: FieldOptionValue
  workExt: FieldOptionValue
  healthwaysId: FieldOptionValue
  marketingEmails: FieldOptionValue
  marketingSMS: FieldOptionValue
  marketingDirectMail: FieldOptionValue
  marketingPushNotifications: FieldOptionValue
  messagingConsent: FieldOptionValue
}

export interface ProfileValues extends ProfileFields {
  secondaryMembers: SecondaryMember[]
}

export interface GuestValues {
  firstName: string
  lastName: string
  email: string
  homePhone: string
  receivePromotionOffers: boolean
  messagingConsent: boolean
  streetAddress: string
  city: string
  state: string
  zip: string
  countryCode: string
  dateOfBirth: string
  gender: string
  campaign: {
    label: string
    value: string
  }
  signatureImage: string
  address2: string
  mobilePhone: string
  favoriteHotel: string
}

export interface PaymentValues {
  todayFirstName: string
  todayLastName: string
  todayCCNumber: string
  todayExpDate: string
  todayCVV: string
  todayZip: string
  recurringFirstName: string
  recurringLastName: string
  recurringCCNumber: string
  recurringExpDate: string
  recurringRoutingNumber: string
  recurringTransitNumber: string
  recurringBankNumber: string
  recurringAccountNumber: string
  recurringAccountNumberRepeat: string
  recurringAccountType: string
  useForRecurring: boolean
  signature: string
  payLater: string
}

export interface FieldOption {
  fieldOptionCode: keyof ProfileFields
  state: string
}

export interface GymPlanSelection {
  activePresale: boolean
  additionalMembersAllowed: number
  agreementDescription: string
  agreementNote: string
  agreementTerm: string
  agreementTerms: string
  clubFeeTotalAmount: string
  clubFees: ClubFee[]
  corporatePlanOnly: string
  downPaymentName: string
  downPaymentTotalAmount: string
  downPayments: DownPayment[]
  dueDay: string
  expirationDate: string
  fieldOption: FieldOption[]
  firstDueDate: string
  hasAutoRenew: boolean
  membershipType: string
  message: string
  onlinePlanDisplayLocation: string
  onlineSignupAllowedPaymentMethods: string
  planId: string
  planName: string
  planValidation: string
  preferredPaymentMethod: string
  scheduleFrequency: string
  scheduleTotalAmount: string // money again
  schedules: Schedule[]
  termInMonths: string
  totalContractValue: string
  userDefinedFields: UserDefinedField[]
}

export interface Campaign {
  id: number
  value: string
  text: string
}
