import { isPastDate, isValidEmail, isValidPhone, isValidPostalCode } from 'src/helpers'
import { FieldOptionState, ProfileFields } from 'src/interfaces'

const schema: Record<string, (value: string, fields: Partial<ProfileFields>) => boolean> = {
  birthDate: isPastDate,
  cellNumber: isValidPhone,
  homeNumber: isValidPhone,
  emergencyContactNumber: isValidPhone,
  workNumber: isValidPhone,
  email: isValidEmail,
  zip: (value, fields) => isValidPostalCode(value, fields.country?.value),
}

export const validateField = (
  fieldName: string,
  value: string | boolean = '',
  isRequired: boolean,
  fields?: Partial<ProfileFields>,
) => {
  if (typeof value === 'boolean') return true

  if (value.trim()) {
    const validateField = schema[fieldName]

    return !validateField || validateField(value, fields ?? {})
  }

  return !isRequired
}

export const validation = (fields: Partial<ProfileFields>): ((fieldName: string) => boolean) => {
  return (fieldName) => {
    const field = fields[fieldName as keyof ProfileFields]

    if (!field || field.state === FieldOptionState.hide) return true

    return validateField(fieldName, field.value, field.state === FieldOptionState.required, fields)
  }
}
