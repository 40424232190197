import { useLocation } from 'react-router-dom'

export const useQuery = () => new URLSearchParams(useLocation().search)

interface IGetQueryUrlState {
  key?: string
  value?: string
  path?: string
}

export const getQueryUrlState = ({ key, value, path }: IGetQueryUrlState) => {
  const searchParams = new URLSearchParams(window.location.search)

  if (key) {
    if (value) {
      searchParams.set(key, value)
    } else {
      searchParams.delete(key)
    }
  }

  const state: { search: string; pathname?: string } = {
    search: searchParams.toString(),
  }

  if (path) {
    state.pathname = path
  }

  return state
}
