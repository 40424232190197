import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { FormBlock, FormBlockHeading } from 'src/components'
import { formatPhoneNumber, useMediaDown } from 'src/helpers'
import { ProfileFields } from 'src/interfaces'
import { EditButton } from './'
import { profileStateSelector } from 'src/selectors'

const MAIN_FIELDS = ['street', 'city', 'state', 'zip', 'gender', 'birthDate', 'dl']
const SECONDARY_FIELDS = ['employer', 'workNumber', 'workExt', 'healthwaysId']

const PersonalInfoBlock = ({ onEdit }: { onEdit: () => void }) => {
  const reduxProfileInfo = useSelector(profileStateSelector)
  const isMobile = useMediaDown('sm')

  const profileHasAnyValue = useCallback(
    (keysList: string[]) => keysList.some((profileKey) => reduxProfileInfo[profileKey as keyof ProfileFields]?.value),
    [reduxProfileInfo],
  )

  if (!profileHasAnyValue([...MAIN_FIELDS, ...SECONDARY_FIELDS])) {
    return null
  }

  return (
    <FormBlock>
      <Grid item>
        <FormBlockHeading title="Personal information" />
        <EditButton onClick={onEdit} />
        <Grid spacing={isMobile ? 2 : 3} container>
          {profileHasAnyValue(MAIN_FIELDS) && (
            <Grid sm={6} xs={12} item>
              <Grid direction="column" spacing={1} container>
                {reduxProfileInfo.street.value && <Grid item>{reduxProfileInfo.street.value}</Grid>}
                {(reduxProfileInfo.city.value || reduxProfileInfo.state.value || reduxProfileInfo.zip.value) && (
                  <Grid item>
                    {reduxProfileInfo.city.value}
                    {reduxProfileInfo.city.value && reduxProfileInfo.state.value ? ', ' : ''}
                    {reduxProfileInfo.state.label || reduxProfileInfo.state.value} {reduxProfileInfo.zip.value}
                  </Grid>
                )}
                {reduxProfileInfo.gender.value && (
                  <Grid item>{reduxProfileInfo.gender.label || reduxProfileInfo.gender.value}</Grid>
                )}
                {reduxProfileInfo.birthDate.value && <Grid item>{reduxProfileInfo.birthDate.value}</Grid>}
                {reduxProfileInfo.dl.value && <Grid item>{reduxProfileInfo.dl.value}</Grid>}
              </Grid>
            </Grid>
          )}
          {profileHasAnyValue(SECONDARY_FIELDS) && (
            <Grid sm={6} xs={12} item>
              <Grid direction="column" spacing={1} container>
                {reduxProfileInfo.employer.value && <Grid item>{reduxProfileInfo.employer.value}</Grid>}
                {reduxProfileInfo.workNumber.value && (
                  <Grid item>
                    {formatPhoneNumber(reduxProfileInfo.workNumber.value)}
                    {reduxProfileInfo.workExt.value ? ' ext. ' + reduxProfileInfo.workExt.value : ''}
                  </Grid>
                )}
                {reduxProfileInfo.healthwaysId.value && (
                  <Grid item>Sneakers ID# {reduxProfileInfo.healthwaysId.value}</Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormBlock>
  )
}

export default PersonalInfoBlock
