import React from 'react'
import cx from 'classnames'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Modal } from 'src/components'
import { getBrandingColors, useMediaDown } from 'src/helpers'
import { CreateMemberAccount } from './CreateMemberAccount'

const useStyle = makeStyles((theme) => ({
  holder: {
    display: 'flex',
    flexDirection: 'row',
    margin: `-20px ${theme.spacing(-3)}px ${theme.spacing(-1)}px`,
    minHeight: 630,
  },
  memberInfoBlock: {
    boxSizing: 'border-box',
    color: '#000',
    textAlign: 'center',

    '&.desktop': {
      width: '42%',
      padding: '115px 48px 45px 57px',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: (props: { primaryColor: string }) => props.primaryColor,

      '&.info-only': {
        flex: 1,
        padding: '115px 20% 45px',
      },
    },
  },
}))

const RegistrationModal = (props: any): JSX.Element => {
  const { gymInfo, profileInfo, verifiedAgreement, closeModal } = props
  const { primaryColor } = getBrandingColors(gymInfo)

  const isMobile = useMediaDown('sm')
  const isClubRegistrationRequired = gymInfo.promptMicoRegistration
  const classes = useStyle({ primaryColor })

  //Limit Member Number to last 5 digits (strip club number)
  const truncatedMemberNumber = verifiedAgreement.memberNumber.slice(-5)

  const registrationData = {
    ...verifiedAgreement,
    memberNumber: truncatedMemberNumber,
    email: profileInfo.email.value,
  }

  const endJoinProcess = () => {
    closeModal()

    window.location.replace(gymInfo.websiteUrl)
  }

  return (
    <Modal handleCancel={endJoinProcess} name="Registration Modal" open>
      <Box className={cx({ [classes.holder]: !isMobile })}>
        <Box
          className={cx(classes.memberInfoBlock, {
            ['desktop']: !isMobile,
            ['info-only']: !isClubRegistrationRequired,
          })}
        >
          <Box pb={[2]} pt={[2, 2, 0]}>
            <Typography align="left" style={{ fontWeight: 500 }} variant={isMobile ? 'h4' : 'h1'}>
              Welcome to <br />
              {gymInfo.name}, {registrationData.firstName}!
            </Typography>
          </Box>
          <Box pb={[1, 1, 0]}>
            <Typography variant="subtitle2">Your agreement number is</Typography>
          </Box>
          <Box pb={[1, 1, 2]}>
            <Typography variant="h3">{`${registrationData.clubNumber}${registrationData.memberNumber}`}</Typography>
          </Box>
          <Typography style={{ wordWrap: 'break-word' }} variant="subtitle2">
            {'You\'ll receive a confirmation email at '}
            <br />
            {registrationData.email}
          </Typography>
        </Box>
        {isClubRegistrationRequired && (
          <CreateMemberAccount registrationData={registrationData} onClose={endJoinProcess} />
        )}
      </Box>
    </Modal>
  )
}

export default RegistrationModal
