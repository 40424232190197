import Box from '@material-ui/core/Box'
import { FormInput, HeadingBox } from 'src/components'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import FormHelperText from '@material-ui/core/FormHelperText'
import React, { ChangeEvent, useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isValidPassword, scrollToElem, useMediaDown } from 'src/helpers'
import { MemberAccount, ValidatedPassword, VerifiedAgreement } from 'src/interfaces'
import { gymService } from 'src/services'

const useStyle = makeStyles({
  root: {
    '& .MuiFormHelperText-root.Mui-error': {
      marginBottom: -8,
    },
  },
  button: {
    position: 'absolute',
    bottom: 48,
    right: 48,
    height: 63,
    lineHeight: '63px',
    paddingTop: 0,
    paddingBottom: 0,
    width: 153,
    maxWidth: 274,
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#fff',

    '&.mobile': {
      position: 'static',
      height: 46,
      lineHeight: '46px',
      width: '100%',
    },
  },
})

export const CreateMemberAccount = ({
  registrationData,
  onClose,
}: {
  registrationData: VerifiedAgreement & { email: string }
  onClose: () => void
}) => {
  const classes = useStyle()
  const isMobile = useMediaDown('sm')
  const errorRef = React.useRef<HTMLDivElement>(null)
  const [submitted, setSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [nextIteration, setNextIteration] = useState(0)

  const formatErrorMessage = (errors: string[]): string => {
    return errors.join('<br/>')
  }

  const [registration, setRegistration] = useState({
    email: '',
    username: '',
    password: '',
    verifiedPassword: '',
    clubNumber: '',
    firstName: '',
    lastName: '',
    zip: '',
    barcode: '',
    memberNumber: '',
    memberId: '',
  })

  useEffect(() => {
    setRegistration({
      ...registration,
      email: registrationData.email,
      clubNumber: registrationData.clubNumber,
      memberNumber: registrationData.memberNumber,
      memberId: registrationData.memberId,
      firstName: registrationData.firstName,
      lastName: registrationData.lastName,
      zip: registrationData.zip,
      barcode: registrationData.barcode,
    })
  }, [])

  useEffect(() => {
    scrollToElem(errorRef.current)
  }, [nextIteration])

  const handleChange = (prop: any) => (event: ChangeEvent<HTMLInputElement>) => {
    setRegistration({ ...registration, [prop]: event.target.value })
  }

  const handleSubmit = async () => {
    setSubmitted(true)
    setNextIteration(nextIteration + 1)
    if (registration.username && registration.email && registration.password && registration.verifiedPassword) {
      const validatedRegistration: ValidatedPassword = isValidPassword(
        registration.password,
        registration.verifiedPassword,
      )

      if (validatedRegistration.valid) {
        const memberAccount: MemberAccount = registration
        //POST to service

        const createResponse = await gymService.createAccount(memberAccount)

        if (createResponse.data.success) {
          const redirectURL = `${process.env.REACT_APP_CLUB_REDIRECT_URL}?clubNumber=${memberAccount.clubNumber}`
          //Redirect to Club Login Page
          window.location.replace(redirectURL)
        }

        if (createResponse.data.errors.length) {
          const responseErrorMessages = formatErrorMessage(createResponse.data.errors)
          setErrorMessage(responseErrorMessages)
        }

        setSubmitted(false)
      } else {
        const newErrorMessage = formatErrorMessage(validatedRegistration.errors)
        setErrorMessage(newErrorMessage)
      }
    }
  }

  return (
    <Box
      className={classes.root}
      flex={1}
      pt={[4, 4, 0]}
      style={{
        position: 'relative',
        padding: isMobile ? '' : '115px 48px 45px 57px',
      }}
    >
      {registrationData.queueMessage && (
        <>
          <HeadingBox title="Almost done!">{registrationData.queueMessage}</HeadingBox>
          <Box pb={[1, 1, 0]} pt={[2, 2, 0]} textAlign="center">
            <Button
              className={`${classes.button} ${isMobile && 'mobile'}`}
              color="secondary"
              variant="contained"
              onClick={onClose}
            >
              Ok
            </Button>
          </Box>
        </>
      )}
      {!registrationData.queueMessage && (
        <>
          <HeadingBox title={'Let\'s get started!'}>Create your username and password</HeadingBox>
          <Box
            boxSizing="border-box"
            mb={isMobile ? 0 : '55px'}
            px={[3, 3, 0]}
            py={[3, 3, 1]}
            style={{
              borderRadius: 6,
              border: isMobile ? '1px solid #ddd' : '',
              background: isMobile ? 'rgba(219, 219, 219, 0.15)' : '',
            }}
            width={isMobile ? '100%' : '86%'}
          >
            <Box pb={2}>
              <FormInput
                error={submitted && !registration.email}
                fieldName="Email"
                nextIteration={nextIteration}
                propName="email"
                value={registration.email}
                required
                onChange={handleChange('email')}
              />
            </Box>
            <Box pb={2}>
              <FormInput
                error={submitted && !registration.username}
                fieldName="Username"
                nextIteration={nextIteration}
                propName="username"
                value={registration.username}
                required
                onChange={handleChange('username')}
              />
            </Box>
            <Box pb={2}>
              <FormInput
                error={submitted && !registration.password}
                fieldName="Create your password"
                nextIteration={nextIteration}
                propName="password"
                type="password"
                value={registration.password}
                required
                onChange={handleChange('password')}
              />
            </Box>
            <Box pb={2}>
              <FormInput
                error={submitted && !registration.verifiedPassword}
                fieldName="Re-enter your password"
                nextIteration={nextIteration}
                propName="verifyPassword"
                type="password"
                value={registration.verifiedPassword}
                required
                onChange={handleChange('verifiedPassword')}
              />
            </Box>
            {errorMessage?.length > 0 && (
              <Box pb={2}>
                <Typography variant="caption">
                  <FormHelperText ref={errorRef} error>
                    <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
                  </FormHelperText>
                </Typography>
              </Box>
            )}
            <Box pb={[1, 1, 0]} pt={[2, 2, 0]} textAlign="center">
              <Button
                className={`${classes.button} ${isMobile && 'mobile'}`}
                color="secondary"
                variant="contained"
                onClick={handleSubmit}
              >
                Register
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}
