import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import {
  CampaignsState,
  CountriesAndStatesState,
  GuestValues,
  GymPlanSelection,
  GymState,
  LocationState,
  PaymentValues,
  ProfileValues,
  UserDefinedField,
  VerifiedAgreement,
} from 'src/interfaces'
import {
  agreementSlice,
  campaignsReducer,
  countriesAndStatesReducer,
  guestProfileFieldsSlice,
  gymSlice,
  loadingSlice,
  paymentSlice,
  planInformationSlice,
  profileInformationSlice,
  profileUDFSlice,
  stepperSlice,
  locationsReducer,
} from 'src/reducers'

interface Reducer {
  agreementSlice: VerifiedAgreement
  campaignsSlice: CampaignsState
  countriesAndStatesSlice: CountriesAndStatesState
  guestProfileFieldsSlice: GuestValues
  gymSlice: GymState
  loadingSlice: string[]
  paymentSlice: PaymentValues
  planInformationSlice: GymPlanSelection
  profileInformationSlice: ProfileValues
  profileUDFSlice: UserDefinedField[]
  stepperSlice: boolean
  locationsSlice: LocationState
}

const reducer = combineReducers<Reducer>({
  agreementSlice: agreementSlice.reducer,
  campaignsSlice: campaignsReducer,
  countriesAndStatesSlice: countriesAndStatesReducer,
  guestProfileFieldsSlice: guestProfileFieldsSlice.reducer,
  gymSlice: gymSlice.reducer,
  loadingSlice: loadingSlice.reducer,
  paymentSlice: paymentSlice.reducer,
  planInformationSlice: planInformationSlice.reducer,
  profileInformationSlice: profileInformationSlice.reducer,
  profileUDFSlice: profileUDFSlice.reducer,
  stepperSlice: stepperSlice.reducer,
  locationsSlice: locationsReducer,
})

const store = configureStore({
  reducer,
})

export default store
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
