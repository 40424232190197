import React from 'react'
import Typography from '@material-ui/core/Typography'

export const FeePaymentsNumberText = ({ amount, className }: { amount: string; className?: string }) => {
  const text = Number(amount) === 1 ? 'payment' : 'payments'
  return (
    <Typography className={className} variant="body2">
      {`${amount} ${text}`}
    </Typography>
  )
}
