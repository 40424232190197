import React from 'react'
import Button from '@material-ui/core/Button'

const EditButton = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <Button
    color="secondary"
    style={{
      fontSize: '18px',
      position: 'absolute',
      letterSpacing: 'normal',
      right: 16,
      top: 18,
    }}
    onClick={onClick}
  >
    Edit
  </Button>
)

export default EditButton
