import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import makeStyles from '@material-ui/core/styles/makeStyles'
import uniqBy from 'lodash/uniqBy'
import { AddonsModal, HeadingBox } from 'src/components'
import { RootState, useAppDispatch } from 'src/configureStore'
import { useMediaDown, filterVisiblePlanLocations, useQuery, getQueryUrlState, scrollToElem } from 'src/helpers'
import { GymPlan, Schedule } from 'src/interfaces'
import { gymSlice, loadingSlice, planInformationSlice, selectGymPlan, stepperSlice } from 'src/reducers'
import PlanComponent from './components/Plan/Plan'
import PromoCode from './components/PromoCode/PromoCode'
import { APP_MODIFIER_PARAMS, PAGES } from 'src/common'
import { gymDetailsSelector, selectedPlanIdSelector, stepperSelector } from 'src/selectors'

const useStyles = makeStyles((theme) => ({
  joinBlockText: {
    fontSize: '14px',
  },
  spacedBox: {
    paddingBottom: theme.spacing(4),
  },
}))

function PlanPage(): JSX.Element {
  const params = useQuery()
  const [showAddons, setShowAddons] = useState(false)
  const reduxPlanId = useSelector(selectedPlanIdSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const [planId, selectPlanId] = useState(reduxPlanId)
  const [nextIteration, setNextIteration] = useState(0)
  const errorRef = React.useRef<any>(null)
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const clubId = reduxGymInfo.number
  const reduxStepper = useSelector(stepperSelector)
  const gymPlans = useSelector((state: RootState) => state.gymSlice.plans)
  const isMobile = useMediaDown('xs')
  const classes = useStyles()

  const navigateNext = () => {
    setShowAddons(false)

    dispatch(stepperSlice.actions.resetNext())

    changeUrlWithQuery({
      path: PAGES.profile,
      key: APP_MODIFIER_PARAMS.selectedPlanId,
    })
  }

  const availablePlans = useMemo(() => {
    const uniquePlans = uniqBy(gymPlans, (plan: GymPlan) => plan.planId)
    return filterVisiblePlanLocations(uniquePlans, params)
  }, [gymPlans, params])

  const changeUrlWithQuery = useCallback(({ path, key, value }) => {
    const newState = getQueryUrlState({ path, key, value })
    history.push(newState)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    if (params.get(APP_MODIFIER_PARAMS.fromClub) !== null) {
      history.push(PAGES.profile + location.search)
    }
  }, [])

  useEffect(() => {
    if (!reduxStepper) return

    dispatch(stepperSlice.actions.resetNext())
    setNextIteration(nextIteration + 1)

    if (!planId) return

    dispatch(loadingSlice.actions.startLoading('planPageNextAction'))

    dispatch(selectGymPlan({ planId, clubNumber: reduxGymInfo.number })).then(({ payload }) => {
      dispatch(loadingSlice.actions.endLoading('planPageNextAction'))

      const addons = payload.schedules.filter((s: Schedule) => s.addon && !s.defaultChecked)
      if (addons.length) {
        setShowAddons(true)
        return
      }

      navigateNext()
    })
  }, [reduxStepper])

  useEffect(() => {
    scrollToElem(errorRef.current)
  }, [nextIteration])

  useEffect(() => {
    if (!clubId) return

    const planIdToSelect = params.get(APP_MODIFIER_PARAMS.selectedPlanId) || planId
    const planExists = availablePlans.find(({ planId }) => planId === planIdToSelect)

    if (planIdToSelect || planExists) {
      selectPlanId(planExists ? planIdToSelect : '')
    }
  }, [reduxGymInfo])

  useEffect(() => {
    if (!clubId) return

    changeUrlWithQuery({
      key: APP_MODIFIER_PARAMS.selectedPlanId,
      value: planId,
    })
  }, [planId])

  const clearPlanSelection = () => {
    selectPlanId('')
    dispatch(planInformationSlice.actions.clearPlanSelection())
  }

  const submitPromoCodePlan = (plan: GymPlan, promoCode: string) => {
    selectPlanId(plan.planId)

    dispatch(gymSlice.actions.addPlan(plan))

    if (promoCode) {
      changeUrlWithQuery({
        key: APP_MODIFIER_PARAMS.promoCode,
        value: promoCode,
      })
    }
  }

  const selectedPlan = useMemo(() => {
    return planId ? availablePlans.filter((plan: GymPlan) => plan.planId === planId)[0] : undefined
  }, [availablePlans, planId])

  return (
    <>
      <Container maxWidth="xl">
        <Box
          alignItems="center"
          className={classes.spacedBox}
          display={isMobile ? 'block' : 'flex'}
          justifyContent="space-between"
        >
          <Box mb={-2}>
            <HeadingBox title="Choose your plan" />
          </Box>
          <Box ml={isMobile ? 0 : 1} pt={isMobile ? 4 : 0}>
            <PromoCode handleCancel={clearPlanSelection} handleSubmit={submitPromoCodePlan} />
          </Box>
        </Box>
      </Container>
      <Container maxWidth="xl">
        {selectedPlan ? (
          <Grid spacing={3} container>
            <PlanComponent
              key={selectedPlan.planId}
              clearSelectedAction={clearPlanSelection}
              plan={selectedPlan}
              selected
            />
          </Grid>
        ) : (
          Boolean(availablePlans.length) && (
            <Box pb={9} pt={1}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup name="plan" onChange={(e) => selectPlanId(e.target.value)}>
                  <Grid spacing={isMobile ? 2 : 4} style={{ justifyContent: 'center' }} container>
                    {availablePlans.map((plan: GymPlan) => (
                      <PlanComponent key={plan.planId} plan={plan} selected={planId === plan.planId} />
                    ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
              {nextIteration > 0 && !planId && (
                <FormHelperText ref={errorRef} error>
                  Selecting a plan is required
                </FormHelperText>
              )}
            </Box>
          )
        )}
      </Container>
      {showAddons && <AddonsModal planId={planId} onClose={navigateNext} />}
    </>
  )
}

export default PlanPage
