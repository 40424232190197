import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

interface IFormBlockHeadingProps {
  title: string
  children?: React.ReactNode
}

const FormBlockHeading = (props: IFormBlockHeadingProps) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="space-between" pb={2}>
      <Typography style={{ fontWeight: 500, fontStyle: 'italic' }} variant="subtitle2">
        {props.title}
      </Typography>
      {props.children}
    </Box>
  )
}

export default FormBlockHeading
