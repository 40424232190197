import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { useTheme } from '@material-ui/core/styles'
import { getCardColorSchema, ICardColorSchema, useMediaUp } from 'src/helpers'
import cx from 'classnames'
import { Schedule } from '../interfaces'

const useStyles = makeStyles({
  root: (props: { colors: ICardColorSchema }) => ({
    borderStyle: 'solid',
    border: `1px solid ${props.colors.activeColor}`,
    boxSizing: 'border-box',
    width: '100%',
    height: 353,
    backgroundColor: `${props.colors.activeColor}27`,

    '& .MuiCardContent-root': {
      display: 'grid',
      height: '100%',
      textAlign: 'center',
      gridTemplateRows: 'auto 1fr auto auto',
      boxSizing: 'border-box',
      overflow: 'hidden',
      padding: '12px 16px 16px',
    },

    '&.selected': {
      borderColor: props.colors.activeColor,
      padding: 0,

      '& .MuiCardContent-root': {
        boxShadow: `inset 0 0 0 4px ${props.colors.activeColor}`,
      },
    },

    '&.descriptionActive': {
      '& .MuiCardContent-root': {
        gridTemplateRows: '1fr auto',
      },
    },
  }),
  addonNameArea: (props: { colors: ICardColorSchema }) => ({
    backgroundColor: props.colors.activeHeaderColor,
    color: props.colors.activeTextColor,
    transition: 'background-color 250ms ease-in',
    margin: '-12px -16px 0',
    padding: '12px 16px',

    '& .addonName': {
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: 26,
      wordBreak: 'break-word',
    },
  }),
  addonTextArea: {
    position: 'absolute',
    maxHeight: '100%',
    overflow: 'auto',
  },
  addonText: {
    fontSize: 22,
    lineHeight: 1.2,
  },
  button: {
    boxShadow: 'none',
  },
  link: {
    fontSize: 18,
  },
})

const FREQUENCY = {
  weekly: 'Weekly',
  biWeekly: 'Bi-Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  semiAnnually: 'Semi-Annually',
  annually: 'Annually',
}

const frequencyTexts = {
  [FREQUENCY.weekly]: { frequency: 'per week', period: 'week(s)' },
  [FREQUENCY.biWeekly]: { frequency: 'bi-weekly', period: 'payment(s)' },
  [FREQUENCY.monthly]: { frequency: 'per month', period: 'month(s)' },
  [FREQUENCY.quarterly]: { frequency: 'quarterly', period: 'quarter(s)' },
  [FREQUENCY.semiAnnually]: { frequency: 'semi-annually', period: 'payment(s)' },
  [FREQUENCY.annually]: { frequency: 'per year', period: 'year(s)' },
}

const Addon = ({
  addon,
  scheduleFrequency,
  onChange,
}: {
  addon: Schedule
  scheduleFrequency: string
  onChange: (selected: boolean, addon: Schedule) => void
}): JSX.Element => {
  const theme = useTheme()
  const [selected, setSelected] = useState(false)
  const [showDescription, setShowDescription] = useState(false)
  const [colors, setColors] = useState(getCardColorSchema(theme, selected))
  const isDesktop = useMediaUp('md')
  const classes = useStyles({ colors })
  const { frequency, period } = frequencyTexts[scheduleFrequency]

  useEffect(() => {
    setColors(getCardColorSchema(theme, selected))
    onChange(selected, addon)
  }, [selected])

  const handleSelect = () => setSelected((prev) => !prev)
  const toggleDescription = () => setShowDescription((prev) => !prev)

  return (
    <Card className={cx(classes.root, { selected, descriptionActive: showDescription })}>
      <CardContent>
        {showDescription && (
          <>
            <Box alignItems="start" display="flex" flex={1} style={{ overflow: 'auto' }}>
              <Typography align="center" variant="body1">
                {addon.description}
              </Typography>
            </Box>
            <Box alignSelf="center" pt={2}>
              <Link
                className={classes.link}
                color="secondary"
                component="button"
                underline="always"
                onClick={toggleDescription}
              >
                Back
              </Link>
            </Box>
          </>
        )}
        {!showDescription && (
          <>
            <Box className={classes.addonNameArea}>
              <Typography align="center" className="addonName" variant={isDesktop ? 'h6' : 'subtitle1'}>
                {addon.profitCenter}
              </Typography>
            </Box>
            <Box display="flex" style={{ position: 'relative', alignItems: 'center' }}>
              <Box className={classes.addonTextArea}>
                <Typography align="center" className={classes.addonText} variant="h6">
                  {addon.recurring
                    ? `Recurring ${addon.scheduleAmount} ${frequency}`
                    : `Limited ${addon.scheduleAmount} ${frequency} for ${addon.numberOfPayments} ${period}`}
                </Typography>
              </Box>
            </Box>
            {addon.description && (
              <Box alignSelf="center" pt={2}>
                <Link
                  className={classes.link}
                  color="secondary"
                  component="button"
                  underline="always"
                  onClick={toggleDescription}
                >
                  {showDescription ? 'Back' : 'See Description'}
                </Link>
              </Box>
            )}
            <Box alignSelf="center" pt={2}>
              <Fab className={classes.button} color="secondary" size="medium" onClick={handleSelect}>
                {selected ? <RemoveIcon /> : <AddIcon />}
              </Fab>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default Addon
