import React, { ChangeEvent, useCallback, useState } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Modal from 'src/components/Modal'
import { gymService } from 'src/services'
import PlanComponent from '../Plan/Plan'
import { filterPlansMatchingPromo, filterVisiblePlanLocations, useQuery } from 'src/helpers'
import { GymPlan } from 'src/interfaces'
import './PromoCode.scss'
import { useSelector } from 'react-redux'
import { clubNumberSelector } from 'src/selectors'
import makeStyles from '@material-ui/core/styles/makeStyles'

interface IPromoCodeProps {
  handleCancel: () => void
  handleSubmit: (plan: GymPlan, promoCode: string) => void
}

const useStyles = makeStyles({
  applyButtonLabel: {
    fontSize: '16px',
  },
})

function PromoCode({ handleCancel, handleSubmit }: IPromoCodeProps): JSX.Element {
  const [error] = useState(false)
  const [loading, setLoading] = useState(false)
  const [plans, setPlans] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [promoCode, setPromoCode] = useState('')
  const [selectedPromoPlanId, setSelectedPromoPlanId] = useState('')
  const params = useQuery()
  const clubNumber = useSelector(clubNumberSelector)
  const classes = useStyles()

  const handlePromoCodeChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value)
  }, [])

  const promoCodeMatches = useCallback(
    (plans: GymPlan[]) => {
      return !!filterPlansMatchingPromo(plans, promoCode).length
    },
    [promoCode],
  )

  const handlePromoCodeSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    if (loading) return
    e.preventDefault()
    setLoading(true)

    gymService.getGymPlans(clubNumber, true).then((plans: any) => {
      setLoading(false)
      setPlans(plans)

      if (promoCodeMatches(plans)) {
        setShowModal(true)
      }
    })
  }

  const handlePromoPlanCancel = () => {
    setShowModal(false)
    handleCancel()
  }

  const handlePromoPlanChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedPromoPlanId(e.target.value)
  }

  const handlePromoPlanSubmit = () => {
    const plan = plans.find((p: GymPlan) => p.planId === selectedPromoPlanId)

    if (!plan) return

    handleSubmit(plan, promoCode)
    setShowModal(false)
  }

  const renderPromoCodePlans = () => {
    const plansMatchesPromoCode = filterPlansMatchingPromo(plans, promoCode)

    return filterVisiblePlanLocations(plansMatchesPromoCode, params).map((p: GymPlan) => {
      return <PlanComponent key={p.planId} idPrefix="promo-" plan={p} selected={selectedPromoPlanId === p.planId} />
    })
  }

  return (
    <>
      <Modal
        cancelText="Cancel"
        confirmText="Next"
        handleCancel={handlePromoPlanCancel}
        handleConfirm={handlePromoPlanSubmit}
        name="Promo Code"
        open={showModal}
        title={`Promo Code: ${promoCode}`}
      >
        <Container>
          <RadioGroup name="plan" onChange={handlePromoPlanChange}>
            <Grid direction="row" spacing={4} container>
              {renderPromoCodePlans()}
            </Grid>
          </RadioGroup>
        </Container>
      </Modal>
      <form onSubmit={handlePromoCodeSubmit}>
        <FormControl className="form-group" error={error}>
          <InputLabel className="promo-code__label" htmlFor="promo-code">
            Enter Promo Code
          </InputLabel>
          <FilledInput
            className="promo-code__filled-input"
            endAdornment={
              <InputAdornment className="promo-code__input-adornment" position="end">
                <Button
                  aria-label="submit promo code"
                  className="promo-code__button"
                  classes={{ label: classes.applyButtonLabel }}
                  color="secondary"
                  disabled={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                  disableRipple
                >
                  {loading ? <CircularProgress color="primary" /> : 'Apply'}
                </Button>
              </InputAdornment>
            }
            id="promo-code"
            onChange={handlePromoCodeChange}
          />
        </FormControl>
      </form>
    </>
  )
}

export default PromoCode
