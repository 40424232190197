import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { formatPhoneNumber, saveDataToGymSales } from 'src/helpers'
import { FormBlock, HeadingBox } from 'src/components'
import { RootState } from 'src/configureStore'
import { clubIdSelector, gymDetailsSelector } from 'src/selectors'
import { gymSalesSteps } from 'src/interfaces'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'
import { PAGES } from '../common'

const GuestReview = (): JSX.Element => {
  const clubId = useSelector(clubIdSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const guestValues = useSelector((state: RootState) => state.guestProfileFieldsSlice)
  const theme = useTheme()

  useEffect(() => {
    if (!guestValues?.firstName) {
      window.location.href = window.location.href.replace(PAGES.guestReview, PAGES.guest)
      return
    }

    iframeDataExchange.emit({ pageChange: 'Guest Pass' })
  }, [])

  useEffect(() => {
    if (!clubId) return

    saveDataToGymSales({
      step: gymSalesSteps.guest,
      abc_club_number: clubId,
      first_name: guestValues.firstName,
      last_name: guestValues.lastName,
      email: guestValues.email,
      phone_mobile: guestValues.homePhone,
      email_opted_out: !guestValues.receivePromotionOffers,
      sms_opted_out: !guestValues.receivePromotionOffers,
      address: guestValues.streetAddress,
      city: guestValues.city,
      state: guestValues.state,
      zip: guestValues.zip,
      source_name: guestValues.campaign?.label || document.referrer,
    })
  }, [clubId, guestValues])

  return (
    <Container maxWidth="xl">
      <HeadingBox title="Thank you for submitting your information." />
      <Box mb={3}>
        <Typography style={{ marginBottom: theme.spacing(1) }} variant="body1">
          We look forward to seeing you soon. Please contact us for any additional information, and have a nice day!
        </Typography>
      </Box>
      <FormBlock>
        <Grid item>
          <Typography style={{ marginBottom: theme.spacing(1) }} variant="subtitle2">
            {`${guestValues.firstName} ${guestValues.lastName}`}
          </Typography>
          <Typography style={{ marginBottom: theme.spacing(0.5) }} variant="body1">
            {guestValues.email}
          </Typography>
          <Typography style={{ marginBottom: theme.spacing(0.5) }} variant="body1" paragraph>
            {formatPhoneNumber(guestValues.homePhone)}
          </Typography>
          <Box alignItems="center" display="flex" pt={0.5}>
            <Box component="span" display="inline-flex" pr={0.5}>
              <LocationOnIcon />
            </Box>
            <Typography variant="body1">
              {reduxGymInfo.name} - {reduxGymInfo.address1}
            </Typography>
          </Box>
        </Grid>
      </FormBlock>
    </Container>
  )
}

export default GuestReview
