import React from 'react'

import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import { useCheckboxStyles } from './styles'

type CheckboxWithLabelProps = {
  controlLabel: Omit<FormControlLabelProps, 'control'>
  checkbox: CheckboxProps
  required?: boolean
}

const CheckboxWithLabel = ({ controlLabel, checkbox, required = false }: CheckboxWithLabelProps) => {
  const styles = useCheckboxStyles()

  return (
    <FormControlLabel
      {...controlLabel}
      control={
        <Checkbox
          {...checkbox}
          checkedIcon={<CheckBoxIcon className={styles.checkedCheckboxIcon} viewBox="3 3 18 18" />}
          color="secondary"
          icon={<div className={styles.uncheckedCheckboxIcon} />}
        />
      }
      label={
        required ? (
          <>
            <span className="required"> * </span>
            {controlLabel.label}
          </>
        ) : (
          controlLabel.label
        )
      }
    />
  )
}

export default CheckboxWithLabel
