import { ComponentType, SVGProps } from 'react'

import { PAYMENT_CARD_BRAND, paymentCardDetails } from 'src/common'

import { AmexIcon, DiscoverIcon, MastercardIcon, VisaIcon } from '../Icons'

export const allowedCardBrands = [
  PAYMENT_CARD_BRAND.VISA,
  PAYMENT_CARD_BRAND.DISCOVER,
  PAYMENT_CARD_BRAND.MASTERCARD,
  PAYMENT_CARD_BRAND.AMEX,
]

export const iconMapping: { [k in PAYMENT_CARD_BRAND]?: ComponentType<SVGProps<SVGSVGElement>> } = {
  [PAYMENT_CARD_BRAND.AMEX]: AmexIcon,
  [PAYMENT_CARD_BRAND.DISCOVER]: DiscoverIcon,
  [PAYMENT_CARD_BRAND.VISA]: VisaIcon,
  [PAYMENT_CARD_BRAND.MASTERCARD]: MastercardIcon,
}

export const isPaymentCardBrand = (cardNumber: string, brand: PAYMENT_CARD_BRAND): boolean =>
  paymentCardDetails[brand].ranges.some(([min, max]) => {
    const test = parseInt(cardNumber.slice(0, min.toString().length), 10)

    return test >= min && test <= max
  })

export const getPaymentCardBrand = (cardNumber: string) =>
  allowedCardBrands.find((brand) => isPaymentCardBrand(cardNumber, brand))
