import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import 'date-fns'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { CheckboxWithLabel, FormBlock, FormBlockHeading, HeadingBox, LoadingBox } from 'src/components'
import { RootState, useAppDispatch } from 'src/configureStore'
import {
  getCountryLabel,
  getCountryStateOptions,
  getCountryStates,
  getPromotionOptions,
  getSalesPeople,
  saveMemberDataToGymSales,
  scrollToElem,
  transformMiddlename,
  useQuery,
} from 'src/helpers'
import { FieldOptionState, gymSalesSteps, ProfileFields, SelectOption } from 'src/interfaces'
import { loadingSlice, profileInformationSlice, profileUDFSlice, selectGymPlan, stepperSlice } from 'src/reducers'
import { clubIdSelector, gymDetailsSelector, planStateSelector, stepperSelector } from 'src/selectors'
import { APP_MODIFIER_PARAMS, MemberField, PAGES, STEPS } from 'src/common'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import makeStyles from '@material-ui/core/styles/makeStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import {
  BirthdateField,
  SecondaryMembers,
  ProfileField,
  ElectronicAgreementAcknowledgement,
  UdfFields,
} from './components'
import { useProfilePage } from './useProfilePage'

const useStyles = makeStyles({
  minorInfoBox: {
    background: '#f8eecc',
    border: '1px solid #f4e4ae',
    borderRadius: '4px',
    padding: '4px 8px',
    margin: '0 0 16px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '8px',
  },
})

function ProfilePage(): JSX.Element {
  const [minors, setMinors] = useState<string[]>([])
  const [nextIteration, setNextIteration] = useState(0)
  const isSubmitted = nextIteration > 0
  const [salesRepOptions, setSalesRepOptions] = useState<SelectOption[]>([])
  const history = useHistory()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const clubId = useSelector(clubIdSelector)
  const reduxStepper = useSelector(stepperSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const params = useQuery()
  const minorInfoRef = useRef<HTMLDivElement>(null)
  const agreementErrorRef = useRef<HTMLDivElement>(null)
  const classes = useStyles()
  const {
    UDFs,
    secondaryMembers,
    handleChange,
    isFieldsValid,
    isValid,
    profileFields,
    setSecondaryMembers,
    setProfileFields,
    setUDFs,
  } = useProfilePage()
  const agreementActive = reduxGymInfo.showElectronicAgreementAcknowledgement
  const [agreementAccepted, setAgreementAccepted] = useState(false)
  const isAgreementValid = agreementAccepted || !agreementActive
  const { stateLabel, zipCodeLabel } = getCountryLabel(profileFields.country.value)

  const countriesStates = useSelector((state: RootState) => state.countriesAndStatesSlice.countriesStates)
  const countriesStatesOptions = getCountryStateOptions(countriesStates)
  const stateOptions = getCountryStates(profileFields.country.value, countriesStatesOptions)
  const countryOptions = countriesStatesOptions?.localCountryOptions
  const campaigns = useSelector((state: RootState) => state.campaignsSlice.campaigns)
  const promotionOptions = getPromotionOptions(campaigns)
  const planId = params.get(APP_MODIFIER_PARAMS.selectedPlanId)
  const fromClub = params.get(APP_MODIFIER_PARAMS.fromClub) !== null
  const [pageDataReady, setPageDataReady] = useState(!fromClub)
  const minorAge = reduxGymInfo.minorAllowSignUp ? undefined : reduxGymInfo.minorAge

  useEffect(() => {
    window.scrollTo(0, 0)
    iframeDataExchange.emit({ pageChange: STEPS.profile.displayText })
  }, [])

  useEffect(() => {
    if (minors.length) {
      setTimeout(() => scrollToElem(minorInfoRef.current), 50)
    }
  }, [minors.length])

  useEffect(() => {
    const promotionField = profileFields.promotion
    const salesPersonIdField = profileFields.salesPersonId

    if (promotionField.label && !promotionField.value) {
      const valueByLabel = promotionOptions.find((option) => option.label === promotionField.label)?.value
      promotionField.value = valueByLabel || ''
    }

    if (!promotionField.label && promotionField.value) {
      const labelByValue = promotionOptions.find((option) => option.value === promotionField.value)?.label
      promotionField.label = labelByValue || ''
    }

    if (!salesPersonIdField.label && salesPersonIdField.value) {
      const labelByValue = salesRepOptions.find((option) => option.value === salesPersonIdField.value)?.label
      salesPersonIdField.label = labelByValue || ''
    }

    setProfileFields(profileFields)
  }, [profileFields])

  useEffect(() => {
    if (reduxPlanInfo.planId) return

    if (!fromClub) {
      history.push(PAGES.plan + location.search)
      return
    }

    if (planId && reduxGymInfo.number) {
      dispatch(loadingSlice.actions.startLoading('getPlanFromProfile'))

      dispatch(selectGymPlan({ planId, clubNumber: reduxGymInfo.number })).then(() => {
        setPageDataReady(true)
        dispatch(loadingSlice.actions.endLoading('getPlanFromProfile'))
        dispatch(stepperSlice.actions.resetNext())
      })
    }
  }, [fromClub, planId, reduxGymInfo])

  useEffect(() => {
    const userDataReady = ['firstName', 'lastName', 'email', 'cellNumber'].every((fieldName) => {
      return !!profileFields[fieldName as keyof ProfileFields]?.value.trim()
    })

    if (!userDataReady) return

    saveMemberDataToGymSales({
      step: gymSalesSteps.step1,
      clubId: clubId || reduxGymInfo.number,
      ...profileFields,
      secondaryMembers,
    })
  }, [reduxGymInfo])

  useEffect(() => {
    if (profileFields.salesPersonId.state === FieldOptionState.hide) return

    Promise.resolve(getSalesPeople(reduxGymInfo.number)).then(setSalesRepOptions)
  }, [])

  useEffect(() => {
    if (!reduxStepper) return

    setNextIteration(nextIteration + 1)

    let valid = !minors.length && isFieldsValid()

    if (valid && !isAgreementValid) {
      valid = false
      scrollToElem(agreementErrorRef.current)
    }

    if (valid) {
      dispatch(profileInformationSlice.actions.setProfileInfo({ ...profileFields, secondaryMembers }))
      dispatch(profileUDFSlice.actions.setUDFs(UDFs))
      history.push(PAGES.payment + location.search)
    }
    dispatch(stepperSlice.actions.resetNext())
  }, [reduxStepper])

  const isAnyVisible = (...fieldNames: Array<keyof ProfileFields>) =>
    fieldNames.some((fieldName) => profileFields[fieldName].state !== FieldOptionState.hide)

  const formField = ({ propName, fieldName, sm = 6, maxLength, options, transformValue }: MemberField) => (
    <ProfileField
      fieldName={fieldName}
      maxLength={maxLength}
      nextIteration={nextIteration}
      options={options}
      propName={propName}
      sm={sm}
      state={profileFields[propName].state}
      transformValue={transformValue}
      validate={() => isValid(propName)}
      value={profileFields[propName].value}
      onChange={handleChange(propName)}
    />
  )

  return (
    <Container maxWidth="xl">
      <LoadingBox isLoading={!pageDataReady}>
        <HeadingBox title="Tell us about yourself!">Please complete the following information.</HeadingBox>
        <FormBlock>
          <Grid item>
            <FormBlockHeading title="Contact Information" />
            {!!minors.length && reduxGymInfo.minorDisclaimer.length && (
              <div ref={minorInfoRef} className={classes.minorInfoBox}>
                <InfoOutlinedIcon fontSize="small" style={{ color: '#655212' }} />
                <Typography variant="body2">{reduxGymInfo.minorDisclaimer}</Typography>
              </div>
            )}
            <Grid direction="row" spacing={3} container>
              {formField({
                propName: 'firstName',
                fieldName: 'First name',
                sm: profileFields.middleInitial.state === FieldOptionState.hide ? 6 : 4,
              })}
              {formField({
                propName: 'middleInitial',
                fieldName: 'Middle initial',
                maxLength: 1,
                sm: 2,
                transformValue: transformMiddlename,
              })}
              {formField({ propName: 'lastName', fieldName: 'Last name', maxLength: 19 })}
              {formField({ propName: 'email', fieldName: 'Email', maxLength: 50 })}
              {formField({
                propName: 'cellNumber',
                fieldName: 'Mobile phone',
                sm: profileFields.homeNumber.state === FieldOptionState.hide ? 6 : 3,
              })}
              {formField({ propName: 'homeNumber', fieldName: 'Secondary phone', sm: 3 })}
              {formField({ propName: 'employer', fieldName: 'Employer', maxLength: 50 })}
              {formField({
                propName: 'workNumber',
                fieldName: 'Work phone',
                sm: profileFields.workExt.state === FieldOptionState.hide ? 6 : 3,
              })}
              {formField({ propName: 'workExt', fieldName: 'Work EXT', maxLength: 4, sm: 3 })}
              {formField({ propName: 'street', fieldName: 'Mailing address', maxLength: 44 })}
              {formField({ propName: 'country', fieldName: 'Country', options: countryOptions })}
              {formField({ propName: 'city', fieldName: 'City', maxLength: 23 })}
              {formField({ propName: 'state', fieldName: stateLabel, maxLength: 4, sm: 3, options: stateOptions })}
              {formField({ propName: 'zip', fieldName: zipCodeLabel, maxLength: 10, sm: 3 })}

              <BirthdateField
                minorAge={minorAge}
                nextIteration={nextIteration}
                setMinors={setMinors}
                state={profileFields.birthDate.state}
                value={profileFields.birthDate.value}
                onChange={handleChange('birthDate')}
              />

              {formField({
                propName: 'gender',
                fieldName: 'Gender',
                options: [
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                ],
              })}
              {formField({ propName: 'dl', fieldName: 'Driver\'s license', maxLength: 20 })}
              {formField({ propName: 'healthwaysId', fieldName: 'Sneakers ID', maxLength: 50 })}
            </Grid>

            <Grid direction="row" spacing={3} container>
              <Box pt={2}>
                <CheckboxWithLabel
                  checkbox={{
                    style: { marginTop: '-8px', marginLeft: '12px' },
                    checked: profileFields.marketingSMS?.value,
                    inputProps: { 'aria-label': 'Receive Promotions Checkbox' },
                    onChange: handleChange('marketingSMS'),
                    value: 'marketingSMS',
                  }}
                  controlLabel={{
                    style: { alignItems: 'flex-start', textAlign: 'justify' },
                    label: `By checking the applicable box below, you agree that ${reduxGymInfo.name}, 
                    may deliver or cause to be delivered to you at the telephone number provided by you above telephone
                     calls, telemarketing calls, SMS messages (including text messages), voicemail messages and similar
                      communications using automated systems and technology (including automatic telephone dialing
                       systems), and/or artificial or prerecorded voice messages. Such communications would be about
                        your account, as well as offers from ${reduxGymInfo.name}. You further acknowledge that you are
                         not required to provide this consent, directly or indirectly, as a condition of purchasing any
                          goods or services and that all contact information provided by you above is accurate. 
                          If you opt-in now, you will be able to opt-out later. Message and data rates may apply. Message 
                          frequency varies. For SMS: text HELP for help and text STOP to cancel messages.`
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </FormBlock>
        {(Boolean(UDFs.length) ||
          isAnyVisible(
            'emergencyContactFirstName',
            'emergencyContactLastName',
            'emergencyContactNumber',
            'emergencyContactExtension',
            'salesPersonId',
            'promotion',
          )) && (
          <FormBlock>
            {isAnyVisible(
              'emergencyContactFirstName',
              'emergencyContactLastName',
              'emergencyContactNumber',
              'emergencyContactExtension',
            ) && (
              <Grid item>
                <FormBlockHeading title="Emergency Contact Information" />
                <Grid direction="row" spacing={3} container>
                  {formField({ propName: 'emergencyContactFirstName', fieldName: 'First name' })}
                  {formField({ propName: 'emergencyContactLastName', fieldName: 'Last name', maxLength: 19 })}
                  {formField({ propName: 'emergencyContactNumber', fieldName: 'Contact phone' })}
                  {formField({ propName: 'emergencyContactExtension', fieldName: 'Contact EXT', maxLength: 4 })}
                </Grid>
                <br />
              </Grid>
            )}
            {isAnyVisible('salesPersonId', 'promotion') && (
              <Grid item>
                <FormBlockHeading title="Referral Information" />
                <Grid direction="row" spacing={3} container>
                  {formField({ propName: 'salesPersonId', fieldName: 'Sales person', options: salesRepOptions })}
                  {formField({
                    propName: 'promotion',
                    fieldName: 'How did you hear about us?',
                    options: promotionOptions,
                  })}
                </Grid>
                <br />
              </Grid>
            )}
            <UdfFields UDFs={UDFs} nextIteration={nextIteration} setUDFs={setUDFs} />
          </FormBlock>
        )}
        {reduxPlanInfo.additionalMembersAllowed > 0 && (
          <SecondaryMembers
            members={secondaryMembers}
            minorAge={minorAge}
            nextIteration={nextIteration}
            setMembers={setSecondaryMembers}
            setMinors={setMinors}
          />
        )}
        {agreementActive && (
          <Box paddingBottom={3}>
            <ElectronicAgreementAcknowledgement checked={agreementAccepted} setChecked={setAgreementAccepted} />
          </Box>
        )}
        {isSubmitted && !isAgreementValid && (
          <FormHelperText ref={agreementErrorRef} error>
            Accepting the Agreement Acknowledge is required
          </FormHelperText>
        )}
        <Typography color="error">* Required fields</Typography>
      </LoadingBox>
    </Container>
  )
}

export default ProfilePage
