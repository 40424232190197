import { FieldOptionState, MemberInfo, Schedule, UserDefinedField, Country, Location } from './api/entities'
import { GymDetails, Campaign, FieldOptionValue, GymPlan, ProfileValues } from './api/gym'

export * from './api/entities'
export * from './api/gym'

export type LoadingState = string[]

export interface GymState {
  loading: boolean
  loaded: boolean
  clubId?: string
  plans: GymPlan[]
  gym: GymDetails
  location: LocationState
}

export interface Address {
  streetAddress: string
  country: string
  zipCode: string
  state: string
  city: string
}

export interface PaymentField {
  id: string
  label: string
  value?: string
  maxLength?: number
  text?: string
}

export interface PaymentInfoOption {
  paymentOption: string
  dueTodaySameAsRecurring: boolean
  clubIPad: string
}

export interface EmergencyContact {
  id: string
  label: string
  abcCode: string
  hiddenShownRequired: FieldOptionState
  columns: number
  value: string
  maxLength: number
}

export interface MemberAgreement {
  activePresale: boolean
  agreementSignature: string
  club: GymDetails
  clubNumber: string
  dueTodayPaymentInfoData: PaymentField[]
  dueTodayPaymentInfoOptions: PaymentInfoOption
  emergencyContact: EmergencyContact[]
  macAddress: string
  memberInfo: MemberInfo[]
  messagingConsent: boolean
  planId: string
  planValidation: string
  recurringPaymentCreditCardInfo: PaymentField[]
  recurringPaymentEFTInfo: PaymentField[]
  recurringPaymentNameInfo: PaymentField[]
  schedules: Schedule[]
  secondaryMembers: ProfileValues['secondaryMembers']
  totalDownPayment: string
  udfs: UserDefinedField[]
}

export interface VerifyAgreement {
  memberNumber: string
  clubNumber: string
  firstName: string
  lastName: string
  zip: string
}

export interface VerifiedAgreement {
  memberNumber: string
  clubNumber: string
  firstName: string
  lastName: string
  zip: string
  barcode: string
  memberId: string
  promptForBarcode: boolean
  valid: boolean
  queueMessage: string
}

export interface MemberAccount {
  memberNumber: string
  clubNumber: string
  firstName: string
  lastName: string
  zip: string
  barcode: string
  memberId: string
  email: string
  username: string
  password: string
}

export enum gymSalesGender {
  male = 'M',
  female = 'F',
}

export enum gymSalesSteps {
  guest = 'guest_pass',
  step1 = 'Step 1',
  step2 = 'Step 2',
  join = 'join',
}

export interface ValidatedPassword {
  password: string
  verifiedPassword: string
  errors: string[]
  valid: boolean
}

export interface AddonsSlice {
  checked: boolean
  addons: Schedule[]
}

export interface SelectGymPlan {
  planId: string
  clubNumber: string
}

export interface Addons {
  planId: string
  clubNumber: string
  addons: Schedule[]
}

export interface CountriesAndStatesState {
  loading: boolean
  loaded: boolean
  error?: string
  countriesStates: Country[]
}

export interface CampaignsState {
  loading: boolean
  loaded: boolean
  error?: string
  campaigns: Campaign[]
}

export interface IUserDataForGymSales {
  step: gymSalesSteps
  abc_club_number: string
  first_name: string
  last_name: string
  email: string
  phone_mobile: string
  email_opted_out: boolean
  sms_opted_out: boolean
  address: string
  city: string
  state: string
  zip: string
  source_name: string
  phone_work?: string
  phone_home?: string
  gender?: gymSalesGender
  birthday?: string
  address2?: string
}

type TGSFieldOption = Omit<FieldOptionValue, 'state'>

export interface IUserDataFromGymSales {
  firstName: TGSFieldOption
  lastName: TGSFieldOption
  email: TGSFieldOption
  cellNumber: TGSFieldOption
  marketingSMS: TGSFieldOption
  marketingEmails: TGSFieldOption
  street: TGSFieldOption
  city: TGSFieldOption
  state: TGSFieldOption
  zip: TGSFieldOption
  birthDate: TGSFieldOption
  gender: TGSFieldOption
  promotion: TGSFieldOption
}

export interface LocationState {
  loading: boolean
  loaded: boolean
  locations: Location[]
  clubId?: string
  error?: string
}
