import React, { useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import { SelectOption } from 'src/interfaces'
import { scrollToElem } from 'src/helpers'
import DateInput from './DateInput'
import PhoneInputMask from './PhoneInputMask'
import './FormInput.scss'

const useStyles = makeStyles((theme) => ({
  select: {
    '& svg.MuiSvgIcon-root': {
      fontSize: '2.75rem',
      color: theme.palette.secondary.main,
      position: 'absolute',
      top: '0.5rem',
    },
  },
}))

function FormInput(props: FormInputType) {
  const {
    propName,
    fieldName,
    value,
    maxLength,
    onChange,
    error = false,
    required = false,
    type = 'text',
    options = [],
    nextIteration = 0,
  } = props
  const errorRef = React.useRef<any>(null)
  const classes = useStyles()

  useEffect(() => {
    if (error) {
      scrollToElem(errorRef.current)
    }
  }, [nextIteration, error])

  let InputObject

  if (type === 'date' || type === 'month') {
    return <DateInput {...props} type={type!} />
  } else if (type === 'select') {
    InputObject = (
      <Select
        IconComponent={ExpandMoreIcon}
        className={classes.select}
        color="secondary"
        error={error}
        name={propName}
        type="text"
        value={value}
        onChange={(e, c: any) => {
          onChange(e, c ? c.props.children : null)
        }}
      >
        {options.map((option: SelectOption | string, index: number) => (
          <MenuItem key={index} value={typeof option === 'string' ? option : option.value}>
            {typeof option === 'string' ? option : option.label}
          </MenuItem>
        ))}
      </Select>
    )
  } else if (type === 'phone') {
    InputObject = (
      <Input
        color="secondary"
        error={error}
        inputComponent={PhoneInputMask}
        name={propName}
        type="text"
        value={value}
        onChange={onChange}
      />
    )
  } else if (type === 'password') {
    InputObject = (
      <Input color="secondary" error={error} name={propName} type="password" value={value} onChange={onChange} />
    )
  } else {
    if (maxLength !== undefined && maxLength !== null) {
      InputObject = (
        <Input
          color="secondary"
          error={error}
          inputProps={{ maxLength: maxLength }}
          name={propName}
          type="text"
          value={value}
          onChange={onChange}
        />
      )
    } else {
      InputObject = (
        <Input color="secondary" error={error} name={propName} type="text" value={value} onChange={onChange} />
      )
    }
  }

  return (
    <FormControl className={'form-group ' + propName}>
      <InputLabel htmlFor={propName}>
        {fieldName}{' '}
        {required && (
          <Typography color="error" component="span">
            *
          </Typography>
        )}
      </InputLabel>
      {InputObject}
      {error && (
        <FormHelperText ref={errorRef} error>
          Valid {fieldName} is required
        </FormHelperText>
      )}
    </FormControl>
  )
}

export interface FormInputType {
  propName: string
  fieldName: string
  value: any
  maxLength?: number
  onChange: (event: any, label?: string) => void
  error?: boolean
  required?: boolean
  type?: 'text' | 'select' | 'date' | 'month' | 'password' | 'phone'
  options?: Array<SelectOption | string>
  nextIteration?: number
}

export default FormInput
