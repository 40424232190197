import React from 'react'
import Typography from '@material-ui/core/Typography'
import { getPaymentDate } from 'src/helpers'
import { gymDetailsSelector, planStateSelector } from 'src/selectors'
import { useSelector } from 'react-redux'

const RecurringPaymentInfo = (): JSX.Element => {
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const { clubFees, firstDueDate, scheduleFrequency, clubFeeTotalAmount } = reduxPlanInfo

  return (
    <Typography variant="body1">
      {'We’ll bill your account '}
      <strong style={{ textTransform: 'lowercase' }}>{scheduleFrequency}</strong>
      {' starting on '}
      <strong>{getPaymentDate(reduxGymInfo, reduxPlanInfo, firstDueDate)}</strong>.
      {!!clubFeeTotalAmount &&
        `This payment method will also be used for ${
          clubFees.length < 2 && clubFees.length > 0 ? clubFees[0].feeName.toLowerCase() : 'upcoming fees'
        }.`}
    </Typography>
  )
}

export default RecurringPaymentInfo
