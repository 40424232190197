import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { gymService } from 'src/services'
import * as states from '../states'

export const fetchCountriesAndStates = createAsyncThunk('gym/fetchCountriesAndStates', async (clubId: string) => {
  const { data } = await gymService.getCountriesStatesProvinces(clubId)

  return data.countries
})

const countriesAndStatesSlice = createSlice({
  name: 'countriesAndStates',
  initialState: states.initialCountriesAndStatesState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountriesAndStates.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.countriesStates = action.payload
      return state
    })
    builder.addCase(fetchCountriesAndStates.pending, (state) => {
      state.loading = true
      return state
    })
    builder.addCase(fetchCountriesAndStates.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error.message
      return state
    })
  },
})

export default countriesAndStatesSlice.reducer
