import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  Addons,
  FieldOptionState,
  GuestValues,
  GymState,
  LoadingState,
  PaymentValues,
  ProfileFields,
  UserDefinedField,
  VerifiedAgreement,
} from 'src/interfaces'
import { gymService } from 'src/services'
import * as states from './states'
import countriesAndStatesReducer, { fetchCountriesAndStates } from './countriesAndStates/countriesAndStatesSlice'
import campaignsReducer, { fetchCampaigns } from './campaigns/campaigns'
import locationsReducer from './locations/locations'
import { filterPlansMatchingPromo } from 'src/helpers'
import { defaultRequiredProfileFields } from 'src/common'

const initialStepperState = false
const initialLoadingState: LoadingState = []
const requiredProfileFields = [...defaultRequiredProfileFields]

interface IFetchGymById {
  gymId: string
  promoCode?: string
}

const fetchGymById: any = createAsyncThunk('gym/fetchGymById', async ({ gymId, promoCode }: IFetchGymById) => {
  const isPromoCodeActive = Boolean(promoCode)
  const response = await axios.all([
    gymService.getGymConfiguration(gymId),
    gymService.getGymPlans(gymId, isPromoCodeActive),
  ])
  let plans = response[1]

  if (promoCode) {
    plans = filterPlansMatchingPromo(plans, promoCode)
    if (!plans.length) {
      plans = await gymService.getGymPlans(gymId, false)
    }
  }

  return [response[0].data, plans]
})

const selectGymPlan = createAsyncThunk(
  'planInformation/selectPlan',
  async (gymPlan: { planId: string; clubNumber: string }) => {
    const { planId, clubNumber } = gymPlan

    return await gymService.getSelectedPlan(planId, clubNumber)
  },
)

const selectAddons: any = createAsyncThunk('planInformation/selectAddons', async (config: Addons) => {
  const { planId, clubNumber, addons } = config

  const { data } = await gymService.getSelectedPlanWithAddons(planId, clubNumber, addons)

  return data
})

const gymSlice: any = createSlice({
  name: 'gym',
  initialState: states.initialGymState,
  reducers: {
    setClubId(state: GymState, action: PayloadAction<string>) {
      state.clubId = action.payload
      return state
    },
    addPlan(state: any, action: any) {
      state.plans.push(action.payload)
      return state
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(fetchGymById.fulfilled, (state: any, action: any) => {
      state.loading = false
      state.loaded = true
      state.gym = action.payload[0][0] || states.initialGymState.gym
      state.plans = action.payload[1] || states.initialGymState.plans
      return state
    })

    builder.addCase(fetchGymById.pending, (state: any) => {
      state.loading = true
      return state
    })
  },
})

const planInformationSlice: any = createSlice({
  name: 'planInformation',
  initialState: states.initialPlanInformationState,
  reducers: {
    clearPlanSelection() {
      return states.initialPlanInformationState
    },
    setSelectedAddons(state, action: any) {
      const { payload } = action
      const { schedules } = state

      const checkedSchedules = schedules.map((schedule: any) => {
        if (payload[schedule.profitCenter]) {
          return {
            ...schedule,
            userChecked: true,
          }
        }

        return schedule
      })

      state.schedules = checkedSchedules

      return state
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(selectAddons.fulfilled, (state: any, action: any) => {
      state.clubFeeTotalAmount = action.payload.clubFeeTotalAmount
      state.clubFees = action.payload.clubFees
      state.downPaymentTotalAmount = action.payload.downPaymentTotalAmount
      state.downPayments = action.payload.downPayments
      state.scheduleTotalAmount = action.payload.scheduleTotalAmount
      state.schedules = action.payload.schedules
      state.totalContractValue = action.payload.totalContractValue
      return state
    })
    builder.addCase(selectGymPlan.fulfilled, (state: any, action: any) => {
      state = action.payload

      return state
    })
  },
})

const profileInformationSlice = createSlice({
  name: 'profileInformation',
  initialState: states.initialProfileInformationState,
  reducers: {
    setProfileInfo(state, action) {
      const { secondaryMembers, ...fields } = action.payload
      Object.keys(fields).forEach((fieldName) => {
        state[fieldName as keyof ProfileFields] = {
          ...state[fieldName as keyof ProfileFields],
          ...action.payload[fieldName],
        }
      })

      state.secondaryMembers = secondaryMembers

      return state
    },
  },
  extraReducers: (builder: any) => {
    // set profile fields states (hide/show/required) according to the selected plan data
    builder.addCase(selectGymPlan.fulfilled, (state: any, action: any) => {
      const planProfileFields = action.payload.fieldOption

      for (const field of planProfileFields) {
        const fieldName = field.fieldOptionCode
        let newState = FieldOptionState[field.state as keyof typeof FieldOptionState]

        if (requiredProfileFields.includes(fieldName)) {
          newState = FieldOptionState.required
        }
        state[field.fieldOptionCode].state = newState
      }
      return state
    })

    // modify profile information slice depending on received gym settings
    builder.addCase(fetchGymById.fulfilled, (state: any, action: any) => {
      const gymSettings = action.payload[0][0] ?? {}

      // set the default value for the country field to the country of the gym, if the country
      // was not prepopulated from saved user data
      if (!state.country.value) {
        state.country.value = gymSettings.country
      }

      // add birthDate field to the list of required profile fields, regardless of
      // plan settings, if the gym does not allow registration of minors
      if (!gymSettings.minorAllowSignUp) {
        requiredProfileFields.push('birthDate')
      }

      return state
    })
  },
})

const guestProfileFieldsSlice = createSlice({
  name: 'guestProfileFields',
  initialState: states.initialGuestProfileFields,
  reducers: {
    setFieldsState(state, action: PayloadAction<GuestValues>) {
      state = action.payload
      return state
    },
  },
  extraReducers: (builder: any) => {
    // set gym country as a default for the guest profile country field
    builder.addCase(fetchGymById.fulfilled, (state: any, action: any) => {
      if (!state.countryCode) {
        state.countryCode = action.payload?.[0][0].country
      }
      return state
    })
  },
})

const profileUDFSlice: any = createSlice({
  name: 'profileInformation',
  initialState: [] as UserDefinedField[],
  reducers: {
    setUDFs(state, action: PayloadAction<UserDefinedField[]>) {
      state = action.payload
      return state
    },
  },
})

const paymentSlice: any = createSlice({
  name: 'payment',
  initialState: states.initialPaymentState,
  reducers: {
    setPayment(state, action: PayloadAction<PaymentValues>) {
      state = action.payload
      return state
    },
  },
})

const agreementSlice: any = createSlice({
  name: 'agreement',
  initialState: states.initialAgreementState,
  reducers: {
    setAgreement(state, action: PayloadAction<VerifiedAgreement>) {
      state = action.payload
      return state
    },
  },
})

const stepperSlice: any = createSlice({
  name: 'stepper',
  initialState: initialStepperState,
  reducers: {
    onNext(state) {
      state = true
      return state
    },
    resetNext(state) {
      state = false
      return state
    },
  },
})

const loadingSlice: any = createSlice({
  name: 'loadings',
  initialState: initialLoadingState,
  reducers: {
    startLoading(state, action: PayloadAction<string>) {
      return [...state, action.payload]
    },
    endLoading(state, action: PayloadAction<string>) {
      return state.filter((loadingName) => loadingName !== action.payload)
    },
  },
})

export {
  gymSlice,
  fetchGymById,
  selectGymPlan,
  selectAddons,
  stepperSlice,
  loadingSlice,
  planInformationSlice,
  profileInformationSlice,
  guestProfileFieldsSlice,
  profileUDFSlice,
  paymentSlice,
  agreementSlice,
  countriesAndStatesReducer,
  fetchCountriesAndStates,
  campaignsReducer,
  fetchCampaigns,
  locationsReducer,
}

//actions in redux-toolkit
