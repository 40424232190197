import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <Typography>
      ABC collects information about you that is provided in conjunction with your purchase of services from one of
      ABC’s health club clients, including information provided on your health club membership agreement(s) and personal
      training agreement(s). Information about you is typically obtained from the agreement you sign with the club from
      whom you purchase services. Your personal information may also be collected from you when you provide information
      directly to ABC in any manner, or when you update account or contact information at the club or online. The
      information ABC collects about you may include your name, postal address, email address, phone numbers, bank
      account or credit card information, audio recordings of phone calls with you or made in relation to your account,
      photographs voluntarily taken at the club, and e-mail correspondence with you or in relation to your account. ABC
      collects such personal information about you for the purpose of processing and collecting payments owed by you to
      the club, to contact you in relation to your health club account, and to otherwise provide third party payment
      processing and administrative services to your club. ABC uses information it collects to communicate with health
      club members, collect payments owed to its health club clients, and maintain up to date account information for
      members. ABC’s Privacy Policy is available at
      <br />
      <Link href="https://www.abcfinancial.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
        https://www.abcfinancial.com/privacy-policy/
      </Link>
      .
    </Typography>
  )
}

export default PrivacyPolicy
