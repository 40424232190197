import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { stepperSlice } from 'src/reducers'
import { useMediaUp } from 'src/helpers'
import { PAGES } from 'src/common'
import { planStateSelector } from 'src/selectors'
import FooterFeePanel from './FooterFeePanel'
import './Footer.scss'

interface FooterProps {
  guestMode: boolean
}

const FeeColumn = (props: { label: string; value: string }) => {
  const isDesktop = useMediaUp('lg')

  const { label, value } = props
  return (
    <Grid direction="column" container>
      <Grid item>
        <Typography variant={isDesktop ? 'body1' : 'body2'}>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography style={{ textAlign: isDesktop ? 'right' : 'center', fontSize: isDesktop ? '' : 22 }} variant="h6">
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

function Footer({ guestMode = false }: FooterProps) {
  const dispatch = useDispatch()
  const location = useLocation()
  const { scheduleFrequency, planName, downPaymentTotalAmount, scheduleTotalAmount, clubFees, clubFeeTotalAmount } =
    useSelector(planStateSelector)
  const showFees = [PAGES.profile, PAGES.payment].includes(location.pathname) && !guestMode
  const hideFooter = [PAGES.review, PAGES.guestReview].includes(location.pathname)
  const showFooterFee = ![PAGES.plan].includes(location.pathname) && !guestMode
  const isDesktop = useMediaUp('md')

  if (hideFooter) {
    return null
  }

  const handleNext = () => {
    dispatch(stepperSlice.actions.onNext())
  }

  return (
    <Box bottom={0} display="flex" flexDirection="column" position="sticky" width="100%" zIndex={1101}>
      {showFooterFee && <FooterFeePanel />}
      <AppBar className={`footer ${isDesktop ? '' : 'mobile'}`} position="sticky">
        <Container
          className="footer-container"
          style={{ minHeight: isDesktop ? 100 : 'auto', textTransform: 'uppercase', display: 'flex' }}
        >
          <Grid container>
            <Hidden smDown>
              <Grid md={4} xs={4} item>
                <Box className="with-decor" display="flex" pl={3}>
                  {!guestMode && (
                    <Grid direction="column" justifyContent="center" container>
                      <Grid item>
                        <Typography variant="h6">{planName || 'Select a Plan'}</Typography>
                      </Grid>
                      {scheduleFrequency && (
                        <Grid item>
                          <Typography variant="subtitle2">{scheduleFrequency}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Box>
              </Grid>
            </Hidden>
            <Grid md={4} style={{ display: 'flex' }} xs={12} item>
              {showFees && (
                <Box display="flex" flex={1} mx={[0, 0, -2]} pt={[3, 3, 0]}>
                  <Grid alignItems="center" justifyContent="space-around" container>
                    <Grid item>
                      <FeeColumn label="Due Today" value={downPaymentTotalAmount} />
                    </Grid>
                    <Grid item>
                      <FeeColumn
                        label={scheduleFrequency ? scheduleFrequency : 'Monthly'}
                        value={scheduleTotalAmount}
                      />
                    </Grid>
                    <Grid item>
                      {clubFees.length < 2 && clubFees.length > 0 ? (
                        <FeeColumn label={clubFees[0].feeName} value={clubFees[0].feeAmount} />
                      ) : (
                        <FeeColumn label="Upcoming Fees" value={clubFeeTotalAmount} />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid className="next" md={4} xs={12} item>
              <Box className="with-decor left" display="flex">
                <Grid
                  alignItems="center"
                  className="next-container"
                  justifyContent={isDesktop ? 'flex-end' : 'center'}
                  container
                >
                  <Grid item>
                    <Box mr={[1, 1, 2, 5]} py={2}>
                      <Button
                        className="next-button"
                        color="secondary"
                        style={{
                          width: isDesktop ? 153 : 273,
                          height: isDesktop ? 50 : 40,
                          lineHeight: isDesktop ? '50px' : '40px',
                        }}
                        variant="contained"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </AppBar>
    </Box>
  )
}

export default Footer
