import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const MastercardIcon = () => {
  return (
    <SvgIcon
      fill="none"
      height={24}
      style={{ fontSize: '3.1875rem' }}
      viewBox="0 0 54 24"
      width={54}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.595 7.283h-5.776v9.433h5.776V7.283z" fill="#FF5F00" />
      <path d="M24.414 12a5.99 5.99 0 012.292-4.716 6 6 0 100 9.433A5.989 5.989 0 0124.414 12z" fill="#EB001B" />
      <path
        d="M35.839 15.718v-.194h.083v-.04h-.198v.04h.078v.194h.037zm.385 0v-.234h-.06l-.07.167-.07-.167h-.06v.234h.043v-.177l.065.152h.045l.065-.152v.177h.041zM36.413 12a5.998 5.998 0 01-9.707 4.716 6.001 6.001 0 000-9.433 5.999 5.999 0 019.707 4.716V12z" // NOSONAR
        fill="#F79E1B"
      />
    </SvgIcon>
  )
}
