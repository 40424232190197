import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import { SecondaryMember, FieldOptionState } from 'src/interfaces'
import { FormBlock, FormBlockHeading, HeadingBox, Modal } from 'src/components'
import { planStateSelector, profileStateSelector } from 'src/selectors'
import { BirthdateField, ProfileField } from '.'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import RemoveIcon from '@material-ui/icons/Remove'
import cx from 'classnames'

const useStyles = makeStyles({
  heading: {
    fontWeight: 500,
    lineHeight: 1.333,
  },
  button: {
    margin: '-6px 0',
  },
  icon: {
    marginRight: 10,
    alignSelf: 'baseline',
  },
  removeButton: {
    margin: 0,
  },
})

const emptyMember: SecondaryMember = {
  birthDate: '',
  email: '',
  firstName: '',
  lastName: '',
  cellNumber: '',
}

export const SecondaryMembers = ({
  nextIteration,
  members,
  setMembers,
  setMinors,
  minorAge,
}: {
  members: SecondaryMember[]
  nextIteration: number
  setMembers: Dispatch<SetStateAction<SecondaryMember[]>>
  setMinors: Dispatch<SetStateAction<string[]>>
  minorAge: string | undefined
}): JSX.Element => {
  const reduxPlanInfo = useSelector(planStateSelector)
  const reduxProfileInfo = useSelector(profileStateSelector)
  const [indexToRemove, setIndexToRemove] = useState<number | undefined>(undefined)
  const classes = useStyles()
  const reduxSecondaryMembers = reduxProfileInfo.secondaryMembers
  const maxMembersAmount = reduxPlanInfo.additionalMembersAllowed

  useEffect(() => {
    setMembers(reduxSecondaryMembers)
  }, [])

  const handleChange = (index: number, propName: string, event: { target: { value: string } }) => {
    const newValue = event.target.value

    const changedMembers = members.map((member, ind) =>
      ind === index ? { ...members[index], [propName]: newValue } : member,
    )

    setMembers(changedMembers)
  }

  const addMember = () => {
    if (members.length < maxMembersAmount) {
      setMembers([...members, { ...emptyMember }])
    }
  }

  const handleRemoveMember = (indexToRemove: number) => {
    const memberToRemove = members[indexToRemove]
    const hasFilledData = Object.values(memberToRemove).some(Boolean)

    hasFilledData ? setIndexToRemove(indexToRemove) : removeMember(indexToRemove)
  }

  const removeMember = (indexToRemove: number) => {
    setMembers(members.filter((member, index) => index !== indexToRemove))
    setIndexToRemove(undefined)
  }

  return (
    <>
      <HeadingBox title="Add additional members">{maxMembersAmount} additional members allowed</HeadingBox>
      {members.map((member, index) => (
        <Grid key={index} direction="row" spacing={3} container>
          <Grid item>
            <FormBlock>
              <Grid item>
                <FormBlockHeading title="Additional member">
                  <Button
                    className={cx(classes.button, classes.removeButton)}
                    color="primary"
                    variant="text"
                    onClick={() => handleRemoveMember(index)}
                  >
                    <RemoveIcon className={classes.icon} /> Remove
                  </Button>
                </FormBlockHeading>
                <Grid direction="row" spacing={3} container>
                  <ProfileField
                    fieldName="First name"
                    nextIteration={nextIteration}
                    propName="firstName"
                    state={FieldOptionState.required}
                    value={member.firstName}
                    onChange={(event) => handleChange(index, 'firstName', event)}
                  />
                  <ProfileField
                    fieldName="Last name"
                    maxLength={19}
                    nextIteration={nextIteration}
                    propName="lastName"
                    state={FieldOptionState.required}
                    value={member.lastName}
                    onChange={(event) => handleChange(index, 'lastName', event)}
                  />
                  <ProfileField
                    fieldName="Email"
                    maxLength={50}
                    nextIteration={nextIteration}
                    propName="email"
                    state={FieldOptionState.required}
                    value={member.email}
                    onChange={(event) => handleChange(index, 'email', event)}
                  />
                  <ProfileField
                    fieldName="Mobile phone"
                    nextIteration={nextIteration}
                    propName="cellNumber"
                    state={FieldOptionState.required}
                    value={member.cellNumber}
                    onChange={(event) => handleChange(index, 'cellNumber', event)}
                  />
                  <BirthdateField
                    md={12}
                    minorAge={minorAge}
                    nextIteration={nextIteration}
                    setMinors={setMinors}
                    state={FieldOptionState.required}
                    value={member.birthDate}
                    onChange={(event) => handleChange(index, 'birthDate', event)}
                  />
                </Grid>
              </Grid>
            </FormBlock>
          </Grid>
        </Grid>
      ))}
      {members.length < maxMembersAmount && (
        <Grid direction="row" spacing={3} container>
          <Grid xs={12} item>
            <FormBlock>
              <Grid item>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Typography className={classes.heading} variant="subtitle1">
                    Add member
                  </Typography>
                  <Button className={classes.button} color="primary" variant="text" onClick={addMember}>
                    <AddIcon className={classes.icon} />
                    ADD
                  </Button>
                </Box>
              </Grid>
            </FormBlock>
          </Grid>
        </Grid>
      )}
      <Modal
        cancelText="No"
        confirmText="Yes"
        handleCancel={() => setIndexToRemove(undefined)}
        handleConfirm={() => removeMember(indexToRemove as number)}
        open={typeof indexToRemove !== 'undefined'}
        title="Remove Member?"
      >
        <Typography variant="body1">
          {'Are you sure you want to remove this member? All data pertaining to them will be deleted.'}
        </Typography>
      </Modal>
    </>
  )
}
