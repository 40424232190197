import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const DiscoverIcon = () => {
  return (
    <SvgIcon
      fill="none"
      height={24}
      style={{ fontSize: '3.1875rem' }}
      viewBox="0 0 54 24"
      width={54}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.899 8.184H6v6.65h1.899c1 0 1.737-.241 2.37-.77a3.347 3.347 0 001.208-2.555c-.011-1.956-1.473-3.325-3.578-3.325zm1.519 4.994c-.403.368-.933.529-1.773.529H7.3V9.31h.345c.84 0 1.347.15 1.773.541.448.403.713 1.013.713 1.657 0 .633-.265 1.266-.713 1.669zM13.365 8.184h-1.3v6.65h1.3v-6.65zM16.529 10.738c-.783-.287-1.001-.483-1.001-.84 0-.414.403-.736.966-.736.392 0 .702.16 1.048.54l.678-.885a2.913 2.913 0 00-1.956-.737c-1.173 0-2.07.817-2.07 1.899 0 .92.413 1.38 1.633 1.818.506.184.771.299.898.38.264.172.39.414.39.69 0 .54-.425.932-1 .932-.622 0-1.116-.31-1.416-.886l-.84.805c.599.875 1.312 1.266 2.302 1.266 1.346 0 2.29-.897 2.29-2.186 0-1.047-.438-1.519-1.922-2.06zM18.854 11.51c0 1.956 1.53 3.464 3.51 3.464.551 0 1.035-.116 1.622-.392v-1.53c-.518.518-.979.725-1.565.725-1.3 0-2.233-.944-2.233-2.29 0-1.277.955-2.278 2.175-2.278.622 0 1.082.218 1.623.748v-1.53c-.564-.288-1.036-.404-1.6-.404-1.956.012-3.532 1.554-3.532 3.487zM34.26 12.648L32.49 8.184h-1.416l2.83 6.823h.691l2.877-6.823h-1.404l-1.806 4.464zM38.058 14.835h3.67v-1.128h-2.382v-1.795h2.29v-1.128h-2.29V9.311h2.382V8.184h-3.67v6.65zM46.86 10.151c0-1.242-.85-1.967-2.347-1.967h-1.921v6.65h1.3v-2.669h.173l1.795 2.67h1.6l-2.095-2.797c.955-.207 1.496-.874 1.496-1.887zm-2.6 1.093h-.38V9.231h.403c.806 0 1.243.333 1.243.99 0 .667-.437 1.023-1.266 1.023z" // NOSONAR
        fill="#231F20"
      />
      <path d="M27.944 15.065a3.533 3.533 0 100-7.065 3.533 3.533 0 000 7.065z" fill="url(#prefix__paint0_linear)" />
      <path
        d="M27.944 15.065a3.533 3.533 0 100-7.065 3.533 3.533 0 000 7.065z"
        fill="url(#prefix__paint1_linear)"
        opacity={0.65}
      />
      <path
        d="M47.689 8.438c0-.115-.08-.184-.219-.184h-.184v.587h.138v-.23l.161.23h.173l-.196-.242c.08-.023.127-.08.127-.161zm-.242.08h-.023V8.37h.023c.07 0 .104.023.104.08 0 .047-.035.07-.104.07z" // NOSONAR
        fill="#231F20"
      />
      <path
        d="M47.494 8.035a.503.503 0 00-.507.506c0 .288.23.507.507.507A.51.51 0 0048 8.54a.51.51 0 00-.506-.506zm0 .932a.41.41 0 01-.403-.414.41.41 0 01.403-.414c.218 0 .402.195.402.414a.41.41 0 01-.402.414z" // NOSONAR
        fill="#231F20"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="prefix__paint0_linear"
          x1={29.754}
          x2={27.306}
          y1={14.367}
          y2={10.534}
        >
          <stop stopColor="#F89F20" />
          <stop offset={0.25} stopColor="#F79A20" />
          <stop offset={0.533} stopColor="#F68D20" />
          <stop offset={0.62} stopColor="#F58720" />
          <stop offset={0.723} stopColor="#F48120" />
          <stop offset={1} stopColor="#F37521" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="prefix__paint1_linear"
          x1={29.367}
          x2={25.789}
          y1={14.315}
          y2={7.322}
        >
          <stop stopColor="#F58720" />
          <stop offset={0.359} stopColor="#E16F27" />
          <stop offset={0.703} stopColor="#D4602C" />
          <stop offset={0.982} stopColor="#D05B2E" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
