import { createSelector } from '@reduxjs/toolkit'
import { GymPlanSelection, GymState, PaymentValues, ProfileValues } from 'src/interfaces'
import { RootState } from '../configureStore'

export const gymStateSelector = (state: RootState): GymState => state.gymSlice
export const stepperSelector = (state: RootState): boolean => state.stepperSlice
export const planStateSelector = (state: RootState): GymPlanSelection => state.planInformationSlice
export const profileStateSelector = (state: RootState): ProfileValues => state.profileInformationSlice
export const paymentStateSelector = (state: RootState): PaymentValues => state.paymentSlice
export const locationsStateSelector = (state: RootState) => state.locationsSlice

/**
 * clubId and clubNumber may differ because of current clubNumber approach on the MICO backend (should be changed in
 * future) - for now only 5-digit club ids are allowed. So for all requests to MICO we should use club number and for a
 * requests to GymSales we must use the club id provided as query parameter in club url.
 * Example: http://localhost:3000/signup/payment?club=0003:
 * 0003 here is a real club id (clubIdSelector) and it is used for a GS requests. But MICO requests should
 * have 00003 (clubNumberSelector)
 */
export const clubIdSelector = createSelector(gymStateSelector, ({ clubId }) => clubId)
export const gymDetailsSelector = createSelector(gymStateSelector, ({ gym }) => gym)
export const clubNumberSelector = createSelector(gymStateSelector, ({ gym }) => gym.number)
export const mainProfileFieldsSelector = createSelector(profileStateSelector, (fields) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { secondaryMembers, ...profileFields } = JSON.parse(JSON.stringify(fields))

  return profileFields
})

export const selectedPlanIdSelector = createSelector(planStateSelector, (plan) => plan.planId)
export const locationsSelector = createSelector(locationsStateSelector, (locationState) => locationState.locations)
