import isBoolean from 'lodash/isBoolean'
import { gymSalesService } from 'src/services'
import {
  gymSalesGender,
  gymSalesSteps,
  IUserDataForGymSales,
  IUserDataFromGymSales,
  ProfileValues,
} from 'src/interfaces'

export const saveDataToGymSales = (data: IUserDataForGymSales) => {
  gymSalesService.sendUserData(data)
}

interface IMemberGymSalesData extends ProfileValues {
  clubId: string
  step: gymSalesSteps
}

export const saveMemberDataToGymSales = (dataToSave: IMemberGymSalesData) => {
  const userData = {
    step: dataToSave.step,
    abc_club_number: dataToSave.clubId,
    first_name: dataToSave.firstName.value,
    last_name: dataToSave.lastName.value,
    phone_mobile: dataToSave.cellNumber.value,
    phone_work: dataToSave.workNumber.value,
    phone_home: dataToSave.homeNumber.value,
    email: dataToSave.email.value,
    address: dataToSave.street.value,
    city: dataToSave.city.value,
    state: dataToSave.state.value,
    zip: dataToSave.zip.value,
    source_name: dataToSave.promotion.label || document.referrer,
    sms_opted_out: !dataToSave.marketingSMS.value,
    email_opted_out: !dataToSave.marketingEmails.value,
    gender: dataToSave.gender.value,
    birthday: dataToSave.birthDate.value,
  }

  userData.gender = (gymSalesGender as Record<string, string>)[dataToSave.gender.value]
  if (!userData.gender) {
    delete userData.gender
  }

  saveDataToGymSales(userData)
}

export const getGymSalesUserData = async (gsUserId: string, gsUserToken: string): Promise<IUserDataFromGymSales> => {
  const gsUserData = await gymSalesService.getUserSavedData(gsUserId, gsUserToken)
  const gender = Object.keys(gymSalesGender).find((genderKey) => {
    return (gymSalesGender as Record<string, string>)[genderKey] === gsUserData.gender
  })

  const fieldsData: IUserDataFromGymSales = {
    firstName: { value: gsUserData.first_name },
    lastName: { value: gsUserData.last_name },
    email: { value: gsUserData.email },
    cellNumber: { value: gsUserData.phone_mobile },
    marketingSMS: { value: isBoolean(gsUserData.sms_opted_out) ? !gsUserData.sms_opted_out : null },
    marketingEmails: { value: isBoolean(gsUserData.email_opted_out) ? !gsUserData.email_opted_out : null },
    street: { value: gsUserData.address },
    city: { value: gsUserData.city },
    state: { value: gsUserData.state },
    zip: { value: gsUserData.zip },
    birthDate: { value: gsUserData.birthday },
    gender: { value: gender },
    promotion: { value: '', label: gsUserData.source_name },
  }

  // remove all empty values
  return Object.keys(fieldsData).reduce((memo, key) => {
    const fieldData = fieldsData[key as keyof IUserDataFromGymSales]
    const fieldValue = fieldData.value || fieldData.label
    if (fieldValue || isBoolean(fieldValue)) {
      memo[key as keyof IUserDataFromGymSales] = fieldData
    }
    return memo
  }, {} as IUserDataFromGymSales)
}
