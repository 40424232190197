import ResizeObserver from 'resize-observer-polyfill'
import { PRESALE_DATE_SPOILER } from 'src/common'
import {
  Country,
  GymDetails,
  GymPlan,
  GymPlanSelection,
  PlanDisplayLocation,
  SelectOption,
  Campaign,
  ProfileFields,
} from 'src/interfaces'
import { gymService } from 'src/services'
import { Theme } from '@material-ui/core/styles/createTheme'
import isEmpty from 'lodash/isEmpty'
import * as iframeDataExchange from './iframeDataExchange'

export const filterVisiblePlanLocations = (plans: GymPlan[], urlParams: URLSearchParams): GymPlan[] => {
  return plans.filter((plan: GymPlan) => {
    const planDisplayMode = plan.webPlanDisplayLocation

    if (planDisplayMode === PlanDisplayLocation.all) {
      return true
    }

    return urlParams?.get('clubIPad') === 'true'
      ? planDisplayMode === PlanDisplayLocation.ipad
      : planDisplayMode === PlanDisplayLocation.web
  })
}

export type TGetCountryStateOptions = {
  localCountryOptions: SelectOption[]
  localStateOptions: { [key: string]: SelectOption[] }
}

export const getCountryStateOptions = (countryStates: Country[]): TGetCountryStateOptions => {
  const localCountryOptions: SelectOption[] = []

  const localStateOptions: { [key: string]: SelectOption[] } = {}

  if (!isEmpty(countryStates)) {
    for (const country of countryStates) {
      const countryKey = Object.keys(country)[0]
      const stateOptions = []

      localCountryOptions.push({ value: countryKey, label: country[countryKey].countryFullName })

      for (const state of country[countryKey].statesProvinces) {
        const stateKey = Object.keys(state)[0]
        stateOptions.push({ value: stateKey, label: state[stateKey].stateProvinceFullName })
      }

      localStateOptions[countryKey] = stateOptions
    }
  }

  return { localCountryOptions, localStateOptions }
}

export const getPromotionOptions = (campaigns: Campaign[]): SelectOption[] => {
  const localPromotionOptions: SelectOption[] = []

  for (const campaign of campaigns) {
    localPromotionOptions.push({ value: campaign.value, label: campaign.text })
  }

  return localPromotionOptions
}

export const getSalesPeople = async (clubId: string): Promise<SelectOption[]> => {
  const localSalesRepOptions: SelectOption[] = []
  try {
    const salesRepResponse = await gymService.getSalesPeople(clubId)

    if (salesRepResponse && salesRepResponse.data) {
      for (const salesRep of salesRepResponse.data) {
        localSalesRepOptions.push({ value: salesRep.value, label: salesRep.text })
      }
    }
  } catch (err) {
    console.error('Oops, something went wrong with getCampaigns:', err)
  }

  return localSalesRepOptions
}

export const getBrandingColors = (gymInfo: GymDetails): { primaryColor: string; secondaryColor: string } => ({
  primaryColor: formatColorHex(gymInfo.theme && gymInfo.theme !== 'DEFAULT' ? gymInfo.theme : '#263870'),
  secondaryColor: formatColorHex(
    gymInfo.brandColor && gymInfo.brandColor !== '2EA2FF' ? gymInfo.brandColor : '#7D2BB9',
  ),
})

export const extractProfileValues = (profileState: ProfileFields, keys: Array<keyof ProfileFields>) =>
  keys.reduce((memo, key) => ({ ...memo, [key]: profileState[key].value }), {})

function formatColorHex(color: string) {
  color = color.charAt(0) === '#' ? color : `#${color}`
  return color
}

export interface ICardColorSchema {
  activeColor: string
  activeHeaderColor: string
  activeTextColor: string
}

export const getCardColorSchema = (theme: Theme, isSelected?: boolean): ICardColorSchema => {
  if (isSelected) {
    return {
      activeColor: theme.palette.secondary.main,
      activeHeaderColor: theme.palette.secondary.main,
      activeTextColor: theme.palette.secondary.contrastText,
    }
  }

  return {
    activeColor: '#dddddd',
    activeHeaderColor: 'initial',
    activeTextColor: '#000000',
  }
}

type TGetCountryLabels = {
  stateLabel: string
  zipCodeLabel: string
}

export const getCountryLabel = (countryCode?: string): TGetCountryLabels => {
  switch (countryCode) {
    case 'CA':
      return { stateLabel: 'Province', zipCodeLabel: 'Postal code' }
    case 'MX':
      return { stateLabel: 'State', zipCodeLabel: 'Postal code' }
    default:
      return { stateLabel: 'State', zipCodeLabel: 'Zip code' }
  }
}

export const getCountryStates = (
  countryCode: string,
  localOptions?: TGetCountryStateOptions,
): SelectOption[] | undefined => {
  if (!localOptions) return

  return localOptions.localStateOptions[countryCode]
}

export const filterPlansMatchingPromo = (plans: GymPlan[], promo: string): GymPlan[] => {
  return plans.filter(({ promoCode }) => promoCode.toLowerCase() === promo.trim().toLowerCase())
}

export const getPaymentDate = (gymInfo: GymDetails, plan: GymPlanSelection, date?: string): string | undefined => {
  return gymInfo.preSale && !plan.activePresale ? PRESALE_DATE_SPOILER : date
}

// need to determinate cases when this application is used in iframe
export const appIsUsedInIframe = (): boolean => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const startIframeInfoExchange = (): void => {
  if (!appIsUsedInIframe()) {
    return
  }

  const onMessageHandler = function (event: MessageEvent) {
    // Need to check first is this message belongs to initialization of iframe tracking command
    if (event.data == 'startIframeTracking' && event.source) {
      window.removeEventListener('message', onMessageHandler)

      iframeDataExchange.init(event.source)

      const resizeObserver = new ResizeObserver((entries) => {
        iframeDataExchange.emit({ appHeight: entries[0].target.clientHeight })
      })

      // start observing a DOM node and its size
      resizeObserver.observe(document.body)
    }
  }

  window.addEventListener('message', onMessageHandler)
}

export const scrollToElem = (elem: HTMLElement | null): void => {
  if (!elem?.scrollIntoView) return

  elem.scrollIntoView({ behavior: 'smooth', block: 'center' })

  const { top, height } = elem.getBoundingClientRect()
  iframeDataExchange.emit({ scrollTo: { top, height } })
}
