import { CardDetails } from './types'

export const PAGES = {
  plan: '/plan',
  profile: '/profile',
  payment: '/payment',
  review: '/review',
  guest: '/guest',
  guestReview: '/guest-review',
}

export const STEPS = {
  plan: {
    index: 1,
    path: PAGES.plan,
    displayText: 'Plan',
  },
  profile: {
    index: 2,
    path: PAGES.profile,
    displayText: 'Profile',
  },
  payment: {
    index: 3,
    path: PAGES.payment,
    displayText: 'Payment',
  },
  review: {
    index: 4,
    path: PAGES.review,
    displayText: 'Review',
  },
}

export const defaultMuiThemeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1000,
      xl: 1132,
    },
  },
  palette: {
    primary: {
      main: '#263870',
    },
    secondary: {
      main: '#7d2bb9',
    },
  },
  typography: {
    h1: {
      fontSize: 48,
    },
    h2: {
      fontSize: 42,
    },
    h3: {
      fontSize: 32,
    },
    h4: {
      fontSize: 30,
    },
    h5: {
      fontSize: 28,
    },
    h6: {
      fontSize: 24,
    },
    subtitle1: {
      fontSize: 20,
    },
    subtitle2: {
      fontSize: 18,
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 16,
    },
  },
}

export const defaultRequiredProfileFields = ['firstName', 'lastName', 'email', 'cellNumber']

export const planRibbonLabels: Record<string, string> = {
  _MP: 'MOST POPULAR',
  _BV: 'BEST VALUE',
}

export const APP_MODIFIER_PARAMS = {
  userId: 'userId',
  selectedPlanId: 'planId',
  promoCode: 'promo',
  withoutHeader: 'withoutHeader',
  fromClub: 'fromClub',
  gsUserId: 'person_id',
  gsUserToken: 'token',
}

export const AUTH_TERMS = 'authTerms'
export const NOTES = 'notes'
export const SIGNATURE = 'signature'
export const PRIVACY_POLICY = 'privacyPolicy'
export const TERMS_CONDITIONS = 'termsAndConditions'

export const PRESALE_DATE_SPOILER = 'TBD'

export const DATES_FORMAT_PATTERNS = {
  date: {
    picker: 'MM/dd/yyyy',
    value: 'MM/DD/yyyy',
  },
  month: {
    picker: 'MM/yyyy',
    value: 'MM/yyyy',
  },
}

export enum PAYMENT_CARD_BRAND {
  AMEX = 'AMEX',
  DISCOVER = 'DISCOVER',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export enum SECURITY_CODE_TYPE {
  CVV = 'CVV',
  CID = 'CID',
  CVC = 'CVC',
}

export const paymentCardDetails: Record<PAYMENT_CARD_BRAND, CardDetails> = {
  [PAYMENT_CARD_BRAND.AMEX]: {
    code: { name: SECURITY_CODE_TYPE.CID, size: 4 },
    icon: 'cc-amex',
    lengths: [15],
    luhn: true,
    brand: PAYMENT_CARD_BRAND.AMEX,
    ranges: [
      [34, 34],
      [37, 37],
    ],
    pattern: '#### ###### #####',
  },
  [PAYMENT_CARD_BRAND.DISCOVER]: {
    code: { name: SECURITY_CODE_TYPE.CID, size: 3 },
    icon: 'cc-discover',
    lengths: [16, 17, 18, 19],
    luhn: true,
    brand: PAYMENT_CARD_BRAND.DISCOVER,
    ranges: [
      [6011, 6011],
      [622126, 622925],
      [644, 649],
      [65, 65],
    ],
    pattern: '#### #### #### #######',
  },
  [PAYMENT_CARD_BRAND.MASTERCARD]: {
    code: { name: SECURITY_CODE_TYPE.CVC, size: 3 },
    icon: 'cc-mastercard',
    lengths: [16],
    luhn: true,
    brand: PAYMENT_CARD_BRAND.MASTERCARD,
    ranges: [
      [2221, 2720],
      [51, 55],
    ],
    pattern: '#### #### #### ####',
  },
  [PAYMENT_CARD_BRAND.VISA]: {
    code: { name: SECURITY_CODE_TYPE.CVV, size: 3 },
    icon: 'cc-visa',
    lengths: [16],
    luhn: true,
    brand: PAYMENT_CARD_BRAND.VISA,
    ranges: [[4, 4]],
    pattern: '#### #### #### ####',
  },
}

export const validCardBrands: Record<string, string> = {
  'american-express': 'AmericanExpress',
  discover: 'Discover',
  mastercard: 'Mastercard',
  visa: 'Visa',
}
