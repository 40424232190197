import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../configureStore'

function LoadingBackdrop() {
  const reduxLoadingList = useSelector((state: RootState) => state.loadingSlice)

  return (
    <Backdrop className="loading" open={!!reduxLoadingList.length} style={{ zIndex: 3000, color: 'white' }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default LoadingBackdrop
