import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { FormBlockHeading, FormInput } from 'src/components'
import { UserDefinedField } from 'src/interfaces'
import { RootState } from 'src/configureStore'
import { planStateSelector } from 'src/selectors'

export const UdfFields = ({
  UDFs,
  setUDFs,
  nextIteration,
}: {
  UDFs: UserDefinedField[]
  setUDFs: (fields: UserDefinedField[]) => void
  nextIteration: number
}): JSX.Element | null => {
  const reduxPlanInfo = useSelector(planStateSelector)
  const reduxUdfData = useSelector((state: RootState) => state.profileUDFSlice)
  const userDefinedFields = reduxPlanInfo.userDefinedFields

  useEffect(() => {
    if (!userDefinedFields) return

    const udfs: UserDefinedField[] = []
    for (const field of userDefinedFields) {
      const label = field.field.split('UDF: ')[1]
      const id = label.replace(/\s/g, '')
      udfs.push({
        ...field,
        label,
        id,
        value: reduxUdfData.find((udfField) => udfField.id === id)?.value ?? '',
        type: field.type.toLowerCase(),
      })
    }
    setUDFs(udfs)
  }, [userDefinedFields])

  const handleUDFChange = (index: number) => (event: any) => {
    const localUDFs = UDFs.map((item, i) => {
      if (i === index) {
        return { ...UDFs[index], value: event.target.value }
      }
      return item
    })
    setUDFs(localUDFs)
  }

  if (!UDFs.length) {
    return null
  }

  return (
    <Grid item>
      <FormBlockHeading title="Additional Information" />
      <Grid direction="row" spacing={3} container>
        {UDFs.map((udf, index) => (
          <Grid key={`${index}${udf.id}`} sm={6} xs={12} item>
            <FormInput
              error={udf.required && nextIteration > 0 && !udf.value}
              fieldName={udf.label || ''}
              maxLength={50}
              nextIteration={nextIteration}
              options={udf.values.split(',')}
              propName={udf.id || ''}
              required={udf.required}
              type={udf.type === 'list' ? 'select' : udf.type === 'date' ? 'date' : 'text'}
              value={udf.value}
              onChange={handleUDFChange(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
