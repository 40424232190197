import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import { FormBlock, FormBlockHeading } from 'src/components'
import { EditButton } from './'
import { formatPhoneNumber } from 'src/helpers'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { ProfileFields } from 'src/interfaces'
import { profileStateSelector } from 'src/selectors'

const EMERGENCY_FIELDS = [
  'emergencyContactFirstName',
  'emergencyContactLastName',
  'emergencyContactNumber',
  'emergencyContactExtension',
]
const REFERRAL_FIELDS = ['salesPersonId', 'promotion']

export const AdditionalInfoBlock = ({ onEdit }: { onEdit: () => void }) => {
  const reduxProfileInfo = useSelector(profileStateSelector)
  const reduxUDFs = useSelector((state: RootState) => state.profileUDFSlice)
  const reduxUDFWithValue = reduxUDFs.filter((field) => field.value)

  const profileHasAnyValue = useCallback(
    (keysList: string[]) => keysList.some((profileKey) => reduxProfileInfo[profileKey as keyof ProfileFields]?.value),
    [reduxProfileInfo],
  )

  if (!profileHasAnyValue([...EMERGENCY_FIELDS, ...REFERRAL_FIELDS]) && !reduxUDFWithValue.length) {
    return null
  }

  return (
    <FormBlock>
      <Grid item>
        <EditButton onClick={onEdit} />
        {profileHasAnyValue(EMERGENCY_FIELDS) && (
          <>
            <FormBlockHeading title="Emergency contact information" />
            <Grid direction="column" spacing={1} container>
              {(reduxProfileInfo.emergencyContactFirstName.value ||
                reduxProfileInfo.emergencyContactLastName.value) && (
                <Grid item>
                  {reduxProfileInfo.emergencyContactFirstName.value} {reduxProfileInfo.emergencyContactLastName.value}
                </Grid>
              )}
              {reduxProfileInfo.emergencyContactNumber.value && (
                <Grid item>
                  {formatPhoneNumber(reduxProfileInfo.emergencyContactNumber.value)}
                  {reduxProfileInfo.emergencyContactExtension.value
                    ? ' ext. ' + reduxProfileInfo.emergencyContactExtension.value
                    : ''}
                </Grid>
              )}
            </Grid>
            <br />
            <br />
          </>
        )}
        {profileHasAnyValue(REFERRAL_FIELDS) && (
          <>
            <FormBlockHeading title="Referral information" />
            <Grid direction="column" spacing={1} container>
              {reduxProfileInfo.salesPersonId.value && (
                <Grid item>{reduxProfileInfo.salesPersonId.label || reduxProfileInfo.salesPersonId.value}</Grid>
              )}
              {reduxProfileInfo.promotion.value && (
                <Grid item>{reduxProfileInfo.promotion.label || reduxProfileInfo.promotion.value}</Grid>
              )}
            </Grid>
            <br />
            <br />
          </>
        )}
        {!!reduxUDFWithValue.length && (
          <Grid item>
            <FormBlockHeading title="Other information" />
            <Grid direction="column" spacing={1} container>
              {reduxUDFWithValue.map((field, index) => (
                <Grid key={`${index}${field.id}`} item>
                  {field.label}: {field.value}
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </FormBlock>
  )
}
