import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export const VisaIcon = () => {
  return (
    <SvgIcon
      fill="none"
      height={24}
      style={{ fontSize: '3.1875rem' }}
      viewBox="0 0 54 24"
      width={54}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.515 10.197c-.017 1.393 1.203 2.17 2.122 2.633.944.474 1.261.778 1.257 1.202-.007.649-.753.935-1.45.946-1.219.02-1.927-.339-2.49-.61l-.439 2.119c.565.268 1.611.503 2.696.513 2.546 0 4.211-1.297 4.22-3.309.01-2.552-3.42-2.693-3.397-3.834.008-.346.328-.715 1.029-.81.347-.047 1.304-.083 2.39.433l.426-2.05A6.319 6.319 0 0031.61 7c-2.397 0-4.082 1.315-4.096 3.197zm10.458-3.02c-.465 0-.856.28-1.031.71l-3.637 8.962h2.544l.506-1.444h3.11l.293 1.444H42l-1.957-9.672h-2.07zm.356 2.613l.734 3.632h-2.01l1.276-3.632zM24.431 7.177l-2.006 9.672h2.425l2.004-9.672h-2.423zm-3.587 0l-2.523 6.583-1.02-5.598c-.12-.625-.593-.985-1.118-.985h-4.125l-.058.28c.847.19 1.809.496 2.392.823.357.2.458.375.575.85l1.934 7.72h2.562l3.927-9.673h-2.546z" // NOSONAR
        fill="url(#prefix__paint0_linear)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="prefix__paint0_linear"
          x1={15.223}
          x2={39.306}
          y1={7.968}
          y2={7.017}
        >
          <stop stopColor="#222963" />
          <stop offset={1} stopColor="#254AA5" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
