import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import ReactSignatureCanvas from 'react-signature-canvas'
import Modal from 'src/components/Modal'
import { FormBlock } from './index'
import { useMediaDown } from '../helpers'

const getCanvasWidth = () => Math.round(Math.min(window.innerWidth * 0.8, 800))

interface ISignatureModalProps {
  handleClose: () => void
  guestName?: string
  setNewSignature: (signature: string) => void
}

const SignatureModal = ({ handleClose, guestName, setNewSignature }: ISignatureModalProps) => {
  const [signature, setSignature] = useState('')
  const sigPad = React.useRef<ReactSignatureCanvas>(null)
  const isMobile = useMediaDown('sm')

  const clearSignature = () => {
    setSignature(sigPad.current?.clear() || '')
  }

  const handleConfirm = () => {
    setNewSignature(signature)
    handleClose()
  }

  const handleSignatureDrawEnd = () => {
    const sig = sigPad.current?.toDataURL()
    if (sig) {
      setSignature(sig)
    }
  }
  const canvasWidth = getCanvasWidth()

  return (
    <Modal
      buttons={[{ text: 'Clear', action: clearSignature }]}
      confirmText="Submit"
      handleCancel={handleClose}
      handleConfirm={handleConfirm}
      title="Sign Agreement"
      open
    >
      <Box display="flex" justifyContent={isMobile ? '' : 'center'} width="100%">
        <Box maxWidth={900}>
          <FormBlock>
            <Grid item>
              <Typography variant="body1">
                I have read and agreed to the Terms & Conditions and Privacy Policy.
              </Typography>
              <Box py={3}>
                <Typography>SIGNATURE</Typography>
              </Box>
              <ReactSignatureCanvas
                ref={sigPad}
                backgroundColor="white"
                canvasProps={{
                  height: Math.round((canvasWidth * 205) / 800),
                  width: canvasWidth,
                }}
                dotSize={3}
                penColor="black"
                onEnd={handleSignatureDrawEnd}
              />
              <Typography>{guestName}</Typography>
            </Grid>
          </FormBlock>
        </Box>
      </Box>
    </Modal>
  )
}

export default SignatureModal
