import React from 'react'

import Typography from '@material-ui/core/Typography'

import { AUTH_TERMS, NOTES, PRIVACY_POLICY, TERMS_CONDITIONS } from 'src/common'
import { ModalOpener } from './'
import { TermsTextProps } from '../types'

const TermsText = ({ handleLinkClick, guestPass }: TermsTextProps) => {
  return guestPass ? (
    <Typography variant="body1">
      {'By checking “Yes I agree” above, you are confirming I have read the '}
      <ModalOpener text="ABC Privacy Policy" onClick={() => handleLinkClick(PRIVACY_POLICY)} />
      {' and agree to the '}
      <ModalOpener text="Terms and Conditions" onClick={() => handleLinkClick(TERMS_CONDITIONS)} />{' '}
      {' of this agreement. '}
    </Typography>
  ) : (
    <Typography variant="body1">
      {`By checking “Yes I agree” above, you are (1) consenting that ABC Fitness, our payment processor, will process your payment. 
      (2) confirming I have read the `}
      <ModalOpener text="ABC Privacy Policy" onClick={() => handleLinkClick(PRIVACY_POLICY)} />
      {' and agree to the '}
      <ModalOpener text="Terms and Conditions" onClick={() => handleLinkClick(TERMS_CONDITIONS)} />
      {' of this agreement. (3) confirming I have read and agree to the '}
      <ModalOpener text="Notes" onClick={() => handleLinkClick(NOTES)} />
      {' of this agreement. (4) and confirming I have read the '}
      <ModalOpener text="Authorization Terms" onClick={() => handleLinkClick(AUTH_TERMS)} />
      {' and give consent to store my credit card information for this agreement. '}
    </Typography>
  )
}

export default TermsText
