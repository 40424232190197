import makeStyles from '@material-ui/core/styles/makeStyles'

export const useCheckboxStyles = makeStyles({
  checkedCheckboxIcon: {
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'inherit',
    borderRadius: 3,
    fontSize: '1rem',
    padding: 1,
  },
  uncheckedCheckboxIcon: {
    height: 16,
    width: 16,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'inherit',
    borderRadius: 3,
    padding: 1,
  },
})
