import React, { useEffect, useState, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  DateInput,
  FormInput,
  FormBlock,
  FormBlockHeading,
  HeadingBox,
  RecurringPaymentInfo,
  TermsAndConditions,
  TwoButtonsTabSwitcher,
  CheckboxWithLabel,
  CCFormInput,
} from 'src/components'
import {
  isFutureDate,
  isValidAccountNumber,
  isValidCard,
  isValidCVV,
  isValidPostalCode,
  isValidRoutingNumber,
  isValidTransitNumber,
  isValidBankNumber,
  useQuery,
  getCountryLabel,
  scrollToElem,
  saveMemberDataToGymSales,
} from 'src/helpers'
import { gymSalesSteps, PaymentValues, SelectOption } from 'src/interfaces'
import { paymentSlice, stepperSlice } from 'src/reducers'
import { initialPaymentState } from 'src/reducers/states'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'
import {
  clubIdSelector,
  gymDetailsSelector,
  paymentStateSelector,
  planStateSelector,
  profileStateSelector,
  stepperSelector,
} from 'src/selectors'
import { PAGES, STEPS } from 'src/common'
import { HintTooltip } from './components'

function PaymentPage(): JSX.Element {
  const params = useQuery()
  const clubIpad: string | null = params.get('clubIPad')
  const [agreement, setAgreement] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const errorRef = React.useRef<HTMLParagraphElement>(null)
  const [nextIteration, setNextIteration] = useState(0)
  const [paymentType, setPaymentType] = useState('bank')
  const [paymentValues, setPaymentValues] = useState<PaymentValues>({
    ...initialPaymentState,
    recurringTransitNumber: '',
    recurringBankNumber: '',
  })
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const clubId = useSelector(clubIdSelector)
  const reduxStepper = useSelector(stepperSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const reduxProfileInfo = useSelector(profileStateSelector)
  const reduxPayment = useSelector(paymentStateSelector)
  const signatureSelector = reduxGymInfo.allowImageSignature
  const cashDownSelector = reduxGymInfo.allowCashDown
  const showSignature = signatureSelector === 'ALL' || (signatureSelector === 'CLB' && clubIpad === 'true')
  const showPayLater = cashDownSelector === 'ALL' || (cashDownSelector === 'CLB' && clubIpad === 'true')
  const showDueToday = !!reduxPlanInfo?.downPaymentTotalAmount && reduxPlanInfo.downPaymentTotalAmount !== '$0.00'
  const showRecurring =
    !!reduxPlanInfo?.scheduleTotalAmount &&
    reduxPlanInfo.scheduleTotalAmount !== '$0.00' &&
    !paymentValues.useForRecurring
  const clubCountryCode = reduxProfileInfo.country.value
  const { zipCodeLabel } = getCountryLabel(clubCountryCode)

  // downPaymentTotalAmount for due today visible
  // scheduleTotalAmount for recurring payment visible
  // firstDueDate for recurring payment date verbiage
  // scheduleFrequency for recurring payment verbiage
  // onlineSignupAllowedPaymentMethods (all, card, bank) for which recurring payment options show
  // preferredPaymentMethod for which is auto selected as recurring payment option
  // clubFeeTotalAmount for recurring payment fees verbiage
  useEffect(() => {
    window.scrollTo(0, 0)
    iframeDataExchange.emit({ pageChange: STEPS.payment.displayText })
  }, [])

  useEffect(() => {
    if (!reduxPlanInfo.planId) {
      history.push(PAGES.plan + location.search)
    }
  }, [reduxPlanInfo])

  useEffect(() => {
    if (reduxProfileInfo && reduxGymInfo) {
      saveMemberDataToGymSales({
        step: gymSalesSteps.step2,
        clubId: clubId || reduxGymInfo.number,
        ...reduxProfileInfo,
      })
    }
  }, [reduxProfileInfo, reduxGymInfo])

  useEffect(() => {
    if (reduxPlanInfo && reduxPayment) {
      if (
        !reduxPayment.recurringAccountNumber &&
        (reduxPlanInfo.preferredPaymentMethod === 'Credit Card' ||
          reduxPlanInfo.onlineSignupAllowedPaymentMethods === 'CARD' ||
          reduxPayment.recurringCCNumber)
      ) {
        setPaymentType('card')
        setPaymentValues({
          ...reduxPayment,
          todayCCNumber: '',
          todayCVV: '',
          recurringCCNumber: '',
          recurringRoutingNumber: '',
          recurringTransitNumber: '',
          recurringBankNumber: '',
          recurringAccountNumber: '',
          recurringAccountNumberRepeat: '',
          recurringAccountType: '',
          signature: '',
        })
      } else {
        setPaymentType('bank')
        setPaymentValues({
          ...reduxPayment,
          todayCCNumber: '',
          todayCVV: '',
          recurringCCNumber: '',
          recurringExpDate: '',
          signature: '',
        })
      }
    }
  }, [reduxPlanInfo, reduxPayment])

  useEffect(() => {
    scrollToElem(errorRef.current)
  }, [nextIteration])

  useEffect(() => {
    if (reduxStepper) {
      handleNext()
    }
  }, [reduxStepper])

  const isValidBillingNumbers = useMemo(() => {
    if (clubCountryCode === 'CA') {
      return (
        isValidTransitNumber(paymentValues.recurringTransitNumber) &&
        isValidBankNumber(paymentValues.recurringBankNumber)
      )
    }

    return paymentValues.recurringAccountType && isValidRoutingNumber(paymentValues.recurringRoutingNumber)
  }, [paymentValues, clubCountryCode])

  const handleNext = (e?: any) => {
    if (e) e.preventDefault()
    setIsSubmitted(true)
    setNextIteration(nextIteration + 1)

    let valid = true
    if (showDueToday && paymentValues.payLater === 'payNow') {
      if (
        !paymentValues.todayFirstName.trim() ||
        !paymentValues.todayLastName.trim() ||
        !isValidCard(paymentValues.todayCCNumber) ||
        !isFutureDate(paymentValues.todayExpDate) ||
        !isValidCVV(paymentValues.todayCVV, paymentValues.todayCCNumber) ||
        !paymentValues.todayZip.trim() ||
        !isValidPostalCode(paymentValues.todayZip)
      ) {
        valid = false
      }
    }

    if (showRecurring) {
      if (!paymentValues.recurringFirstName.trim() || !paymentValues.recurringLastName.trim()) {
        valid = false
      }

      if (paymentType === 'bank') {
        if (
          !isValidBillingNumbers ||
          !isValidAccountNumber(paymentValues.recurringAccountNumber) ||
          paymentValues.recurringAccountNumber !== paymentValues.recurringAccountNumberRepeat
        ) {
          valid = false
        }
      } else {
        if (!isValidCard(paymentValues.recurringCCNumber) || !isFutureDate(paymentValues.recurringExpDate)) {
          valid = false
        }
      }
    }

    if (!agreement) valid = false
    if (!paymentValues.signature && showSignature) valid = false

    if (valid) {
      const paymentToSave = { ...paymentValues }

      // if the country is Canada - we need to transform Bank and Transit recurring numbers to Routing number
      // and set recurring account type as 'checking'
      if (showRecurring && clubCountryCode === 'CA' && paymentType === 'bank') {
        paymentToSave.recurringRoutingNumber = `0${paymentValues.recurringTransitNumber}${paymentValues.recurringBankNumber}`
        paymentToSave.recurringAccountType = 'checking'
      }

      dispatch(paymentSlice.actions.setPayment(paymentToSave))
      history.push(PAGES.review + location.search)
    }
    dispatch(stepperSlice.actions.resetNext())
  }

  const handleChange = (prop: keyof PaymentValues) => (event: any) => {
    setPaymentValues({ ...paymentValues, [prop]: event.target.value })
  }

  const handleCheck = (event: any) => {
    setPaymentValues({ ...paymentValues, useForRecurring: event.target.checked })
    setPaymentType('card')
  }

  const togglePaymentType = (type: string) => {
    setPaymentType(type)
    setPaymentValues({
      ...paymentValues,
      recurringCCNumber: '',
      recurringExpDate: '',
      recurringRoutingNumber: '',
      recurringTransitNumber: '',
      recurringBankNumber: '',
      recurringAccountNumber: '',
      recurringAccountNumberRepeat: '',
      recurringAccountType: '',
    })
  }

  const togglePaymentTime = (type: string) => {
    setPaymentValues({
      ...paymentValues,
      todayFirstName: '',
      todayLastName: '',
      todayCCNumber: '',
      todayExpDate: '',
      todayCVV: '',
      todayZip: '',
      payLater: type,
      useForRecurring: false,
    })
  }

  const handleAgreementCheck = (checked: boolean) => {
    setAgreement(checked)
  }

  const handleSignatureSubmit = (signature: string) => {
    setPaymentValues({
      ...paymentValues,
      signature,
    })
  }

  type TFormFieldProps = Omit<PaymentValues, 'useForRecurring'>

  const formField = (
    propName: keyof TFormFieldProps,
    fieldName: string,
    maxLength?: number,
    isValid = true,
    type: 'text' | 'select' = 'text',
    options: SelectOption[] = [],
  ) => {
    const inputProps = {
      propName,
      fieldName,
      maxLength,
      nextIteration,
      value: paymentValues[propName],
      onChange: handleChange(propName),
      error: isSubmitted && (!isValid || !paymentValues[propName].trim()),
    }

    return ['todayCCNumber', 'recurringCCNumber'].includes(propName) ? (
      <CCFormInput {...inputProps} required />
    ) : (
      <FormInput {...inputProps} options={options} type={type} required />
    )
  }

  const dateField = (propName: 'todayExpDate' | 'recurringExpDate', fieldName: string) => {
    const value = paymentValues[propName]

    return (
      <DateInput
        error={isSubmitted && !isFutureDate(value)}
        fieldName={fieldName}
        formatValue="yyyy-MM"
        nextIteration={nextIteration}
        propName={propName}
        type="month"
        value={value}
        required
        onChange={handleChange(propName)}
      />
    )
  }

  return (
    <Container maxWidth="xl">
      <Grid direction="column" container>
        {showDueToday && (
          <>
            <HeadingBox title="Due Today">
              This one-time credit card payment is due today to help us get your membership started.
            </HeadingBox>
            <FormBlock>
              <Grid item>
                {showPayLater && (
                  <TwoButtonsTabSwitcher
                    active={paymentValues.payLater}
                    buttons={{ payNow: 'Pay now', payAtClub: 'Pay at club' }}
                    handleToggle={togglePaymentTime}
                  />
                )}
                {paymentValues.payLater === 'payNow' && (
                  <Box pt={3}>
                    <FormBlockHeading title="Credit card info" />
                    <Grid direction="row" spacing={3} container>
                      <Grid sm={6} xs={12} item>
                        {formField('todayFirstName', 'First name', 15)}
                      </Grid>
                      <Grid sm={6} xs={12} item>
                        {formField('todayLastName', 'Last name', 25)}
                      </Grid>
                      <Grid md={4} sm={6} xs={12} item>
                        {formField('todayCCNumber', 'Card number', 19, isValidCard(paymentValues.todayCCNumber))}
                      </Grid>
                      <Grid md={2} sm={6} xs={12} item>
                        {formField(
                          'todayCVV',
                          'CVV',
                          4,
                          isValidCVV(paymentValues.todayCVV, paymentValues.todayCCNumber),
                        )}
                      </Grid>
                      <Grid md={4} sm={6} xs={12} item>
                        {dateField('todayExpDate', 'Expiration date')}
                      </Grid>
                      <Grid md={2} sm={6} xs={12} item>
                        {formField('todayZip', zipCodeLabel, 10, isValidPostalCode(paymentValues.todayZip))}
                      </Grid>
                      {['ALL', 'CCD'].includes(reduxPlanInfo.onlineSignupAllowedPaymentMethods) && (
                        <Grid xs={12} item>
                          <CheckboxWithLabel
                            checkbox={{
                              name: 'paymentForRecurring',
                              checked: paymentValues.useForRecurring,
                              onChange: handleCheck,
                            }}
                            controlLabel={{
                              label: 'Use same information in the credit card option for Recurring payments',
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )}
              </Grid>
            </FormBlock>
          </>
        )}
        {showRecurring && (
          <>
            <HeadingBox title="Recurring Payment">
              <RecurringPaymentInfo />
            </HeadingBox>
            <FormBlock>
              <Grid item>
                <Box pb={3}>
                  <Grid direction="row" container>
                    <Grid sm={6} xs={12} item>
                      {reduxPlanInfo.onlineSignupAllowedPaymentMethods === 'ALL' && (
                        <TwoButtonsTabSwitcher
                          active={paymentType}
                          buttons={{ bank: 'Bank account', card: 'Credit card' }}
                          handleToggle={togglePaymentType}
                        />
                      )}
                    </Grid>
                    <Hidden xsDown>
                      <Grid sm={6} item>
                        <Box textAlign="right">
                          <Typography variant="body1">Payments occur</Typography>
                          <Typography variant="h4">{reduxPlanInfo.scheduleFrequency}</Typography>
                        </Box>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Box>
                <FormBlockHeading title={paymentType === 'bank' ? 'Bank account info' : 'Credit card info'}>
                  <Hidden smUp>
                    <Box textAlign="right">
                      <Typography variant="body2">Payments occur</Typography>
                      <Typography style={{ fontWeight: 500 }} variant="subtitle1">
                        {reduxPlanInfo.scheduleFrequency}
                      </Typography>
                    </Box>
                  </Hidden>
                </FormBlockHeading>
                <Grid direction="row" spacing={3} container>
                  <Grid sm={6} xs={12} item>
                    {formField('recurringFirstName', 'First name', 15)}
                  </Grid>
                  <Grid sm={6} xs={12} item>
                    {formField('recurringLastName', 'Last name', 25)}
                  </Grid>
                  {paymentType === 'bank' && (
                    <>
                      {clubCountryCode === 'CA' ? (
                        <>
                          <Grid sm={6} xs={12} item>
                            {formField(
                              'recurringTransitNumber',
                              'Transit number',
                              5,
                              isValidTransitNumber(paymentValues.recurringTransitNumber),
                            )}
                            <HintTooltip hintText="Where is my Transit Number?" />
                          </Grid>
                          <Grid sm={6} xs={12} item>
                            {formField(
                              'recurringBankNumber',
                              'Bank number',
                              3,
                              isValidBankNumber(paymentValues.recurringBankNumber),
                            )}
                            <HintTooltip hintText="Where is my Bank Number?" />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid sm={6} xs={12} item>
                            {formField(
                              'recurringRoutingNumber',
                              'Routing number',
                              9,
                              isValidRoutingNumber(paymentValues.recurringRoutingNumber),
                            )}
                          </Grid>
                          <Grid sm={6} xs={12} item>
                            {formField('recurringAccountType', 'Account type', undefined, true, 'select', [
                              { value: 'checking', label: 'Checking' },
                              { value: 'saving', label: 'Saving' },
                            ])}
                          </Grid>
                        </>
                      )}
                      <Grid sm={6} xs={12} item>
                        {formField(
                          'recurringAccountNumber',
                          'Account number',
                          17,
                          isValidAccountNumber(paymentValues.recurringAccountNumber),
                        )}
                      </Grid>
                      <Grid sm={6} xs={12} item>
                        {formField(
                          'recurringAccountNumberRepeat',
                          'Verify account number',
                          17,
                          paymentValues.recurringAccountNumber === paymentValues.recurringAccountNumberRepeat,
                        )}
                      </Grid>
                    </>
                  )}
                  {paymentType === 'card' && (
                    <>
                      <Grid sm={6} xs={12} item>
                        {formField(
                          'recurringCCNumber',
                          'Card number',
                          19,
                          isValidCard(paymentValues.recurringCCNumber),
                        )}
                      </Grid>
                      <Grid md={4} sm={6} xs={12} item>
                        {dateField('recurringExpDate', 'Expiration date')}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </FormBlock>
          </>
        )}
      </Grid>
      <HeadingBox title="Terms & Conditions" />
      <TermsAndConditions
        handleAgreementCheck={handleAgreementCheck}
        handleSignatureSubmit={handleSignatureSubmit}
        memberName={`${reduxProfileInfo.firstName.value} ${reduxProfileInfo.lastName.value}`}
        showSignature={showSignature}
      />
      {isSubmitted && !agreement && (
        <FormHelperText ref={errorRef} error>
          Accepting the Terms and Conditions is required
        </FormHelperText>
      )}
      {isSubmitted && !paymentValues.signature && showSignature && (
        <FormHelperText ref={errorRef} error>
          Signature is required
        </FormHelperText>
      )}
    </Container>
  )
}

export default PaymentPage
