import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export const useMediaUp = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.up(breakpoint))
}

export const useMediaDown = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.down(breakpoint))
}

export const useMediaOnly = (breakpoint: 'xs' | 'sm' | 'md' | 'lg' | 'xl') => {
  const theme = useTheme()
  return useMediaQuery(theme.breakpoints.only(breakpoint))
}
