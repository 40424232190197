import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormBlock, FormBlockHeading, RecurringPaymentInfo } from 'src/components'
import { EditButton } from './'
import { disguiseCardNumber, useMediaDown } from 'src/helpers'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { paymentStateSelector } from 'src/selectors'

const CardTextLine = ({ children }: { children: React.ReactNode }) => (
  <Box pt={0.5}>
    <Typography>{children}</Typography>
  </Box>
)

interface ICardBlockProps {
  title?: string
  firstName: string
  lastName: string
  cardNumber?: string
  expDate?: string
}

const CardBlock = ({ title, firstName, lastName, cardNumber, expDate }: ICardBlockProps) => (
  <Box>
    {title && <Typography style={{ fontWeight: 500 }}>{title}</Typography>}
    <CardTextLine>
      {firstName} {lastName}
    </CardTextLine>
    {cardNumber && (
      <>
        <CardTextLine>{disguiseCardNumber(cardNumber)}</CardTextLine>
        <CardTextLine>exp. {expDate}</CardTextLine>
      </>
    )}
  </Box>
)

const PaymentInfoBlock = ({ onEdit }: { onEdit: () => void }): JSX.Element => {
  const reduxPayment = useSelector(paymentStateSelector)
  const isMobile = useMediaDown('sm')

  return (
    <FormBlock>
      <Grid item>
        <FormBlockHeading title="Payment information" />
        <EditButton onClick={onEdit} />
        <Box pb={2}>
          <RecurringPaymentInfo />
        </Box>
        <Grid spacing={isMobile ? 2 : 3} container>
          {reduxPayment.todayCCNumber && (
            <Grid sm={6} xs={12} item>
              <CardBlock
                cardNumber={reduxPayment.todayCCNumber}
                expDate={reduxPayment.todayExpDate}
                firstName={reduxPayment.todayFirstName}
                lastName={reduxPayment.todayLastName}
                title="Due Today"
              />
            </Grid>
          )}
          {(reduxPayment.recurringCCNumber || reduxPayment.recurringAccountNumber || reduxPayment.useForRecurring) && (
            <Grid sm={6} xs={12} item>
              <Grid direction="column" spacing={1} container>
                <CardBlock
                  firstName={reduxPayment.recurringFirstName}
                  lastName={reduxPayment.recurringLastName}
                  title="Recurring Payment"
                />
                {reduxPayment.recurringCCNumber && (
                  <>
                    <CardTextLine>{disguiseCardNumber(reduxPayment.recurringCCNumber)}</CardTextLine>
                    <CardTextLine>exp. {reduxPayment.recurringExpDate}</CardTextLine>
                  </>
                )}
                {reduxPayment.recurringAccountNumber && (
                  <>
                    <CardTextLine>routing {disguiseCardNumber(reduxPayment.recurringRoutingNumber)}</CardTextLine>
                    <CardTextLine>
                      {reduxPayment.recurringAccountType} {disguiseCardNumber(reduxPayment.recurringAccountNumber)}
                    </CardTextLine>
                  </>
                )}
                {reduxPayment.useForRecurring && (
                  <CardBlock
                    cardNumber={reduxPayment.todayCCNumber}
                    expDate={reduxPayment.todayExpDate}
                    firstName={reduxPayment.todayFirstName}
                    lastName={reduxPayment.todayLastName}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </FormBlock>
  )
}

export default PaymentInfoBlock
