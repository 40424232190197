import axios from 'axios'
import * as gymService from './gym'
import * as gymSalesService from './gym-sales'
import * as locations from './location'
import { CookieJar } from 'tough-cookie';

const cookieJar = new CookieJar();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ABC_FINANCIAL_URL,
  withCredentials: true,
  // @ts-ignore
  jar: cookieJar
})

const abcAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ABC_FINANCIAL_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Referrer-Policy': 'no-strict-origin-when-cross-origin',
  },
  withCredentials: true,
  // @ts-ignore
  jar: cookieJar
})

const gymSalesRequestHeadings: { [key: string]: string } = {
  'Content-Type': 'application/json',
  'Referrer-Policy': 'no-referrer',
  Accept: 'application/json',
}

if (process.env.REACT_APP_ENV !== 'production') {
  gymSalesRequestHeadings.Authorization = 'Basic b25saW5lam9pbkBhYmNmaXRuZXNzLmNvbTpddVk5KSRiJDd2fVsmI0JX'
}

const gymSalesAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GYM_SALES_URL,
  headers: gymSalesRequestHeadings,
})

export { gymService, gymSalesService, locations, abcAxiosInstance, gymSalesAxiosInstance, axiosInstance }
