import React, { useEffect, useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Link from '@material-ui/core/Link'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Close from '@material-ui/icons/Close'
import { appIsUsedInIframe, useMediaDown } from 'src/helpers'
import makeStyles from '@material-ui/core/styles/makeStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'
import './Modal.scss'

type TModalButton = {
  text: string
  action: (event: any) => void
}

interface ModalProps {
  open: boolean
  name?: string
  title?: string
  buttons?: TModalButton[]
  subHeader?: string
  children: React.ReactNode
  cancelText?: string
  handleCancel?: (event: any) => void
  confirmText?: string
  handleConfirm?: (event: any) => void
}

const useStyles = makeStyles((theme) =>
  createStyles({
    modalFooter: {
      padding: '30px 40px',

      '& button': {
        height: 60,
        width: 150,
        fontSize: '18px',
        lineHeight: '60px',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
      },

      '&.mobile': {
        justifyContent: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        '& button': {
          margin: `0 ${theme.spacing(1)}px`,
        },
      },
    },
  }),
)

function Modal(props: ModalProps) {
  const isMobile = useMediaDown('sm')
  const classes = useStyles()
  const appIsInIframe = appIsUsedInIframe()
  const additionalButtons = useMemo(() => {
    return props.buttons?.map(({ text, action }, index) => (
      <Button key={text + index} color="secondary" variant="outlined" onClick={action}>
        {text}
      </Button>
    ))
  }, [props.buttons])

  useEffect(() => {
    if (props.open) {
      iframeDataExchange.emit({ modalOpened: props.name || props.title })
    }
  }, [props.open])

  const showButtonsPanel =
    !!additionalButtons?.length ||
    (props.handleCancel && props.cancelText) ||
    (props.handleConfirm && props.confirmText)

  return (
    <Dialog fullScreen={isMobile && !appIsInIframe} maxWidth="lg" open={props.open} fullWidth>
      <Box display="flex" flex={1} flexDirection="column" style={{ color: '#000' }}>
        {props.title && (
          <MuiDialogTitle disableTypography>
            <Box pl={[0, 0, 4]} pr={4} pt={2}>
              <Typography
                align={isMobile ? 'left' : 'center'}
                style={{ fontWeight: 500 }}
                variant={isMobile ? 'h4' : 'h1'}
              >
                {props.title}
              </Typography>
            </Box>
            {props.subHeader && (
              <Box pb={1} pt={1}>
                <Typography align={isMobile ? 'left' : 'center'} variant="body1">
                  {props.subHeader}
                </Typography>
              </Box>
            )}
          </MuiDialogTitle>
        )}
        <MuiDialogContent className="modal-body">
          {props.children}
          <Box mr={2} mt={3} position="absolute" right={0} top={0}>
            {props.handleCancel && (
              <Link color="secondary" style={{ cursor: 'pointer' }} onClick={props.handleCancel}>
                <Close />
              </Link>
            )}
          </Box>
        </MuiDialogContent>
        {showButtonsPanel && (
          <MuiDialogActions className={`${classes.modalFooter} ${isMobile && 'mobile'}`}>
            {additionalButtons}
            {props.handleCancel && props.cancelText && (
              <Button color="secondary" variant="outlined" onClick={props.handleCancel}>
                {props.cancelText}
              </Button>
            )}
            {props.handleConfirm && props.confirmText && (
              <Button color="secondary" variant="contained" autoFocus onClick={props.handleConfirm}>
                {props.confirmText}
              </Button>
            )}
          </MuiDialogActions>
        )}
      </Box>
    </Dialog>
  )
}

export default Modal
