interface IBlockPositionData {
  top: number
  height: number
}

interface IMessageData {
  appHeight?: number
  pageChange?: string
  modalOpened?: string
  datepickerOpened?: string
  scrollTo?: IBlockPositionData
}

let messagePortal: MessageEventSource

export const init = (iframeSource: MessageEventSource) => {
  if ('postMessage' in iframeSource) {
    messagePortal = iframeSource
  }
}

export const emit = (messageData: IMessageData) => {
  if (!messagePortal) return

  // @ts-ignore
  messagePortal.postMessage(messageData, '*')
}
