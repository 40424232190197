import React, { useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { AccordionHeading, FeeLine } from './'
import { getCardType } from 'src/helpers'
import { useFeesDetails } from 'src/hooks'
import { useSelector } from 'react-redux'
import { ClubFee, DownPayment, Schedule } from 'src/interfaces'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { gymDetailsSelector, paymentStateSelector, planStateSelector } from '../../../selectors'

const FeePaymentsNumberText = ({ amount }: { amount: string }) => {
  const text = Number(amount) === 1 ? 'payment' : 'payments'
  return (
    <Typography className="disclaimer" variant="body2">
      {`${amount} ${text}`}
    </Typography>
  )
}

const useStyles = makeStyles({
  root: {
    margin: 0,
    border: '1px solid #ddd',
    boxShadow: '0 0 black',
    padding: 8,
    background: 'rgba(219, 219, 219, 0.15)',
    '&:first-child': {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
    },
    '&:last-child': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  disclaimer: {
    fontSize: '12px',
    color: '#625e68',
  },
  billing: {
    paddingTop: 10,
    color: '#625e68',
    '& strong': {
      textTransform: 'lowercase',
    },
  },
})

const OfferDetails = (): JSX.Element => {
  const reduxPayment = useSelector(paymentStateSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const [showAllAccordion, setShowAllAccordion] = useState(false)
  const { clubFeesList, firstDueDate, schedulesAdditional, schedulesDefault } = useFeesDetails()
  const classes = useStyles()
  const accordionClasses = useMemo(() => ({ root: classes.root, expanded: classes.expanded }), [classes])

  const toggleAccordion = () => {
    setShowAllAccordion(!showAllAccordion)
  }

  const paymentTypeInfo = useMemo(() => {
    let cardNumber = reduxPayment.recurringAccountNumber
    let text = 'bank account'

    if (reduxPayment.useForRecurring) {
      text = getCardType(reduxPayment.todayCCNumber)
      cardNumber = reduxPayment.todayCCNumber
    } else if (reduxPayment.recurringCCNumber) {
      text = getCardType(reduxPayment.recurringCCNumber)
      cardNumber = reduxPayment.recurringCCNumber
    }
    return {
      cardNumberCropped: cardNumber.slice(-4),
      text: `${text} ending in `,
    }
  }, [reduxPayment])

  return (
    <>
      <Box pb={1}>
        <Typography variant="h6">Offer Details</Typography>
      </Box>
      <Box
        mb={1}
        px={3}
        py={2}
        style={{
          borderRadius: 6,
          border: 'solid 1px #ddd',
          color: '#fff',
          backgroundColor: '#2f2d36',
        }}
      >
        <Typography style={{ fontStyle: 'italic', fontWeight: 500 }} variant="body1">
          Your Plan
        </Typography>
        <Typography style={{ fontWeight: 400 }} variant="subtitle2" paragraph>
          {reduxPlanInfo.planName}
        </Typography>
        <Typography style={{ fontStyle: 'italic', fontWeight: 500 }} variant="body1">
          Club Address
        </Typography>
        <Typography variant="body2">{reduxGymInfo.address1}</Typography>
        <Typography variant="body2">
          {reduxGymInfo.city}, {reduxGymInfo.state} {reduxGymInfo.zip}
        </Typography>
        <Typography variant="body2">{reduxGymInfo.phone}</Typography>
      </Box>
      <Box pb={2}>
        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" style={{ fontSize: '18px', letterSpacing: 'normal' }} onClick={toggleAccordion}>
            {showAllAccordion ? 'Hide All' : 'Show All'}
          </Button>
        </Box>
        <Box>
          <Accordion classes={accordionClasses} expanded={showAllAccordion}>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
              <AccordionHeading amount={reduxPlanInfo.downPaymentTotalAmount} title="Due Today" />
            </AccordionSummary>
            {reduxPlanInfo.downPayments.length > 0 && (
              <AccordionDetails>
                <Grid spacing={2} container>
                  {reduxPlanInfo.downPayments.map((payment: DownPayment) => (
                    <Grid key={payment.name} xs={12} item>
                      <Grid container>
                        <FeeLine amount={payment.total} color="#625e68" name={payment.name} />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item>
                    <Typography className={classes.billing} variant="body1">
                      <strong>{reduxPlanInfo.downPaymentTotalAmount}</strong> will be paid{' '}
                      {reduxPayment.payLater === 'payAtClub' && <>at the club.</>}
                      {reduxPayment.payLater !== 'payAtClub' && (
                        <>
                          today using your {getCardType(reduxPayment.todayCCNumber)} card ending in{' '}
                          <strong>{reduxPayment.todayCCNumber.slice(-4)}</strong>
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            )}
          </Accordion>
          <Accordion classes={accordionClasses} expanded={showAllAccordion}>
            <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
              <AccordionHeading
                amount={reduxPlanInfo.scheduleTotalAmount}
                title={`${reduxPlanInfo.scheduleFrequency || 'Monthly'} Dues`}
              />
            </AccordionSummary>
            {reduxPlanInfo.schedules.length > 0 && (
              <AccordionDetails>
                <Grid spacing={2} container>
                  {schedulesDefault.map((schedule: Schedule) => (
                    <Grid key={schedule.profitCenter} xs={12} item>
                      <Grid container>
                        <FeeLine amount={schedule.scheduleAmount} color="#625e68" name={schedule.profitCenter} />
                        <Grid xs={12} item>
                          {!schedule.recurring && <FeePaymentsNumberText amount={schedule.numberOfPayments} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid xs={12} item>
                    <Typography className={classes.disclaimer} variant="body2">
                      (includes applicable taxes)
                    </Typography>
                    <Typography className={classes.disclaimer} variant="body2">
                      * Recurring dues may change
                    </Typography>
                  </Grid>
                  {schedulesAdditional.map((schedule: Schedule) => (
                    <Grid key={schedule.profitCenter} xs={12} item>
                      <Grid direction="row" container>
                        <FeeLine amount={schedule.scheduleAmount} color="#625e68" name={schedule.profitCenter} />
                        <Grid item>
                          {!schedule.recurring && <FeePaymentsNumberText amount={schedule.numberOfPayments} />}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item>
                    <Typography className={classes.billing} variant="body1">
                      <strong>{reduxPlanInfo.scheduleTotalAmount}</strong> will be drafted{' '}
                      <strong>{reduxPlanInfo.scheduleFrequency}</strong> using your {paymentTypeInfo.text}
                      <strong>{paymentTypeInfo.cardNumberCropped}</strong>
                    </Typography>
                  </Grid>
                  {firstDueDate && (
                    <Grid item>
                      <Typography className={classes.disclaimer} variant="body2">
                        {`Your first due date is ${firstDueDate}`}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            )}
          </Accordion>
          <Accordion classes={accordionClasses} expanded={showAllAccordion}>
            <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
              <AccordionHeading amount={reduxPlanInfo.clubFeeTotalAmount} title="Upcoming Fees" />
            </AccordionSummary>
            {clubFeesList.length > 0 && (
              <AccordionDetails>
                <Grid spacing={2} container>
                  {clubFeesList.map((fee: ClubFee) => (
                    <Grid key={fee.feeName} xs={12} item>
                      <Grid container>
                        <FeeLine amount={fee.feeAmount} color="#625e68" name={fee.feeName} />
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item>
                    <Typography className={classes.disclaimer} variant="body1">
                      (includes applicable taxes)
                    </Typography>
                    <Typography className={classes.disclaimer} variant="body1">
                      * Recurring dues may change
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.billing} variant="body1">
                      We will bill you using your {paymentTypeInfo.text}
                      <strong>{paymentTypeInfo.cardNumberCropped}</strong>.
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            )}
          </Accordion>
        </Box>
      </Box>
    </>
  )
}

export default OfferDetails
