import React from 'react'
import Grid from '@material-ui/core/Grid'
import { FormBlock, FormBlockHeading } from 'src/components'
import { EditButton } from './index'
import { formatPhoneNumber } from 'src/helpers'
import { useSelector } from 'react-redux'
import { profileStateSelector } from 'src/selectors'

export const ContactInfoBlock = ({ onEdit }: { onEdit: () => void }): JSX.Element => {
  const reduxProfileInfo = useSelector(profileStateSelector)

  return (
    <FormBlock>
      <Grid item>
        <FormBlockHeading title="Contact information" />
        <EditButton onClick={onEdit} />
        <Grid direction="column" spacing={1} container>
          <Grid item>
            {`${reduxProfileInfo.firstName.value} ${reduxProfileInfo.middleInitial.value} ${reduxProfileInfo.lastName.value}`}
          </Grid>
          <Grid item>{reduxProfileInfo.email.value}</Grid>
          <Grid item>{formatPhoneNumber(reduxProfileInfo.cellNumber.value)}</Grid>
          {reduxProfileInfo.homeNumber.value && (
            <Grid item>{formatPhoneNumber(reduxProfileInfo.homeNumber.value)}</Grid>
          )}
        </Grid>
      </Grid>
    </FormBlock>
  )
}
