import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  popper: {
    maxWidth: '90%',
    position: 'fixed',
    left: 0,
    top: 0,
  },
  tooltip: {
    maxWidth: 'none',
    padding: 0,
    marginTop: 5,
    background: 'none',

    '& img': {
      maxWidth: '100%',
      display: 'block',
    },
  },
  hint: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  hintIcon: {
    margin: 5,
  },
})
