import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { useTheme } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { APP_MODIFIER_PARAMS, STEPS } from 'src/common'
import { useMediaDown, useMediaOnly, useQuery } from 'src/helpers'
import { gymDetailsSelector, locationsSelector } from 'src/selectors'
import './Header.scss'
import { ClubLocationsSelect } from './ClubLocationsSelect/ClubLocationsSelect'

interface IStep {
  index: number
  path: string
  displayText: string
  activeStepNumber: number
}

interface HeaderProps {
  guestMode: boolean
}

const Step = ({ index, path, displayText, activeStepNumber }: IStep) => {
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const params = useQuery()
  const fromClub = params.get(APP_MODIFIER_PARAMS.fromClub) !== null
  const isDisabled = index > activeStepNumber
  const reduxGymInfo = useSelector(gymDetailsSelector)

  const handleRedirect = () => {
    if (fromClub && path === STEPS.plan.path) {
      window.location.href = reduxGymInfo.websiteUrl
      return
    }

    history.push(path + location.search)
  }

  return (
    <Button className="step" color="primary" disabled={isDisabled} variant="contained" onClick={handleRedirect}>
      <div className={isDisabled ? 'light_filter' : 'dark_filter'} />
      <Typography style={{ color: theme.palette.primary.contrastText }} variant="subtitle2">
        {displayText}
      </Typography>
    </Button>
  )
}

type TMobileStepProps = {
  activeStep: number
}

const MobileStep = ({ activeStep }: TMobileStepProps) => {
  const params = useQuery()
  const fromClub = params.get(APP_MODIFIER_PARAMS.fromClub) !== null
  const steps = Object.values(STEPS)
  const activeStepData = steps.find(({ index }) => index === activeStep)
  const activeStepText = activeStepData?.displayText || ''
  const prevStepPath = steps.find(({ index }) => index === activeStep - 1)?.path
  const reduxGymInfo = useSelector(gymDetailsSelector)

  const history = useHistory()
  const location = useLocation()

  const handleRedirect = () => {
    if (fromClub && prevStepPath === STEPS.plan.path) {
      window.location.href = reduxGymInfo.websiteUrl
      return
    }
    history.push(prevStepPath + location.search)
  }

  return prevStepPath ? (
    <Link
      className="MuiAppBar-colorPrimary"
      style={{ textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }}
      underline="none"
      variant="subtitle2"
      onClick={handleRedirect}
    >
      <Box alignItems="center" component="span" display="flex">
        <ChevronLeftIcon fontSize="large" />
        {activeStepText}
      </Box>
    </Link>
  ) : (
    <Typography style={{ textTransform: 'uppercase', fontWeight: 'bold' }} variant="subtitle2">
      {activeStepText}
    </Typography>
  )
}

function Header({ guestMode = false }: HeaderProps) {
  const [activeStep, setActiveStep] = useState(0)
  const location = useLocation()
  const params = useQuery()
  const theme = useTheme()
  const isMobile = useMediaDown('sm')
  const isTablet = useMediaOnly('md')
  const hideTopPanel = params.get(APP_MODIFIER_PARAMS.withoutHeader) !== null

  const reduxGymInfo = useSelector(gymDetailsSelector)
  const clubLocations = useSelector(locationsSelector)
  const stepsArr = Object.values(STEPS)

  useEffect(() => {
    const stepNumber = stepsArr.find(({ path }) => path === location.pathname)?.index
    setActiveStep(stepNumber || 0)
  }, [location])

  return (
    <AppBar className="top-header" position={isMobile ? 'static' : 'sticky'}>
      <Container className="top-header-container MuiAppBar-colorPrimary">
        <Grid direction="column" container>
          {!hideTopPanel && (
            <Grid item>
              {reduxGymInfo && (
                <Grid
                  alignItems="center"
                  className="top-header-grid"
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    height: 100,
                    paddingLeft: theme.spacing(isMobile ? 4 : 5.25),
                    paddingRight: theme.spacing(isMobile ? 4 : 3),
                  }}
                  container
                >
                  <Grid item>
                    <a href={reduxGymInfo.websiteUrl}>
                      <img src={reduxGymInfo.logoUrl} style={{ maxHeight: isMobile ? 40 : 80 }} />
                    </a>
                  </Grid>
                  <Hidden smDown>
                    <Grid className="back-to-club" item>
                      <a href={reduxGymInfo.websiteUrl}>
                        <Typography variant="subtitle2">
                          <ChevronLeftIcon />
                          Back to Club
                        </Typography>
                      </a>
                    </Grid>
                  </Hidden>
                  <Hidden mdUp>
                    <Grid item>
                      <MobileStep activeStep={activeStep} />
                    </Grid>
                  </Hidden>
                </Grid>
              )}
            </Grid>
          )}
          <Grid item>
            <Grid alignItems="center" className="panel" justifyContent="space-between" container>
              {!guestMode && (
                <Hidden smDown>
                  <Grid item>
                    <Grid className={`stepper ${isTablet && 'tablet'}`} container>
                      {stepsArr.map((step) => (
                        <Step {...step} key={step.index} activeStepNumber={activeStep} />
                      ))}
                    </Grid>
                  </Grid>
                </Hidden>
              )}
              <Grid className="club-address" color="primary" style={{ flex: 1 }} item>
                <Box
                  alignItems="center"
                  display="flex"
                  height={guestMode ? 92 : 70}
                  justifyContent={['none', 'none', 'flex-end']}
                  pl={[2, 3, 1]}
                  pr={[2, 3, 4]}
                >
                  {reduxGymInfo && (
                    <>
                      {guestMode && clubLocations && reduxGymInfo?.number && (
                        <ClubLocationsSelect
                          clubLocations={clubLocations}
                          defaultClubNumber={reduxGymInfo.number.toString()}
                        />
                      )}
                      {!guestMode && (
                        <Typography style={{ letterSpacing: 0 }} variant="subtitle2">
                          <LocationOnIcon />
                          {reduxGymInfo.name} - {reduxGymInfo.address1}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

export default Header
