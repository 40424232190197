import React, { useEffect, useMemo, useState } from 'react'
import { FeeLine, FeePaymentsNumberText } from './components'
import { getPaymentDate, useMediaUp } from 'src/helpers'
import { useSelector } from 'react-redux'
import { useFeesDetails } from 'src/hooks'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTheme } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useLocation } from 'react-router-dom'
import { ClubFee, DownPayment, Schedule } from 'src/interfaces'
import { gymDetailsSelector, planStateSelector } from 'src/selectors'

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    alignSelf: 'center',
    margin: 0,
    '&:last-child': {
      borderBottomLeftRadius: 6,
      borderBottomRightRadius: 6,
    },
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  disclaimer: {
    fontSize: '12px',
    color: theme.palette.primary.contrastText,
  },
  billing: {
    paddingTop: 10,
    color: theme.palette.primary.contrastText,
  },
  feeCard: {
    flex: 1,
    border: '1px solid #ddd',
    boxShadow: '0 0 black',
    borderRadius: '8px',
    margin: '8px 16px 0 16px',
    padding: '16px',
  },
  feeCardMobile: {
    marginBottom: '16px',
  },
  feeLine: {
    fontSize: '14px',
    paddingTop: 10,
    color: theme.palette.primary.contrastText,
  },
  feeButton: {
    fontSize: '18px',
    letterSpacing: 'normal',
    alignSelf: 'center',
    borderRadius: '80px 80px 0 0',
    height: '30px',
  },
}))

export const FooterFeePanel = (): JSX.Element => {
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const [showAccordion, setShowAccordion] = useState(false)
  const { clubFeesList, firstDueDate, schedulesAdditional, schedulesDefault } = useFeesDetails()
  const classes = useStyles()
  const theme = useTheme()
  const accordionClasses = useMemo(() => ({ root: classes.root, expanded: classes.expanded }), [classes])
  const isDesktop = useMediaUp('md')
  const location = useLocation()

  const toggleAccordion = () => {
    setShowAccordion(!showAccordion)
  }

  useEffect(() => {
    showAccordion && toggleAccordion()
  }, [location])

  return (
    <Box display="flex" flexDirection="column">
      <Button className={classes.feeButton} color="primary" variant="contained" onClick={toggleAccordion}>
        {showAccordion ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </Button>
      <Accordion
        classes={accordionClasses}
        expanded={showAccordion}
        style={{
          width: isDesktop ? '' : '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '955px',
          maxHeight: isDesktop ? '58vh' : '45vh',
          overflowY: 'auto',
          backgroundColor: theme.palette.primary.main,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" style={{ display: 'none' }} />
        {reduxPlanInfo.downPayments.length > 0 && (
          <AccordionDetails>
            <Box display="flex" flexDirection={isDesktop ? 'row' : 'column'} justifyContent="center" width="100%">
              <Box className={`${classes.feeCard} ${!isDesktop ? classes.feeCardMobile : ''}`}>
                {reduxPlanInfo.downPayments.map((fee: DownPayment) => (
                  <FeeLine
                    key={fee.name}
                    amount={fee.total}
                    color={theme.palette.primary.contrastText}
                    name={fee.name}
                  />
                ))}
              </Box>
              {reduxPlanInfo.schedules.length > 0 && (
                <Box className={`${classes.feeCard} ${!isDesktop ? classes.feeCardMobile : ''}`}>
                  {schedulesDefault.map((schedule: Schedule) => (
                    <Box key={schedule.profitCenter}>
                      <FeeLine
                        amount={schedule.scheduleAmount}
                        color={theme.palette.primary.contrastText}
                        name={schedule.profitCenter}
                      />
                      {!schedule.recurring && (
                        <FeePaymentsNumberText amount={schedule.numberOfPayments} className={classes.disclaimer} />
                      )}
                    </Box>
                  ))}
                  <Typography className={classes.disclaimer} variant="body2">
                    (includes applicable taxes)
                  </Typography>
                  {schedulesAdditional.map((schedule: Schedule) => (
                    <Box key={schedule.profitCenter}>
                      <FeeLine
                        amount={schedule.scheduleAmount}
                        color={theme.palette.primary.contrastText}
                        name={schedule.profitCenter}
                      />
                      {!schedule.recurring && (
                        <FeePaymentsNumberText amount={schedule.numberOfPayments} className={classes.disclaimer} />
                      )}
                    </Box>
                  ))}
                  {firstDueDate && (
                    <Box mt={0.5}>
                      <Typography className={classes.disclaimer} variant="body2">
                        {`Your first due date is ${firstDueDate}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
              {clubFeesList.length > 0 && (
                <Box className={`${classes.feeCard} ${!isDesktop ? classes.feeCardMobile : ''}`}>
                  {clubFeesList.length > 1 ? (
                    <>
                      <FeeLine
                        amount={reduxPlanInfo.clubFeeTotalAmount}
                        color={theme.palette.primary.contrastText}
                        name="Upcoming fees"
                      />
                      {clubFeesList.map((fee: ClubFee) => (
                        <React.Fragment key={fee.feeName}>
                          <FeeLine
                            amount={fee.feeAmount}
                            color={theme.palette.primary.contrastText}
                            name={fee.feeName}
                            textClassName={classes.feeLine}
                          />
                          <Typography className={classes.disclaimer} variant="body1">
                            (includes applicable taxes)
                          </Typography>
                          <Typography className={classes.feeLine} variant="body1">
                            The {fee.feeName.toLowerCase()} will be billed on
                            <strong> {getPaymentDate(reduxGymInfo, reduxPlanInfo, fee.feeDueDate)}</strong>
                            {fee.feeRecurring ? ' and on the same day each year thereafter' : ''}.
                          </Typography>
                        </React.Fragment>
                      ))}
                    </>
                  ) : (
                    clubFeesList.map((fee: ClubFee) => (
                      <React.Fragment key={fee.feeName}>
                        <FeeLine amount={fee.feeAmount} color={theme.palette.primary.contrastText} name={fee.feeName} />
                        <Typography className={classes.disclaimer} variant="body1">
                          (includes applicable taxes)
                        </Typography>
                        <Typography className={classes.billing} variant="body1">
                          The {fee.feeName.toLowerCase()} will be billed on
                          <strong> {getPaymentDate(reduxGymInfo, reduxPlanInfo, fee.feeDueDate)}</strong>
                          {fee.feeRecurring ? ' and on the same day each year thereafter' : ''}.
                        </Typography>
                      </React.Fragment>
                    ))
                  )}
                </Box>
              )}
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  )
}

export default FooterFeePanel
