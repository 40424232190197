import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { RootState } from 'src/configureStore'
import { gymSalesSteps, MemberAgreement, VerifiedAgreement, VerifyAgreement } from 'src/interfaces'
import { HeadingBox } from 'src/components'
import { saveMemberDataToGymSales, scrollToElem, transformAgreement, useMediaDown, useQuery } from 'src/helpers'
import { agreementSlice, loadingSlice, planInformationSlice } from 'src/reducers'
import { gymService } from 'src/services'
import {
  AdditionalInfoBlock,
  PaymentInfoBlock,
  PersonalInfoBlock,
  RegistrationModal,
  ContactInfoBlock,
  SecondaryMemberBlock,
  OfferDetails,
} from './components'
import {
  clubIdSelector,
  gymDetailsSelector,
  paymentStateSelector,
  planStateSelector,
  profileStateSelector,
} from 'src/selectors'
import { PAGES, STEPS } from 'src/common'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'

interface IServerErrorsArr {
  abcCode: string
  errorMessage: string
}

function ReviewPage(): JSX.Element {
  const params = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const clubId = useSelector(clubIdSelector)
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)
  const reduxProfileInfo = useSelector(profileStateSelector)
  const reduxPayment = useSelector(paymentStateSelector)
  const reduxUDFs = useSelector((state: RootState) => state.profileUDFSlice)

  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const [nextIteration, setNextIteration] = useState(0)
  const errorRef = React.useRef<HTMLDivElement>(null)
  const [conversionTrackers, setConversionTrackers] = useState<string[]>([])
  const [showRegistrationModal, setShowRegistrationModal] = useState(false)
  const [displayQueueMessageError, setDisplayQueueMessageError] = useState(false)
  const [verifiedAgreement, setVerifiedAgreement] = useState<VerifiedAgreement>()
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
  const isMobile = useMediaDown('sm')

  useEffect(() => {
    window.scrollTo(0, 0)
    iframeDataExchange.emit({ pageChange: STEPS.review.displayText })
  }, [])

  useEffect(() => {
    const init = async () => {
      if (!reduxPlanInfo.planId) {
        history.push(PAGES.plan + location.search)
        return
      }

      const conversionTrackerResponse = await gymService.getConversionTrackers(params.get('club') || '')

      if (conversionTrackerResponse?.data?.length) {
        const trackers: string[] = []
        for (const tracker of conversionTrackerResponse.data) {
          if (tracker.url) {
            let conversionUrl = tracker.url
            let conversionValue = ''

            switch (tracker.valueType) {
              case 'DP':
                conversionValue = reduxPlanInfo.downPaymentTotalAmount.replace('$', '')
                break
              case 'MP':
                conversionValue = reduxPlanInfo.scheduleTotalAmount.replace('$', '')
                break
              case 'TCV':
                conversionValue = reduxPlanInfo.totalContractValue.replace('$', '')
                break
            }

            conversionUrl = conversionUrl.replace(/conversiontrackingvalue/i, conversionValue)
            trackers.push(conversionUrl)
          }
        }
        setConversionTrackers(trackers)
      }
    }

    init()
  }, [reduxPlanInfo])

  useEffect(() => {
    scrollToElem(errorRef.current)
  }, [nextIteration])

  const handleSubmitAgreement = () => {
    setNextIteration(nextIteration + 1)
    ;(async () => {
      const agreement: MemberAgreement = transformAgreement(
        reduxGymInfo,
        reduxPlanInfo,
        reduxProfileInfo,
        reduxUDFs,
        reduxPayment,
        params,
      )
      dispatch(loadingSlice.actions.startLoading('handleSubmitAgreement'))
      const response = await gymService.submitAgreement(agreement)

      if (!response || response.status !== 200 || !response.data) {
        setError('Something went wrong. Please try again later.')
        dispatch(loadingSlice.actions.endLoading('handleSubmitAgreement'))
        return
      }

      //Check id displayQueueMessage is present in response (no Registration)
      if (response.data.displayQueueMessage !== '') {
        setDisplayQueueMessageError(true)
      }

      //Redirect to payment plans after 3rd reattempt
      if (response.data.status === 'id10tError') {
        dispatch(planInformationSlice.actions.clearPlanSelection())
        history.push(PAGES.plan + location.search)
      }

      let displayErrorMessage = response.data.displayErrorMessage

      if (displayErrorMessage) {
        if (reduxProfileInfo.country.value === 'CA') {
          try {
            const errors: IServerErrorsArr[] = JSON.parse(response.data.lastException)
            const hasBillingError = errors.find(({ abcCode }) => abcCode === 'abcBillingRoutingNumber')
            if (hasBillingError && reduxPayment.recurringTransitNumber) {
              displayErrorMessage = `Please, check if Transit Number ${reduxPayment.recurringTransitNumber} is correct
                Please, check if Bank Number ${reduxPayment.recurringBankNumber} is correct`
            }
          } catch (e) {
            // nothing to do here, just skip it and set standard displayErrorMessage
          }
        }

        setError(displayErrorMessage)
      } else {
        const verify: VerifyAgreement = {
          memberNumber: response.data.agreementNumber.slice(-5),
          clubNumber: response.data.clubNumber,
          firstName: reduxProfileInfo.firstName.value,
          lastName: reduxProfileInfo.lastName.value,
          zip: reduxProfileInfo.zip.value,
        }

        const validate = await gymService.verifyAgreement(verify)

        if (validate?.status === 200 && validate?.data.errors.length === 0 && validate.data.valid) {
          const verifiedAgreement: VerifiedAgreement = {
            memberNumber: response.data.agreementNumber,
            clubNumber: response.data.clubNumber,
            firstName: reduxProfileInfo.firstName.value,
            lastName: reduxProfileInfo.lastName.value,
            zip: reduxProfileInfo.zip.value,
            barcode: validate.data.barcode,
            memberId: validate.data.memberId,
            promptForBarcode: validate.data.promptForBarcode,
            valid: validate.data.valid,
            queueMessage: response.data.displayQueueMessage,
          }

          dispatch(agreementSlice.actions.setAgreement(verifiedAgreement))

          setSuccess(true)
          setVerifiedAgreement(verifiedAgreement)
          dispatch(loadingSlice.actions.endLoading('handleSubmitAgreement'))

          if (!displayQueueMessageError) {
            setShowRegistrationModal(true)
            setSubmitButtonDisabled(true)
          }

          saveMemberDataToGymSales({
            step: gymSalesSteps.join,
            clubId: clubId || reduxGymInfo.number,
            ...reduxProfileInfo,
          })
        } else if (validate?.status === 200 && validate.data?.errors.length) {
          setError(validate.data.errors[0])
        } else {
          setError('Something went wrong. Please try again later.')
        }
      }

      dispatch(loadingSlice.actions.endLoading('handleSubmitAgreement'))
    })()
  }

  const handleEdit = useCallback((route: string) => history.push(route + location.search), [])
  const handleEditProfile = useCallback(() => handleEdit(PAGES.profile), [handleEdit])

  return (
    <Container maxWidth="xl">
      {showRegistrationModal && (
        <RegistrationModal
          closeModal={setShowRegistrationModal}
          gymInfo={reduxGymInfo}
          profileInfo={reduxProfileInfo}
          verifiedAgreement={verifiedAgreement}
        />
      )}
      <HeadingBox title={`Review - You're almost there, ${reduxProfileInfo.firstName.value}!`}>
        Please review the following information.
      </HeadingBox>
      <Grid direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 5} container>
        <Grid md={8} item>
          <ContactInfoBlock onEdit={handleEditProfile} />
          <PersonalInfoBlock onEdit={handleEditProfile} />
          {reduxProfileInfo.secondaryMembers.map((member, index) => (
            <SecondaryMemberBlock key={index} memberIndex={index} onEdit={handleEditProfile} />
          ))}
          <PaymentInfoBlock onEdit={() => handleEdit(PAGES.payment)} />
          <AdditionalInfoBlock onEdit={handleEditProfile} />
        </Grid>
        <Grid md={4} item>
          <OfferDetails />
          {error && (
            <FormHelperText ref={errorRef} style={{ whiteSpace: 'pre-line' }} error>
              {error}
            </FormHelperText>
          )}
          <Button
            color="secondary"
            disabled={submitButtonDisabled}
            style={{
              width: '100%',
              marginTop: '16px',
              padding: '20px',
              fontSize: '18px',
              fontWeight: 'bold',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
            }}
            variant="contained"
            onClick={handleSubmitAgreement}
          >
            Complete
          </Button>

          {success && conversionTrackers?.map((tracker, index) => <img key={index} alt="" src={tracker} />)}
        </Grid>
      </Grid>
    </Container>
  )
}

export default ReviewPage
