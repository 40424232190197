import { MemberAgreement, VerifyAgreement, MemberAccount, GymPlan, Schedule } from 'src/interfaces'
import { abcAxiosInstance as axios, axiosInstance } from './index'
import { planRibbonLabels } from 'src/common'

async function getGymConfiguration(clubId: string) {
  const response = await axios.get('/club/getClub', {
    params: {
      club: clubId,
    },
  })

  return response
}

// if plan has _BV or _MP prefix in plan name - it means that plan was labeled as BestValue or MostPopular.
// In this case we need to save appropriate label to plan data and remove this extra prefix from plan name.
const extractPlanRibbon = (plan: GymPlan) => {
  const ribbonSign = plan.planName.slice(-3)
  const ribbonText = planRibbonLabels[ribbonSign]

  if (!ribbonText) return plan

  return {
    ...plan,
    planName: plan.planName.slice(0, -3),
    ribbonLabel: ribbonText,
  }
}

async function getGymPlans(clubId: string, promo: boolean) {
  const response = await axios.get('/signup/planList', {
    params: {
      clubNumber: clubId,
      returnOnlyPromoPlans: promo,
    },
  })

  return response.data.map(extractPlanRibbon)
}

async function getSelectedPlan(planId: string, clubNumber: string) {
  const { data } = await axios.get('/signup/calculatePlan', {
    params: { planId, clubNumber },
  })

  return data
}

async function getSelectedPlanWithAddons(planId: string, clubNumber: string, addons: Schedule[]) {
  const formData = new FormData()

  // MICO backend accepts profit centers names only with first letter capitalized and without space symbols,
  // otherwise the addon fees will not be calculated. Example: 'some profit center' -> 'Someprofitcenter'
  const profitCenters = addons.map(({ profitCenter }) =>
    profitCenter.replaceAll(' ', '').replace(/./, (letter) => letter.toUpperCase()),
  )

  formData.append(
    'json',
    JSON.stringify({
      addons: profitCenters,
      planId,
      clubNumber,
    }),
  )

  const response = await axios.post('/signup/recalculateSummary', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return response
}

async function getCountriesStatesProvinces(clubId: string) {
  const response = await axios.get('/club/getCountriesStatesProvinces', {
    params: { club: clubId, _: Date.now() },
    headers: {},
  })

  return response
}

async function getSalesPeople(clubId: string) {
  const response = await axios.get('/signup/getSalesPeople', {
    params: { clubNumber: clubId, _: Date.now() },
  })

  return response
}

async function getCampaigns(clubId: string) {
  const response = await axios.get('/signup/getCampaigns', {
    params: { clubNumber: clubId, campaignSource: 'ONL', _: Date.now() },
  })

  return response
}

async function submitTrial(payload: Record<string, any>) {
  const formData = new FormData()
  const keys = Object.keys(payload)
  keys.forEach((key) => {
    formData.append(key, payload[key])
  })
  const response = await axios.post('/trial/submit', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })

  return response
}

async function submitAgreement(payload: MemberAgreement) {
  const formData = new FormData()
  formData.append('json', JSON.stringify(payload))

  const response = await axiosInstance.post('/signup/submitAgreement', formData)

  return response
}

async function verifyAgreement(payload: VerifyAgreement) {
  const formData = new FormData()
  formData.append('json', JSON.stringify(payload))

  const response = await axiosInstance.post('/registration/verifyAgreement', formData)

  return response
}

async function createAccount(payload: MemberAccount) {
  const formData = new FormData()
  formData.append('json', JSON.stringify(payload))
  const response = await axios.post('/registration/createAccount', formData)

  return response
}

async function getConversionTrackers(clubId: string) {
  const response = await axios.get('/signup/getConversionTrackers', {
    params: { club: clubId, conversionEvent: 'COS', _: Date.now() },
  })

  return response
}

export {
  getGymPlans,
  getGymConfiguration,
  getSelectedPlan,
  getSelectedPlanWithAddons,
  getCountriesStatesProvinces,
  getSalesPeople,
  getCampaigns,
  getConversionTrackers,
  submitAgreement,
  verifyAgreement,
  submitTrial,
  createAccount,
}
