import { useTermsAndConditionsStyles } from '../styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { IModalOpenerProps } from '../types'

const ModalOpener = (props: IModalOpenerProps) => {
  const classes = useTermsAndConditionsStyles()

  return (
    <Typography className={classes.modalOpener} color="secondary" component="span" onClick={props.onClick}>
      {props.text}
    </Typography>
  )
}

export default ModalOpener
