import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const AccordionHeading = ({ title, amount }: { title: string; amount: string }): JSX.Element => (
  <>
    <Grid xs={8} item>
      <Typography style={{ fontStyle: 'italic' }} variant="subtitle2">
        {title}
      </Typography>
    </Grid>
    <Grid xs={4} item>
      <Typography style={{ textAlign: 'right' }} variant="subtitle2">
        {amount}
      </Typography>
    </Grid>
  </>
)

export default AccordionHeading
