import React, { PropsWithChildren } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

const FormBlock = (props: PropsWithChildren<unknown>) => {
  return (
    <Box
      mb={4}
      py={3}
      style={{
        position: 'relative',
        borderRadius: 6,
        border: '1px solid #ddd',
        background: 'rgba(219, 219, 219, 0.15)',
      }}
    >
      <Container maxWidth="xl">
        <Grid direction="column" spacing={6} container>
          {props.children}
        </Grid>
      </Container>
    </Box>
  )
}

export default FormBlock
