import { gymSalesAxiosInstance as axios } from './index'
import { IUserDataForGymSales } from 'src/interfaces'
import { AxiosResponse } from 'axios'

function sendUserData(data: IUserDataForGymSales): Promise<AxiosResponse> {
  return axios.post('online_join/people', data)
}

type TReturnedUserDataFromGS = Omit<IUserDataForGymSales, 'step' | 'abc_club_number'>

async function getUserSavedData(id: string, token: string): Promise<TReturnedUserDataFromGS> {
  try {
    const response = await axios.get(`online_join/people/${id}`, {
      params: { token },
    })
    return response.data
  } catch (e) {
    console.warn('Something went wrong with gym sales user data request: ', e)
  }
  return {} as TReturnedUserDataFromGS
}

export { sendUserData, getUserSavedData }
