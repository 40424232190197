import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { locations } from 'src/services'
import * as states from '../states'

export const fetchLocations = createAsyncThunk('gyms/fetch', async (clubNumber: string) => {
  const { data } = await locations.getLocations(clubNumber)

  return data
})

const locationsSlice = createSlice({
  name: 'locations',
  initialState: states.initialLocationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLocations.fulfilled, (state, action) => {
      state.loading = false
      state.loaded = true
      state.locations = action.payload
      return state
    })
    builder.addCase(fetchLocations.pending, (state) => {
      state.loading = true
      return state
    })
    builder.addCase(fetchLocations.rejected, (state, action) => {
      state.loading = false
      state.loaded = true
      state.error = action.error.message
      return state
    })
  },
})

export default locationsSlice.reducer
