import {
  CampaignsState,
  CountriesAndStatesState,
  FieldOptionState,
  GymDetails,
  GymPlanSelection,
  GymState,
  LocationState,
  SecondaryMember,
} from 'src/interfaces'

const initialGymState: GymState = {
  clubId: undefined,
  gym: {} as GymDetails,
  location: {} as LocationState,
  loaded: false,
  loading: false,
  plans: [],
}

const initialPlanInformationState: GymPlanSelection = {
  activePresale: false,
  additionalMembersAllowed: 0,
  agreementDescription: '',
  agreementNote: '',
  agreementTerm: '',
  agreementTerms: '',
  clubFeeTotalAmount: '',
  clubFees: [],
  corporatePlanOnly: '',
  downPaymentName: '',
  downPaymentTotalAmount: '',
  downPayments: [],
  dueDay: '',
  expirationDate: '',
  fieldOption: [],
  firstDueDate: '',
  hasAutoRenew: false,
  membershipType: '',
  message: '',
  onlinePlanDisplayLocation: '',
  onlineSignupAllowedPaymentMethods: '',
  planId: '',
  planName: '',
  planValidation: '',
  preferredPaymentMethod: '',
  scheduleFrequency: '',
  scheduleTotalAmount: '',
  schedules: [],
  termInMonths: '',
  totalContractValue: '',
  userDefinedFields: [],
}

const initialProfileInformationState = {
  birthDate: { state: FieldOptionState.hide, value: '' },
  cellNumber: { state: FieldOptionState.required, value: '' },
  city: { state: FieldOptionState.hide, value: '' },
  country: { state: FieldOptionState.hide, value: '' },
  dl: { state: FieldOptionState.hide, value: '' },
  email: { state: FieldOptionState.required, value: '' },
  emergencyContactExtension: { state: FieldOptionState.hide, value: '' },
  emergencyContactFirstName: { state: FieldOptionState.hide, value: '' },
  emergencyContactLastName: { state: FieldOptionState.hide, value: '' },
  emergencyContactNumber: { state: FieldOptionState.hide, value: '' },
  employer: { state: FieldOptionState.hide, value: '' },
  secondaryMembers: [] as SecondaryMember[],
  firstName: { state: FieldOptionState.required, value: '' },
  gender: { state: FieldOptionState.hide, value: '' },
  healthwaysId: { state: FieldOptionState.hide, value: '' },
  homeNumber: { state: FieldOptionState.hide, value: '' },
  lastName: { state: FieldOptionState.required, value: '' },
  marketingDirectMail: { state: FieldOptionState.hide, value: '' },
  marketingEmails: { state: FieldOptionState.hide, value: true },
  marketingPushNotifications: { state: FieldOptionState.hide, value: '' },
  marketingSMS: { state: FieldOptionState.hide, value: false },
  messagingConsent: { state: FieldOptionState.hide, value: false },
  middleInitial: { state: FieldOptionState.hide, value: '' },
  promotion: { state: FieldOptionState.hide, value: '' },
  salesPersonId: { state: FieldOptionState.hide, value: '' },
  state: { state: FieldOptionState.hide, value: '' },
  street: { state: FieldOptionState.hide, value: '' },
  workExt: { state: FieldOptionState.hide, value: '' },
  workNumber: { state: FieldOptionState.hide, value: '' },
  zip: { state: FieldOptionState.hide, value: '' },
}

const initialGuestProfileFields = {
  address2: '',
  campaign: {
    value: '',
    label: '',
  },
  city: '',
  countryCode: '',
  dateOfBirth: '',
  email: '',
  favoriteHotel: '',
  firstName: '',
  gender: '',
  homePhone: '',
  lastName: '',
  mobilePhone: '',
  receivePromotionOffers: false,
  messagingConsent: false,
  signatureImage: '',
  state: '',
  streetAddress: '',
  zip: '',
}

const initialPaymentState = {
  payLater: 'payNow',
  recurringAccountNumber: '',
  recurringAccountNumberRepeat: '',
  recurringAccountType: '',
  recurringCCNumber: '',
  recurringExpDate: '',
  recurringFirstName: '',
  recurringLastName: '',
  recurringRoutingNumber: '',
  signature: '',
  todayCCNumber: '',
  todayCVV: '',
  todayExpDate: '',
  todayFirstName: '',
  todayLastName: '',
  todayZip: '',
  useForRecurring: false,
}

const initialAgreementState = {
  barcode: '',
  clubNumber: '',
  firstName: '',
  lastName: '',
  memberId: '',
  memberNumber: '',
  promptForBarcode: false,
  valid: false,
  zip: '',
}

const initialCountriesAndStatesState: CountriesAndStatesState = {
  countriesStates: [],
  error: undefined,
  loaded: false,
  loading: false,
}

const initialCampaignsState: CampaignsState = {
  loading: false,
  loaded: false,
  error: undefined,
  campaigns: [],
}

const initialLocationState: LocationState = {
  loading: false,
  loaded: false,
  error: undefined,
  locations: [],
}

export type { GymState }
export {
  initialGymState,
  initialPaymentState,
  initialPlanInformationState,
  initialGuestProfileFields,
  initialProfileInformationState,
  initialAgreementState,
  initialCountriesAndStatesState,
  initialCampaignsState,
  initialLocationState,
}
