import React, { CSSProperties } from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'

type IButtons = {
  [key: string]: string
}

interface ITwoButtonsTabSwitcherProps {
  buttons: IButtons
  active?: keyof IButtons
  handleToggle: (key: string) => void
  style?: CSSProperties
}

const useStyles = makeStyles({
  root: {
    width: 168,
    boxShadow: 'none',
    textTransform: 'none',

    '&:first-child': {
      clipPath: 'polygon(75% 0, 100% 100%, 0 100%, 0 0)',
      padding: '14px 39px 14px 18px',
    },
    '&:last-child': {
      clipPath: 'polygon(100% 0, 100% 100%, 25% 100%, 0 0)',
      padding: '14px 18px 14px 39px',
      marginLeft: -39,
    },
  },
  containedSecondary: {
    fontWeight: 'bold',
  },
})

const TwoButtonsTabSwitcher = (props: ITwoButtonsTabSwitcherProps) => {
  const classes = useStyles()
  return (
    <ButtonGroup style={props.style}>
      {Object.keys(props.buttons).map((key) => (
        <Button
          key={key}
          classes={{
            root: classes.root,
            containedSecondary: classes.containedSecondary,
          }}
          color={props.active === key ? 'secondary' : 'default'}
          variant="contained"
          onClick={() => props.handleToggle(key)}
        >
          {props.buttons[key]}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default TwoButtonsTabSwitcher
