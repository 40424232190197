import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import { ClubFee, Schedule } from 'src/interfaces'
import { getPaymentDate } from 'src/helpers'
import { gymDetailsSelector, planStateSelector } from 'src/selectors'

export const useFeesDetails = (): {
  clubFeesList: ClubFee[]
  firstDueDate: string | undefined
  schedulesDefault: Schedule[]
  schedulesAdditional: Schedule[]
} => {
  const reduxGymInfo = useSelector(gymDetailsSelector)
  const reduxPlanInfo = useSelector(planStateSelector)

  const firstDueDate = useMemo(() => {
    const scheduleDueDate = reduxPlanInfo.schedules.find(
      (schedule: Schedule) => schedule.scheduleDueDate,
    )?.scheduleDueDate

    return getPaymentDate(reduxGymInfo, reduxPlanInfo, scheduleDueDate)
  }, [reduxPlanInfo.schedules])

  return {
    clubFeesList: reduxPlanInfo.clubFees.filter((fee: ClubFee) => fee.feeApply),
    firstDueDate,
    schedulesDefault: reduxPlanInfo.schedules.filter(
      (schedule: Schedule) => schedule.defaultChecked && !schedule.addon,
    ),
    schedulesAdditional: reduxPlanInfo.schedules.filter(
      (schedule: Schedule) => schedule.defaultChecked && schedule.addon,
    ),
  }
}
