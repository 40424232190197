import React, { useCallback, useEffect } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import FormControl from '@material-ui/core/FormControl'
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'date-fns'
import moment from 'moment'
import * as iframeDataExchange from 'src/helpers/iframeDataExchange'
import { scrollToElem } from 'src/helpers'
import './DateInput.scss'
import './FormInput.scss'
import { DATES_FORMAT_PATTERNS } from '../common'
import FormHelperText from '@material-ui/core/FormHelperText'

const minDate = new Date(2019, 12, 31)
const maxDate = new Date(2040, 12, 31)

const convertToDate = (date: string) => {
  let convertedDate
  if (date && date.length > 8) {
    convertedDate = moment(date).toDate()
  } else if (date && date.length < 8) {
    const dateArray = date.split('-')
    const year = dateArray[0]
    const month = dateArray[1]
    const dateWithDay = `${year}-${month}`
    convertedDate = moment(dateWithDay).toDate()
  } else {
    convertedDate = null
  }
  return convertedDate
}

function DateInput({
  propName,
  fieldName,
  formatValue,
  value,
  onChange,
  onClose,
  onOpen,
  type,
  error = false,
  errorMessage,
  required = false,
  nextIteration = 0,
  ...rest
}: DateInputType) {
  const errorRef = React.useRef<HTMLParagraphElement>(null)
  const errorText = errorMessage ?? `Valid ${fieldName} is required`
  const { picker: pickerFormat, value: valuePattern } = DATES_FORMAT_PATTERNS[type]
  const formatValuePattern = formatValue ?? valuePattern

  useEffect(() => {
    if (error) {
      scrollToElem(errorRef.current)
    }
  }, [nextIteration, error])

  const onOpenCb = useCallback(() => {
    onOpen?.()
    iframeDataExchange.emit({ datepickerOpened: propName })
  }, [onOpen])

  let InputObject
  const commonProps: KeyboardDatePickerProps = {
    color: 'secondary',
    disableToolbar: true,
    format: pickerFormat,
    id: propName,
    label: (
      <span>
        {fieldName} - {formatValuePattern.toUpperCase()} {required ? <span className="required"> *</span> : ''}
      </span>
    ),
    margin: 'normal',
    onOpen: onOpenCb,
    onClose: () => onClose?.(),
    onChange: (date) => {
      onChange({
        target: { value: date ? moment(date).format(formatValuePattern) : '' },
      })
    },
    value: convertToDate(value),
    KeyboardButtonProps: {
      'aria-label': 'change date',
    },
    error: error,
    ...rest,
  }

  if (type === 'month') {
    InputObject = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          maxDate={maxDate}
          minDate={minDate}
          openTo="month"
          views={['month', 'year']}
          {...commonProps}
          label={
            <span>
              {fieldName} {required ? <span className="required"> *</span> : ''}
            </span>
          }
        />
      </MuiPickersUtilsProvider>
    )
  } else {
    InputObject = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker openTo="year" variant="inline" views={['year', 'month', 'date']} autoOk {...commonProps} />
      </MuiPickersUtilsProvider>
    )
  }

  return (
    <FormControl className={'form-group ' + propName}>
      {InputObject}
      {error && (
        <FormHelperText ref={errorRef} error>
          {errorText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

interface DateInputType extends Partial<KeyboardDatePickerProps> {
  propName: string
  fieldName: string
  formatValue?: string
  value: string
  onChange: (date: any) => void
  onClose?: () => void
  onOpen?: () => void
  error?: boolean
  errorMessage?: string
  required?: boolean
  type: 'month' | 'date'
  nextIteration?: number
}

export default DateInput
